/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState} from "react";
import {CreateListElements} from '../../molecules/CreateListElements/index';
import {leftPanelOptions} from '../../common/leftpaneloptions';
import Logo from '../../images/dashboard.logo.svg';
import { useDispatch, useSelector} from "react-redux";
import {getPinnedDeals} from '../../react-shared/src/Actions/DealsActions';


export const LeftPanelMainOptions = (props) => {
  const [pinnedDeals, setPinnedDeals] = useState();
  
  const dispatch = useDispatch();
  
  const updatePinnedDeals = useCallback(params => {
    setPinnedDeals && setPinnedDeals(prevState => ({ ...prevState, ...params }));
  }, []);
  const pinnedDeatils = useSelector(state => state?.dealsReducers?.addPinnedDeal);
  useEffect(() => {
    dispatch(getPinnedDeals(
      {
        pinned: 'Y' 
      }, response => {
        updatePinnedDeals({
          ...pinnedDeals,
          pinnedDealList: response?.results
        })
      }, error => {
        console.log('error', error);
      }
    )
      // pinned : "N",
  )
  },[pinnedDeatils, props?.listofDeals?.pinned])

  return (
    <div className="leftpannel-wrapper leftpanel-fixed">
      <div className="dashboardlogo">
        <img src={Logo}/>
      </div>
      <CreateListElements options = {leftPanelOptions}/>
      {
        pinnedDeals?.pinnedDealList && <CreateListElements dealOptions = {pinnedDeals?.pinnedDealList} onOptionClick = {props?.onOptionClick}/>
      }
      
    </div>
    
  );
};
