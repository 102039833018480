/* eslint-disable react/prop-types */
import React from 'react';
import { SearchInput } from '../../atoms/SearchInput';
import { SelectInput } from '../../atoms/SelectInput';

export const DocumentsHeader = props => {
  const {
    searchTerm,
    onClear,
    onChangeSearch,
    onSelectSort,
    onSelectFilter,
    sort,
    filter,
    sortOptions = [],
    filterOptions = [],
  } = props;
  return (
    <div className="document-page-header-container">
      <SearchInput
        value={searchTerm}
        placeholder="Find anything here"
        onChangeSearch={onChangeSearch}
        onClear={onClear}
      />
      <div className="document-page--header-sort">
        <SelectInput
          options={sortOptions}
          onSelect={onSelectSort}
          value={sort}
        />
        <SelectInput
          options={filterOptions}
          icon="tune"
          onSelect={onSelectFilter}
          value={filter}
        />
      </div>
    </div>
  );
};
