/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Modal } from 'react-bootstrap';
import { Button } from '../../atoms/Button';
import addplus from '../../images/addplus.svg';
// import { NewProject } from '../../organisms/NewProject';
import { CreateDeal } from './CreateDeal';
import { newDealAction } from '../../react-shared/src/Actions/DealsActions';

export const DealRoomHeader = props => {
  const { dealList, updateDealList } = props;
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // const params = { getFieldValue, newProjectDetails };
  return (
    <Row className="newDeal-header-wrapper">
      <Col md={6}>
        <h2 className="deal-header">My Deal Room</h2>
      </Col>
      <Col md={6} className="text-end">
        <div className="newDeal-button">
          <Button
            text={'New Deal Room'}
            type="button"
            imageULR={addplus}
            varient={'primary'}
            onClickHandler={handleShow}
          />
        </div>
      </Col>
      <CreateDeal
        dealDetails={dealList}
        setDealdeatils={updateDealList}
        show={show}
        handleClose={handleClose}
      />
    </Row>
  );
};
