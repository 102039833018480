/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SectionTaskList } from '../../../molecules/SectionTask/SectionTaskList';
import CustomProgressBar from '../../../atoms/ProgressBar';
import { AddActionItems } from '../Templates/AddActionItems';
import { taskCompleteAction } from '../../../react-shared/src/Actions/DealsActions';
import { AddTaskTemplate } from './AddTaskTemplate';
import { ToastMessage } from '../../../atoms/Toast';

const updateTaskCompleting = (count, response) => {
  return count.map(item =>
    item.id === response.id ? { ...item, ...response } : item,
  );
};

export const TaskTemplate = props => {
  const {
    updateDealRoomDetails,
    dealRoomDetails,
    redirectToOtherSection,
    membersList,
  } = props;
  const [completedPercentage, SetCompletedPercentage] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalStates, setModalStates] = useState({});
  const [count, setCount] = useState(dealRoomDetails?.section_task);
  const dispatch = useDispatch();

  useEffect(() => {
    setCount(dealRoomDetails?.section_task);
  }, [dealRoomDetails?.section_task]);

  const handleInputChange = (id, value) => {
    dispatch(
      taskCompleteAction(
        {
          status: value,
          id,
        },
        response => {
          const updatedResponse = updateTaskCompleting(count, response);
          setCount(updatedResponse);
          updateDealRoomDetails({
            sectionTaskMarked: updatedResponse,
          });
          ToastMessage({
            success: true,
            message: 'Task status Changes',
          });
        },
        error => {
          console.log('error', error);
        },
      ),
    );
  };

  const calculatePercantageCompleted = item => {
    if (item?.action_item_count) {
      const openItems = item?.task_action_item?.filter(
        subItem => subItem?.status?.code === 'O',
      );
      return (
        ((item?.action_item_count - openItems.length) /
          item?.action_item_count) *
        100
      );
    } else {
      return item?.status?.code === 'O' ? 0 : 100;
    }
  };

  const addNewTaskHandler = () => {
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const handleCloseHandler = id => {
    setModalStates(prevState => ({
      ...prevState,
      [id]: false,
    }));
  };

  const openModalHandler = id => {
    setModalStates(prevState => ({
      ...prevState,
      [id]: true,
    }));
  };
  const completedTaskList = (params) => {
    const arrayResult =  params.filter(item => item.status?.code === "C");
    return arrayResult?.length;
  }
  console.log('dealRoomDetails from task template', dealRoomDetails);
  const redirectToTask = id => {
    redirectToOtherSection(id);
  };
  return (
    <div className="taskSection_wrapper">
      <p className="percantage-Completed">
        <span className="material-symbols-rounded">task_alt</span>
        tasks{' '}
        {dealRoomDetails?.section_task?.length > 0 && (
          <span className="number"> {completedTaskList(dealRoomDetails?.section_task)}/ {dealRoomDetails?.section_task?.length}</span>
        )}
        <span className="addfile">
          <button onClick={addNewTaskHandler} type="button">
            Add Task
          </button>
        </span>
      </p>
      <ul>
        {dealRoomDetails?.section_task?.map((item, index) => {
          const isModalOpen = modalStates[item?.id] || false;
          return (
            <React.Fragment key={item.id}>
              <li>
                <SectionTaskList
                  item={item}
                  handleInputChange={handleInputChange}
                />
              </li>
              <CustomProgressBar
                progress={calculatePercantageCompleted(item)}
              />
              {item?.action_item_count > 0 && (
                <div className="action-item-present">
                  <p>
                    <span className="material-symbols-rounded">
                      subdirectory_arrow_right
                    </span>
                    {item?.task_action_item[0]?.name}
                  </p>
                </div>
              )}

              <ul className="list-inline task-action-list-inline">
                {item?.action_item_count ? (
                  <li
                    className="list-inline-item"
                    onClick={() => redirectToTask(item?.id)}>
                    Action Items ({item?.action_item_count})
                  </li>
                ) : (
                  <li
                    className="list-inline-item"
                    onClick={() => openModalHandler(item?.id)}>
                    Add Action Items
                  </li>
                )}
                {item?.task_file_count ? (
                  <li
                    className="list-inline-item"
                    onClick={() => redirectToTask(item?.id)}>
                    Files({item?.task_file_count})
                  </li>
                ) : (
                  <li className="list-inline-item">
                    Files({item?.task_file_count})
                  </li>
                )}
                {/* <li className="list-inline-item">
                  Files({item?.task_file_count})
                </li> */}
                {item?.task_comment_count ? (
                  <li
                    className="list-inline-item"
                    onClick={() => redirectToTask(item?.id)}>
                    Comments({item?.task_comment_count})
                  </li>
                ) : (
                  <li className="list-inline-item">
                    Comments({item?.task_comment_count})
                  </li>
                )}
                {/* <li className="list-inline-item">
                  Comments({item?.task_comment_count})
                </li> */}
              </ul>
              <AddActionItems
                updateDealRoomDetails={updateDealRoomDetails}
                taskDetails={item}
                handleClose={() => handleCloseHandler(item?.id)}
                modalShow={isModalOpen}
              />
            </React.Fragment>
          );
        })}
      </ul>
      <AddTaskTemplate
        updateDealRoomDetails={updateDealRoomDetails}
        dealRoomDetails={dealRoomDetails}
        handleClose={handleClose}
        modalShow={modalShow}
        membersList={membersList}
      />
    </div>
  );
};
