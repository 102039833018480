import { put, takeLatest, call, take } from 'redux-saga/effects';
import { apiConfig, APPURL } from '../config';
import {
  CREAT_FOLDER,
  CREAT_FOLDER_SUCCESS,
  CREAT_FOLDER_FAILURE,
  GET_FOLDER_LIST_SUCCESS,
  GET_FOLDER_LIST_FAILURE,
  GET_FOLDER_LIST,
  GET_FILE_LIST_SUCCESS,
  GET_FILE_LIST_FAILURE,
  GET_FILE_LIST,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAILURE,
  UPDATE_FOLDER,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE,
  GET_FOLDER_DETAIL_SUCCESS,
  GET_FOLDER_DETAIL_FAILURE,
  GET_FOLDER_DETAIL,
  GET_FILE_DETAIL_SUCCESS,
  GET_FILE_DETAIL_FAILURE,
  GET_FILE_DETAIL,
  DELETE_DOCUMENT_FILE_SUCCESS,
  DELETE_DOCUMENT_FILE_FAILURE,
  DELETE_DOCUMENT_FILE,
  UPDATE_DOCUMENT_FILE_SUCCESS,
  UPDATE_DOCUMENT_FILE_FAILURE,
  UPDATE_DOCUMENT_FILE,
  LINK_FILE,
  LINK_FILE_SUCCESS,
  LINK_FILE_FAILURE,
  LINK_FILE_TASK,
  LINK_FILE_TASK_SUCCESS,
  LINK_FILE_TASK_FAILURE,
} from '../Constants/constants';

export function* createFolderSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;

  try {
    const result = yield call(apiConfig.post, `${APPURL}folders/`, payload);
    console.log('result', result);
    const { status, data } = result;

    if (status === 201 || status === 200) {
      yield put({
        type: CREAT_FOLDER_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: CREAT_FOLDER_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: CREAT_FOLDER_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* getFolderListSaga(request) {
  const { successCallback = null, errorCallback = null } = request;

  try {
    const result = yield call(apiConfig.get, `${APPURL}folders/`);
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: GET_FOLDER_LIST_SUCCESS,
        data,
      });
      if (typeof successCallback === 'function') {
        successCallback?.(data);
      }
    } else {
      yield put({
        type: GET_FOLDER_LIST_FAILURE,
        data,
      });
      if (typeof errorCallback === 'function') {
        errorCallback?.(data);
      }
    }
  } catch (error) {
    yield put({
      type: GET_FOLDER_LIST_FAILURE,
      error,
    });
    if (typeof errorCallback === 'function') {
      errorCallback?.(error);
    }
  }
}

export function* getFileListSaga(request) {
  const { successCallback = null, errorCallback = null } = request;

  try {
    const result = yield call(apiConfig.get, `${APPURL}file/`);
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: GET_FILE_LIST_SUCCESS,
        data,
      });
      if (typeof successCallback === 'function') {
        successCallback?.(data);
      }
    } else {
      yield put({
        type: GET_FILE_LIST_FAILURE,
        data,
      });
      if (typeof errorCallback === 'function') {
        errorCallback?.(data);
      }
    }
  } catch (error) {
    yield put({
      type: GET_FILE_LIST_FAILURE,
      error,
    });
    if (typeof errorCallback === 'function') {
      errorCallback?.(error);
    }
  }
}

export function* updateFolderSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;

  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}folders/${payload?.id}/`,
      payload,
    );
    console.log('result', result);
    const { status, data } = result;

    if (status === 201 || status === 200) {
      yield put({
        type: UPDATE_FOLDER_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: UPDATE_FOLDER_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: UPDATE_FOLDER_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* uploadFileSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;

  try {
    const result = yield call(apiConfig.post, `${APPURL}file/`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('result', result);
    const { status, data } = result;

    if (status === 201 || status === 200) {
      yield put({
        type: UPLOAD_FILE_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: UPLOAD_FILE_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: UPLOAD_FILE_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* getFolderDetailSaga(request) {
  const { payload, successCallback = null, errorCallback = null } = request;

  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}folders/${payload?.id}/`,
    );
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: GET_FOLDER_DETAIL_SUCCESS,
        data,
      });
      if (typeof successCallback === 'function') {
        successCallback?.(data);
      }
    } else {
      yield put({
        type: GET_FOLDER_DETAIL_FAILURE,
        data,
      });
      if (typeof errorCallback === 'function') {
        errorCallback?.(data);
      }
    }
  } catch (error) {
    yield put({
      type: GET_FOLDER_DETAIL_FAILURE,
      error,
    });
    if (typeof errorCallback === 'function') {
      errorCallback?.(error);
    }
  }
}

export function* getFileDetailSaga(request) {
  const { payload, successCallback = null, errorCallback = null } = request;

  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}file/?name=${payload?.name}`,
    );
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: GET_FILE_DETAIL_SUCCESS,
        data,
      });
      if (typeof successCallback === 'function') {
        successCallback?.(data);
      }
    } else {
      yield put({
        type: GET_FILE_DETAIL_FAILURE,
        data,
      });
      if (typeof errorCallback === 'function') {
        errorCallback?.(data);
      }
    }
  } catch (error) {
    yield put({
      type: GET_FILE_DETAIL_FAILURE,
      error,
    });
    if (typeof errorCallback === 'function') {
      errorCallback?.(error);
    }
  }
}

export function* deleteDocumentFileSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;

  try {
    const result = yield call(
      apiConfig.delete,
      `${APPURL}file/${payload?.id}/`,
    );
    console.log('result', result);
    const { status, data } = result;

    if (status === 201 || status === 200 || status === 204) {
      yield put({
        type: DELETE_DOCUMENT_FILE_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: DELETE_DOCUMENT_FILE_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: DELETE_DOCUMENT_FILE_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* updateDocumentFileSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;

  try {
    const { id, ...params } = payload;
    const result = yield call(
      apiConfig.patch,
      `${APPURL}file/${payload?.id}/`,
      params,
    );
    console.log('result', result);
    const { status, data } = result;

    if (status === 201 || status === 200 || status === 204) {
      yield put({
        type: UPDATE_DOCUMENT_FILE_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: UPDATE_DOCUMENT_FILE_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: UPDATE_DOCUMENT_FILE_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* watchLinkFileSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request);
  try {
    // const { id, ...params } = payload;
    const result = yield call(
      apiConfig.post,
      `${APPURL}link-files/`,
      payload,
    );
    console.log('result', result);
    const { status, data } = result;

    if (status === 201 || status === 200 || status === 204) {
      yield put({
        type: LINK_FILE_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: LINK_FILE_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: LINK_FILE_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* watchLinkFileToTaskSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request);
  try {
    // const { id, ...params } = payload;
    const result = yield call(
      apiConfig.post,
      `${APPURL}link-file_task/`,
      payload,
    );
    console.log('result', result);
    const { status, data } = result;

    if (status === 201 || status === 200 || status === 204) {
      yield put({
        type: LINK_FILE_TASK_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: LINK_FILE_TASK_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: LINK_FILE_TASK_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* watchCreateFolder() {
  yield takeLatest(CREAT_FOLDER, createFolderSaga);
}

export function* watchGetFolderList() {
  yield takeLatest(GET_FOLDER_LIST, getFolderListSaga);
}

export function* watchGetFileList() {
  yield takeLatest(GET_FILE_LIST, getFileListSaga);
}

export function* watchUpdateFolder() {
  yield takeLatest(UPDATE_FOLDER, updateFolderSaga);
}

export function* watchUploadFile() {
  yield takeLatest(UPLOAD_FILE, uploadFileSaga);
}

export function* watchGetFolderDetail() {
  yield takeLatest(GET_FOLDER_DETAIL, getFolderDetailSaga);
}

export function* watchGetFileDetail() {
  yield takeLatest(GET_FILE_DETAIL, getFileDetailSaga);
}

export function* watchDeleteDocumentFile() {
  yield takeLatest(DELETE_DOCUMENT_FILE, deleteDocumentFileSaga);
}

export function* watchUpdateDocumentFile() {
  yield takeLatest(UPDATE_DOCUMENT_FILE, updateDocumentFileSaga);
}

export function* watchLinkFile() {
  yield takeLatest(LINK_FILE, watchLinkFileSaga);
}

export function* watchLinkFileToTask() {
  yield takeLatest(LINK_FILE_TASK, watchLinkFileToTaskSaga);
}
