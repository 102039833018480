import React from 'react';
import MainHeader from '../../organisms/MainHeader';
import {LoginFooter} from '../../molecules/LoginFooter' 
import { ResetPassword } from '../../organisms/Auth/Container/ResetPassword';
import '../Login/styles.scss';
import './style.scss';
import logo from '../../images/logo.svg';

export const ResetPasswordComponent = props => {
  return (
    <>
      <MainHeader />
      <div
        className=" auth-body d-flex justify-content-center align-items-center resetpassword-body"
        style={{ height: '100vh' }}>
        <div className="d-flex justify-content-center align-items-center authWrapper">
        <div className="auth-container">
          <div className="auth-header">
            <h1>Reset Password</h1>
            <p>
              Enter your email to receive instructions on how to reset your
              password.
            </p>
          </div>
          <ResetPassword />
        </div>
        </div>
        <LoginFooter />
      </div>
      {/* <Container className="authWrapper" fluid>
        
      </Container> */}
    </>
  );
};
