import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getDashboardActivityAction } from '../../react-shared/src/Actions/dashboard';
import { checkActivityDate, formatTime } from '../../common/utils';
import Pagination from '../../atoms/Pagination';

export const DashboardActivity = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const {
    results: activityList = [],
    total_pages,
    page_size,
    count,
  } = useSelector(state => state.dashboardReducer?.dashboardActivity) || {};

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = page => {
    dispatch(getDashboardActivityAction({ page }));
  };

  const renderActivity = useMemo(() => {
    const groupedActivities = {};

    activityList.forEach(activity => {
      const category = checkActivityDate(activity.timestamp);

      if (!groupedActivities[category]) {
        groupedActivities[category] = [];
      }

      groupedActivities[category].push(activity);
    });

    return groupedActivities;
  }, [activityList]);

  return (
    <Container fluid className="section-container dashboard-activity">
      <Row>
        <h2 className="dashboard-activity-title mb-3">Room Activity</h2>
      </Row>
      <div>
        {Object.keys(renderActivity)?.map(key => {
          return (
            <div key={key} className="mt-2 mb-4">
              <div className="dashboard-activity-subtitle">
                <span className="material-symbols-rounded">
                  calendar_view_day
                </span>
                <div>{key}</div>
              </div>
              <div className="mt-3">
                {renderActivity[key]?.map((ac, idx) => {
                  return (
                    <div key={idx} className="dashboard-activity-item">
                      <div>
                        <div className="dashboard-activity-item--title">
                          {ac?.title}
                        </div>
                        <div className="dashboard-activity-item--des">
                          {ac?.description}
                        </div>
                        {ac?.type !== 'file' ? (
                          <div className="dashboard-activity-item--des mt-1">
                            {ac?.actual_content}
                          </div>
                        ) : (
                          <div className="dashboard-activity-item--file mt-1">
                            <span className="material-symbols-rounded">
                              file_present
                            </span>
                            <div className="dashboard-activity-item--file-name">
                              {ac?.actual_content}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="dashboard-activity-item--time">
                        {formatTime(ac.timestamp)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {activityList?.length === 0 && <div className="mt-5">No Activity</div>}
      </div>
      {activityList?.length > 0 && (
        <Pagination
          pageSize={page_size}
          totalItems={count}
          onPageChange={setCurrentPage}
          currentPage={currentPage}
          totalPages={total_pages}
        />
      )}
    </Container>
  );
};
