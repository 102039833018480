/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTaskCommentAction,
  getTaskCommentAction,
  editTaskCommentAction,
} from '../../../react-shared/src/Actions/tasksAction';
import { CommentsFilters } from '../../../molecules/CommentSectionFilters';
import {
  imageTextWrapper,
  getFormatedDate,
  checkUser,
  areYouaOwner,
  checkEditedDate,
} from '../../../common/utils';

export const TaskComments = props => {
  const { commentsList } = props;
  const [comments, setComment] = useState({});

  const updateCommentSection = useCallback(params => {
    setComment && setComment(prevState => ({ ...prevState, ...params }));
  }, []);

  const userInfor = useSelector(state => state?.loginReducer);

  const dispatch = useDispatch();

  const getCommentValue = event => {
    updateCommentSection({
      comment: event.target.value,
    });
  };

  useEffect(() => {
    updateCommentSection({
      ...commentsList,
    });
  }, []);

  const showReplyField = id => {
    updateCommentSection({
      ...comments,
      replyComment: {
        id,
      },
    });
  };

  const getreplyComment = (event, id) => {
    updateCommentSection({
      ...comments,
      replyComment: {
        ...comments?.replyComment,
        id: id,
        replyText: event.target.value,
      },
    });
  };

  const sendreplyComment = () => {
    sendComment({
      id: comments?.replyComment?.id,
      replyText: comments?.replyComment?.replyText,
    });
  };

  const sendComment = ({ id, replyText }) => {
    dispatch(
      addTaskCommentAction(
        {
          task: comments?.id,
          // section: comments?.id,
          parent: id ? id : '',
          comment_text: replyText ? replyText : comments?.comment,
        },
        response => {
          dispatch(
            getTaskCommentAction(
              { taskID: comments?.id },
              response => {
                updateCommentSection({
                  ...comments,
                  section_comment: response?.results,
                  comment: '',
                  replyComment: {},
                });
              },
              error => {
                console.log('error', error);
              },
            ),
          );
        },
        error => {
          console.log('error', error);
        },
      ),
    );
  };
  const editcomment = (id, text, replySection = false, commentid) => {
    if (replySection) {
      updateCommentSection({
        ...comments,
        replyComment: {
          ...comments?.replyComment,
          id: id,
          replyText: text,
        },
      });
    } else {
      updateCommentSection({
        comment: text,
        editCommentid: id,
        editMode: true,
      });
    }
  };
  const editcommentSend = id => {
    dispatch(
      editTaskCommentAction(
        {
          id: comments?.editCommentid,
          params: {
            comment_text: comments?.comment,
          },
        },
        response => {
          dispatch(
            getTaskCommentAction(
              { taskID: comments?.id },
              response => {
                updateCommentSection({
                  ...comments,
                  section_comment: response?.results,
                  comment: '',
                  replyComment: {},
                });
              },
              error => {
                console.log('error', error);
              },
            ),
          );
        },
        error => {
          console.log('error', error);
        },
      ),
    );
  };

  const deleteComment = id => {
    console.log('delted id', id);
  };
  return (
    <>
      <Col md={12}>
          <>
            <CommentsFilters count={comments?.section_comment} />
          </>
        {comments?.section_comment?.map((comment, index) => {
          return (
            <div className="main-comments-wrappers" key={index}>
              <div>
                <div>
                  <div className="comment-text-wrapper replay-comment">
                    <div className="mesage-wrapper ">
                      <p className="comment-text">
                        {imageTextWrapper(comment?.comment_by)}{' '}
                        {comment?.comment_text}
                      </p>
                      {areYouaOwner(userInfor, comment?.comment_by) && (
                        <Dropdown className="nav-bar-custom-dropdown">
                          <DropdownToggle className="custom-button-dropdown-toggle">
                            <span className="material-symbols-rounded">
                              more_vert
                            </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              <div
                                className="sign-out-dropdown-item"
                                onClick={() =>
                                  editcomment(
                                    comment?.id,
                                    comment?.comment_text,
                                  )
                                }>
                                <span className="material-symbols-rounded">
                                  edit_document
                                </span>
                                <div>Edit</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem>
                              <div
                                className="sign-out-dropdown-item"
                                onClick={() =>
                                  deleteComment(
                                    comment?.id,
                                    comment?.comment_text,
                                  )
                                }>
                                <span className="material-symbols-rounded">
                                  delete
                                </span>
                                <div>Delete</div>
                              </div>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </div>
                    <p className="time">
                      {checkUser(comment, userInfor)}
                      <span>
                        {checkEditedDate(
                          comment?.created_at,
                          comment?.modified_at,
                        )
                          ? `Edited At ${getFormatedDate(comment?.modified_at)}`
                          : getFormatedDate(comment?.created_at)}
                      </span>
                    </p>
                  </div>
                </div>
                {comment?.replies &&
                  comment?.replies.map(reply => {
                    return (
                      <div className="reply-comment" key={reply?.id}>
                        <p className="profile-name">
                          <span className="name"></span>
                        </p>
                        <div className="comment-text-wrapper">
                          <div className="mesage-wrapper ">
                            <p className="comment-text">
                              {imageTextWrapper(reply?.comment_by)}
                              {reply?.comment_text}
                            </p>
                            {/* {areYouaOwner(userInfor, comment?.comment_by) && (
                              <Dropdown className="nav-bar-custom-dropdown">
                                <DropdownToggle className="custom-button-dropdown-toggle">
                                  <span className="material-symbols-rounded">
                                    more_vert
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    <div
                                      className="sign-out-dropdown-item"
                                      onClick={() =>
                                        editcomment(
                                          comment?.replies?.[0]?.id,
                                          reply?.comment_text,
                                          true,
                                          comment?.id,
                                        )
                                      }>
                                      <span className="material-symbols-rounded">
                                        edit_document
                                      </span>
                                      <div>Edit</div>
                                    </div>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <div
                                      className="sign-out-dropdown-item"
                                      onClick={() =>
                                        deleteComment(
                                          comment?.id,
                                          comment?.comment_text,
                                        )
                                      }>
                                      <span className="material-symbols-rounded">
                                        delete
                                      </span>
                                      <div>Delete</div>
                                    </div>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            )} */}
                          </div>
                          <p className="time">
                            {checkUser(comment, userInfor)}
                            <span>{getFormatedDate(reply?.created_at)}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                {!comment?.replies.length > 0 && (
                  <>
                    <div className="commet-replySection">
                      <p onClick={() => showReplyField(comment?.id)}>Reply</p>
                      {/* <button
                        className="replyin"
                        >
                        <span className="material-symbols-rounded">reply</span>
                        Replay
                      </button> */}
                    </div>
                  </>
                )}
                {comments?.replyComment?.id === comment?.id && (
                  <div className="reply-section">
                    <textarea
                      type="text"
                      key={comment?.id}
                      className="reply-text-field"
                      placeholder="Reply to comment"
                      id={comment?.id}
                      onChange={event => getreplyComment(event, comment?.id)}
                      value={comments?.comment?.replyText}
                      rows="1"
                    />
                    <button onClick={sendreplyComment} className="replyButton">
                      <span className="material-symbols-rounded">send</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Col>
      <div className="dealsection-comment">
        <textarea
          placeholder="Leave a new comment"
          onChange={event => getCommentValue(event)}
          value={comments?.comment}
          rows="1"
        />
        <button className="replyButton">
          <span
            className="material-symbols-rounded"
            onClick={comments?.editMode ? editcommentSend : sendComment}>
            send
          </span>
        </button>
      </div>
    </>
  );
};
