/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { LeftPanelMainOptions } from '../../organisms/MainLeftPannel';
import { Row, Col, Accordion } from 'react-bootstrap';
import { NavBar } from '../../organisms/NavBar';
import { DocumentsHeader } from '../../organisms/Documents/DocumentsHeader';
import { DocumentsSectionHeader } from '../../organisms/Documents/DocumentsSectionHeader';
import iconFile from '../../images/upload-file.svg';
import { DocumentFileItem } from '../../atoms/DocumentFileItem';
import { useDispatch } from 'react-redux';
import {
  deleteDocumentFileAction,
  getFolderDetailAction,
  uploadDocumentFileAction,
} from '../../react-shared/src/Actions/fileManager';
import { EmptyData } from '../../organisms/Documents/EmptyData';
import { ToastMessage } from '../../atoms/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import { FileDetail } from '../../organisms/Documents/FileDetail';

const sortOptions = [
  { label: 'Title A-Z', value: 'a-z' },
  { label: 'Latest to oldest', value: 'latest' },
];

const filterOptions = [
  { label: 'All Types', value: 'all' },
  { label: 'Documents', value: 'file' },
  { label: 'Images', value: 'image' },
  { label: 'Video', value: 'video' },
];

export const DocumentsFolderDetails = props => {
  const {noAction = false, folderID, getFolderDetails, selectedFileList, uploadDocumentList} = props;
  console.log('folderID', folderID, noAction);
  const navigate = useNavigate();
  const params = useParams();
  const id = noAction ? folderID: params?.id;

  const dispatch = useDispatch();

  const [folderDetailData, setFolderDetailData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(filterOptions[0].value);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (!id) {
      navigate('/document');
    }
    getFolderDetail(id);
  }, [id]);

  const getFolderDetail = folder_id => {
    dispatch(
      getFolderDetailAction(
        { id: folder_id },
        response => {
          console.log('response', response);
          setFolderDetailData(response);
          noAction && getFolderDetails(response);
        },
        error => {
          ToastMessage({
            success: false,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleSelectSortBy = e => {
    setSort(e.target.value);
  };

  const handleSelectFilterBy = e => {
    setFilter(e.target.value);
  };

  const handleUploadFile = event => {
    const files = [...event.target.files];
    console.log('upload: ', files);
    if (files?.length === 0) {
      ToastMessage({
        success: false,
        message: 'Please select files first!',
      });
      return;
    }
    const formData = new FormData();
    files?.forEach((file, idx) => {
      formData.append(`files[${idx}]`, file);
    });
    formData.append('folder', id);
    event.target.value = null;

    dispatch(
      uploadDocumentFileAction(
        formData,
        response => {
          console.log('response', response);
          ToastMessage({
            success: true,
            message: 'Upload file sucessfully',
          });
          getFolderDetail(id);
        },
        error => {
          ToastMessage({
            success: false,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleCloseFileDetail = () => {
    setSelectedFile(null);
  };

  const handleDeleteFile = () => {
    dispatch(
      deleteDocumentFileAction(
        { id: selectedFile?.id },
        response => {
          console.log('response', response);
          setSelectedFile(null);
          getFolderDetail(id);
          ToastMessage({
            success: true,
            message: 'Deleted file successfully',
          });
        },
        error => {
          ToastMessage({
            success: false,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const listFileFilterd = useMemo(() => {
    let temp = folderDetailData?.folder_file
      ? [...folderDetailData?.folder_file]
      : [];
    if (filter !== 'all') {
      temp = [...folderDetailData?.folder_file]?.filter(f => f.type === filter);
    }
    if (sort === 'a-z') {
      temp.sort((a, b) => {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1;
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    if (sort === 'latest') {
      temp.sort((a, b) => {
        const dateA = new Date(a.last_mod_date);
        const dateB = new Date(b.last_mod_date);
        return dateB - dateA;
      });
    }
    if (!!searchTerm?.trim()) {
      const searchedItems = temp.filter(item =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
      );
      return searchedItems;
    }
    return temp;
  }, [folderDetailData?.folder_file, filter, sort, searchTerm]);

  const setSelectedFileHandler = (item) => {
    uploadDocumentList(item);
  }
  console.log('selectedFileList', selectedFileList);
  return (
    <>
      <Row className={`${noAction ? 'modal-document-page' : 'document-page'}`}>
        {
          !noAction && (    
            <Col md={2}>
              <LeftPanelMainOptions />
            </Col>
          )
        }
        <Col md={noAction? 12: 10}>
        {
          !noAction && (
            <NavBar
            text={folderDetailData?.name || 'Folder'}
            backarrowShow={'Show'}
            owner
            onBack={() => navigate('/document')}
          />
          )
        }

          <DocumentsHeader
            searchTerm={searchTerm}
            onClear={handleClearSearch}
            onChangeSearch={handleSearchChange}
            onSelectSort={handleSelectSortBy}
            onSelectFilter={handleSelectFilterBy}
            sort={sort}
            filter={filter}
            sortOptions={sortOptions}
            filterOptions={filterOptions}
          />
          <Accordion defaultActiveKey="0" alwaysOpen={true}>
            <div className="document-page-section">
              <DocumentsSectionHeader
                name="Files"
                btnText="Upload File"
                imageULR={iconFile}
                eventKey="0"
                supportUploadFile
                handleFileChange={handleUploadFile}
                noAction = {noAction}
              />
              <Accordion.Collapse eventKey="0">
                <>
                  <div className="document-page--section-list">
                    {listFileFilterd?.map(item => {
                      return (
                        <DocumentFileItem
                          key={item.id}
                          fileName={item.name}
                          fileSize={`${item.size_in_mb} MB`}
                          fileUrl={item.file}
                          isLoading={item?.loading}
                          onClick={() => {
                            if (!noAction) {
                              setSelectedFile(item);
                            }
                          }}
                          noAction = {noAction}
                          clickSelectHandler ={setSelectedFileHandler}
                          params = {item}
                          id={item?.id}
                          selectedFileList ={selectedFileList}
                        />
                      );
                    })}
                  </div>
                  {listFileFilterd?.length === 0 && <EmptyData />}
                </>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </Col>
      </Row>
      <FileDetail
        show={!!selectedFile}
        data={selectedFile}
        handleClose={handleCloseFileDetail}
        handleDeleteFile={handleDeleteFile}
        refreshFolderData={() => getFolderDetail(id)}
        folderId={id}
      />
    </>
  );
};
