/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { LeftPanelMainOptions } from '../../organisms/MainLeftPannel';
import { Row, Col, Accordion } from 'react-bootstrap';
import { NavBar } from '../../organisms/NavBar';
import { DocumentsHeader } from '../../organisms/Documents/DocumentsHeader';
import './style.scss';
import { DocumentsSectionHeader } from '../../organisms/Documents/DocumentsSectionHeader';
import iconFolder from '../../images/create-folder.svg';
import iconFile from '../../images/upload-file.svg';
import { CreateFolder } from '../../organisms/Documents/CreateFolder';
import { DocumentFileItem } from '../../atoms/DocumentFileItem';
import { DocumentFolderItem } from '../../atoms/DocumentFolderItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDocumentFileAction,
  getFileListAction,
  getFolderListAction,
  updateDocumentFileAction,
  uploadDocumentFileAction,
} from '../../react-shared/src/Actions/fileManager';
import { EmptyData } from '../../organisms/Documents/EmptyData';
import { ToastMessage } from '../../atoms/Toast';
import { useNavigate } from 'react-router-dom';
import { FileDetail } from '../../organisms/Documents/FileDetail';

const sortOptions = [
  { label: 'Title A-Z', value: 'a-z' },
  { label: 'Latest to oldest', value: 'latest' },
];

const filterOptions = [
  { label: 'All Types', value: 'all' },
  { label: 'Documents', value: 'file' },
  { label: 'Images', value: 'image' },
  { label: 'Video', value: 'video' },
];

export const Documents = props => {
  const { noAction = false, redirectToFolderView, uploadDocumentList, selectedFileList} = props;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const folderList =
    useSelector(state => state.fileManagerReducer?.folderData?.results) || [];
  const fileList =
    useSelector(state => state.fileManagerReducer?.fileData?.results) || [];

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(filterOptions[0].value);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    dispatch(getFolderListAction());
    dispatch(getFileListAction());
  }, []);

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleSearchChange = e => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
  };

  const handleSelectSortBy = e => {
    setSort(e.target.value);
  };

  const handleSelectFilterBy = e => {
    setFilter(e.target.value);
  };

  const handleCreateFolder = () => {
    setShowCreateFolder(true);
  };

  const handleUploadFile = event => {
    const files = [...event.target.files];
    console.log('upload: ', files);
    if (files?.length === 0) {
      ToastMessage({
        success: false,
        message: 'Please select files first!',
      });
      return;
    }
    const formData = new FormData();
    files?.forEach((file, idx) => {
      formData.append(`files[${idx}]`, file);
    });
    event.target.value = null;

    dispatch(
      uploadDocumentFileAction(
        formData,
        response => {
          console.log('response', response);
          ToastMessage({
            success: true,
            message: 'Upload file sucessfully',
          });
          dispatch(getFileListAction());
        },
        error => {
          ToastMessage({
            success: false,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleCloseCreateFolder = () => {
    setShowCreateFolder(false);
    setSelectedFolder(null);
  };

  const handleClickRename = item => {
    setSelectedFolder(item);
  };

  const handleCloseFileDetail = () => {
    setSelectedFile(null);
  };

  const handleDeleteFile = () => {
    dispatch(
      deleteDocumentFileAction(
        { id: selectedFile?.id },
        response => {
          console.log('response', response);
          setSelectedFile(null);
          dispatch(getFileListAction());
          ToastMessage({
            success: true,
            message: 'Deleted file successfully',
          });
        },
        error => {
          ToastMessage({
            success: false,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleFileDrop = (fileId, folderId) => {
    dispatch(
      updateDocumentFileAction(
        { folder: folderId, id: fileId },
        response => {
          console.log('response', response);
          dispatch(getFolderListAction());
          ToastMessage({
            success: true,
            message: 'File moved successfully',
          });
        },
        error => {
          ToastMessage({
            error: true,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const listFileFilterd = useMemo(() => {
    let temp = [...fileList];
    if (filter !== 'all') {
      temp = [...fileList]?.filter(f => f.type === filter);
    }
    if (sort === 'a-z') {
      temp.sort((a, b) => {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1;
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    if (sort === 'latest') {
      temp.sort((a, b) => {
        const dateA = new Date(a.last_mod_date);
        const dateB = new Date(b.last_mod_date);
        return dateB - dateA;
      });
    }
    if (!!searchTerm?.trim()) {
      const searchedItems = temp.filter(item =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
      );
      return searchedItems;
    }
    return temp;
  }, [fileList, filter, sort, searchTerm]);

  const listFolderSorted = useMemo(() => {
    let temp = [...folderList];

    if (sort === 'a-z') {
      temp.sort((a, b) => {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1;
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    if (sort === 'latest') {
      temp.sort((a, b) => {
        const dateA = new Date(a.modified_at);
        const dateB = new Date(b.modified_at);
        return dateB - dateA;
      });
    }
    if (!!searchTerm?.trim()) {
      const searchedItems = temp.filter(item =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
      );
      return searchedItems;
    }
    return temp;
  }, [folderList, sort, searchTerm]);

  const setSelectedFileHandler = (item) => {
    uploadDocumentList(item);
  }


  return (
    <>
      <Row className={`${noAction ? 'modal-document-page' : 'document-page'}`}>
        {!noAction && (
          <Col md={2}>
            <LeftPanelMainOptions />
          </Col>
        )}

        <Col md={noAction? 12: 10}>
          {!noAction && <NavBar text="Documents" />}
          <DocumentsHeader
            searchTerm={searchTerm}
            onClear={handleClearSearch}
            onChangeSearch={handleSearchChange}
            onSelectSort={handleSelectSortBy}
            onSelectFilter={handleSelectFilterBy}
            sort={sort}
            filter={filter}
            sortOptions={sortOptions}
            filterOptions={filterOptions}
          />
          <Accordion defaultActiveKey="0" alwaysOpen={true}>
            <div className="document-page-section">
              <DocumentsSectionHeader
                name="Folder"
                btnText="New Folder"
                imageULR={iconFolder}
                handleClickBtn={handleCreateFolder}
                eventKey="0"
                noAction={noAction}
              />

              <Accordion.Collapse eventKey="0">
                <>
                  <div className="document-page--section-list">
                    {listFolderSorted?.map(item => {
                      return (
                        <DocumentFolderItem
                          key={item?.id}
                          fileName={item?.name}
                          fileNo={item?.file_count}
                          description={item?.description}
                          onClickRename={() => handleClickRename(item)}
                          onClick={() => {
                            if (noAction) {
                              redirectToFolderView(item.id);
                            }
                            else {
                              navigate(`/document/folder/${item.id}`)
                            }
                          }}
                          id={item?.id}
                          onDropFile={handleFileDrop}
                          noAction={noAction}
                        />
                      );
                    })}
                  </div>
                  {listFolderSorted?.length === 0 && <EmptyData />}
                </>
              </Accordion.Collapse>
            </div>
            <div className="document-page-section">
              <DocumentsSectionHeader
                name="Files"
                btnText="Upload File"
                imageULR={iconFile}
                eventKey="1"
                supportUploadFile
                handleFileChange={handleUploadFile}
                noAction={noAction}
              />
              <Accordion.Collapse eventKey="1">
                <>
                  <div className="document-page--section-list">
                    {listFileFilterd?.map(item => {
                      return (
                        <DocumentFileItem
                          key={item.id}
                          fileName={item.name}
                          fileSize={`${item.size_in_mb} MB`}
                          fileUrl={item.file}
                          isLoading={item?.loading}
                          onClick={() => {
                            if (!noAction) {
                              setSelectedFile(item);
                            }
                          }}
                          id={item.id}
                          canDrag
                          selectedFileList={selectedFileList}
                          noAction={noAction}
                          clickSelectHandler = {setSelectedFileHandler}
                          params={item}
                        />
                      );
                    })}
                  </div>
                  {listFileFilterd?.length === 0 && <EmptyData />}
                </>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </Col>
      </Row>
      {!noAction && (
        <CreateFolder
          show={showCreateFolder || !!selectedFolder}
          data={selectedFolder}
          handleClose={handleCloseCreateFolder}
        />
      )}
      {!noAction && (
        <FileDetail
          show={!!selectedFile}
          data={selectedFile}
          handleClose={handleCloseFileDetail}
          handleDeleteFile={handleDeleteFile}
        />
      )}
    </>
  );
};
