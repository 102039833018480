import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';
import { APPURL, apiConfig } from '../config';
import {
  DEAL_LIST,
  DEAL_LIST_SUCCESS,
  DEAL_LIST_FAILURE,
  CREATE_NEW_DEAL,
  CREATE_NEW_DEAL_SUCCESS,
  CREATE_NEW_DEAL_FAILURE,
  EACH_DEAL,
  EACH_DEAL_SUCCESS,
  EACH_DEAL_FAILURE,
  EACH_SECTION,
  EEACH_SECTIONL_SUCCESS,
  EACH_SECTIONL_FAILURE,
  ADD_SECTION,
  ADD_SECTION_SUCCESS,
  ADD_SECTION_FAILURE,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  TASK_COMPLETE,
  TASK_COMPLETE_SUCCESS,
  TASK_COMPLETE_FAILURE,
  TASK_LIST,
  TASK_LIST_SUCCESS,
  TASK_LIST_FAILURE,
  CREAT_TASK,
  CREAT_TASK_SUCCESS,
  CREAT_TASK_FAILURE,
  UPDATE_DEAL,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_FAILURE,
  GET_PINNED,
  GET_PINNED_SUCCESS,
  GET_PINNED_FAILURE,
  ADD_PINNED,
  ADD_PINNED_SUCCESS,
  ADD_PINNED_FAILURE,
  INVITE_COLLABORATORS_SUCCESS,
  INVITE_COLLABORATORS_FAILURE,
  INVITE_COLLABORATORS,
  EDIT_SECTION,
  EDIT_SECTION_SUCCESS,
  EDIT_SECTION_FAILURE,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  CUPDATE_TASK_FAILURE,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  REMOVE_COLLABORATORS,
  REMOVE_COLLABORATORS_SUCCESS,
  REMOVE_COLLABORATORS_FAILURE,
  GET_COLLABORATORS,
  GET_COLLABORATORS_SUCCESS,
  GET_COLLABORATORS_FAILURE,
  GET_SINGLE_SECTION,
  GET_SINGLE_SECTION_SUCCESS,
  GET_SINGLE_SECTION_FAILURE,
} from '../Constants/constants';
// const Rurl = 'https://mmerge.onrender.com/en/login/'
export function* getDealListSaga(request) {
  const { payload, successCallback = null, errorCallback = null } = request;
  const statusURL = payload?.status
    ? `${APPURL}deal/room/?status=${payload?.status}`
    : `${APPURL}deal/room/`;
  try {
    const result = yield call(apiConfig.get, statusURL);
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: DEAL_LIST_SUCCESS,
        data,
      });
      if (typeof successCallback === 'function') {
        successCallback?.(data);
      }
    } else {
      yield put({
        type: DEAL_LIST_FAILURE,
        data,
      });
      if (typeof errorCallback === 'function') {
        errorCallback?.(data);
      }
    }
  } catch (error) {
    yield put({
      type: DEAL_LIST_FAILURE,
      error,
    });
    if (typeof errorCallback === 'function') {
      errorCallback?.(error);
    }
  }
}

// Generator function
export function* watchGetDealList() {
  yield takeLatest(DEAL_LIST, getDealListSaga);
}

export function* newDealCreateSaga(request) {
  const {
    payload,
    successCallback = () => {},
    errorCallback = () => {},
  } = request;
  try {
    const result = yield call(apiConfig.post, `${APPURL}deal/room/`, payload);
    const { status, data } = result;
    if (status === 201) {
      yield put({
        type: CREATE_NEW_DEAL_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: CREATE_NEW_DEAL_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: CREATE_NEW_DEAL_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchNewDealCreate() {
  yield takeLatest(CREATE_NEW_DEAL, newDealCreateSaga);
}

export function* getEachDealSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/room/${payload?.id}`,
    );
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: EACH_DEAL_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: EACH_DEAL_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: EACH_DEAL_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchEachGetDeal() {
  yield takeLatest(EACH_DEAL, getEachDealSaga);
}

export function* getSectionSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const statusURL = `${APPURL}deal/section/?deal_room=${payload?.id}`;
  try {
    const result = yield call(apiConfig.get, statusURL);
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: EEACH_SECTIONL_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: EACH_SECTIONL_FAILURE,
        data,
      });
      // errorCallback?.((data));
    }
  } catch (error) {
    yield put({
      type: EACH_SECTIONL_FAILURE,
      error,
    });
    // errorCallback?.(error);
  }
}
export function* watchGetSection() {
  yield takeLatest(EACH_SECTION, getSectionSaga);
}

export function* newSectionSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  try {
    const result = yield call(
      apiConfig.post,
      `${APPURL}deal/section/`,
      payload,
    );
    const { status, data } = result;
    if (status === 201) {
      yield put({
        type: ADD_SECTION_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: ADD_SECTION_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: ADD_SECTION_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchNewSection() {
  yield takeLatest(ADD_SECTION, newSectionSaga);
}

export function* newFileUploadSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('payload', payload);
  try {
    const result = yield call(apiConfig.post, `${APPURL}file/`, payload);
    const { status, data } = result;
    if (status === 201) {
      yield put({
        type: ADD_SECTION_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: ADD_SECTION_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: ADD_SECTION_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchNewFileUpload() {
  yield takeLatest(FILE_UPLOAD, newFileUploadSaga);
}

export function* taskCompletSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/task/${payload?.id}/`,
      { status: payload?.status },
    );
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: TASK_COMPLETE_SUCCESS,
        payload: data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: TASK_COMPLETE_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: TASK_COMPLETE_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchTaskCompletedSaga() {
  yield takeLatest(TASK_COMPLETE, taskCompletSaga);
}

export function* taskListSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/task/?deal_room=${payload?.deal_room}&section=${payload?.section}`,
    );

    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: TASK_LIST_SUCCESS,
        data,
      });
      // successCallback?.(data);
    } else {
      yield put({
        type: TASK_LIST_FAILURE,
        data,
      });
      // errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: TASK_LIST_FAILURE,
      error,
    });
    // errorCallback?.(error);
  }
}

// Generator function
export function* watchTaskListSaga() {
  yield takeLatest(TASK_COMPLETE_SUCCESS, taskListSaga);
}

export function* createTaskSaga(request) {
  const { payload = {}, successCallback = {}, errorCallback = {} } = request;
  try {
    const result = yield call(apiConfig.post, `${APPURL}deal/task/`, payload);
    const { status, data } = result;
    if (status === 201) {
      yield put({
        type: CREAT_TASK_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: CREAT_TASK_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: CREAT_TASK_FAILURE,
      error,
    });
  }
}

// Generator function
export function* watchcreateTaskSaga() {
  yield takeLatest(CREAT_TASK, createTaskSaga);
}

export function* updateDealSaga(request) {
  const { payload = {}, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/room/${id}/`,
      params,
    );

    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: UPDATE_DEAL_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: UPDATE_DEAL_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: UPDATE_DEAL_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchupdateDealSaga() {
  yield takeLatest(UPDATE_DEAL, updateDealSaga);
}

export function* getPinnedSage(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/room/?pinned=${payload?.pinned}`,
    );

    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: GET_PINNED_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: GET_PINNED_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: GET_PINNED_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchGetPinnedSaga() {
  yield takeLatest(GET_PINNED, getPinnedSage);
}

export function* addPinedSaga(request) {
  const {
    payload,
    successCallback = () => {},
    errorCallback = () => {},
  } = request;
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/room/${payload?.id}/`,
      payload?.params,
    );
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: ADD_PINNED_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: ADD_PINNED_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: ADD_PINNED_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchaddPinedSaga() {
  yield takeLatest(ADD_PINNED, addPinedSaga);
}

export function* inviteCollaboratorsSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  try {
    const result = yield call(
      apiConfig.post,
      `${APPURL}deal/invite_colloborator/${id}/`,
      params,
    );
    const { status, data } = result;

    if (status === 200) {
      yield put({
        type: INVITE_COLLABORATORS_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: INVITE_COLLABORATORS_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    yield put({
      type: INVITE_COLLABORATORS_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchInviteCollaboratorsSaga() {
  yield takeLatest(INVITE_COLLABORATORS, inviteCollaboratorsSaga);
}

export function* editSectionSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  console.log('payload', payload);
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/section/${id}/`,
      params,
    );
    const { status, data } = result;

    if (status === 200) {
      yield put({
        type: EDIT_SECTION_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: EDIT_SECTION_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: EDIT_SECTION_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watcheditSectionSaga() {
  yield takeLatest(EDIT_SECTION, editSectionSaga);
}

export function* updateTaskSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  console.log('payload', payload?.params);
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/task/${id}/`,
      params,
    );
    const { status, data } = result;

    if (status === 200) {
      yield put({
        type: UPDATE_TASK_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: CUPDATE_TASK_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: CUPDATE_TASK_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchupdateTaskSaga() {
  yield takeLatest(UPDATE_TASK, updateTaskSaga);
}


export function* deleteFileSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  console.log('payload', payload?.params);
  try {
    const result = yield call(
      apiConfig.delete,
      `${APPURL}file/${id}`,
      params,
    );
    const { status, data } = result;

    if (status === 200) {
      yield put({
        type: DELETE_FILE_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: DELETE_FILE_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: DELETE_FILE_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchudeleteFileSaga() {
  yield takeLatest(DELETE_FILE, deleteFileSaga);
}

export function* removeCollaboratorSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  console.log('payload', payload?.params);

  try {
    const result = yield call(
      apiConfig.post,
      `${APPURL}deal/manage_participants/?deal_room=${payload?.deal_room}&user=${payload?.userID}`,
      params,
    );
    const { status, data } = result;

    if (status === 201) {
      yield put({
        type: REMOVE_COLLABORATORS_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: REMOVE_COLLABORATORS_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: REMOVE_COLLABORATORS_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchRemoveCollaboratorSaga() {
  yield takeLatest(REMOVE_COLLABORATORS, removeCollaboratorSaga);
}

export function* getCollaboratorSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  console.log('payload', payload);

  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/share/?deal_room=${payload?.id}?section=${payload?.id2}`,
    );
    const { status, data } = result;

    if (status === 200) {
      yield put({
        type: GET_COLLABORATORS_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: GET_COLLABORATORS_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: GET_COLLABORATORS_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchGetCollaboratorSaga() {
  yield takeLatest(GET_COLLABORATORS, getCollaboratorSaga);
}

export function* getSingleSectionSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  const { id, params } = payload;
  console.log('payload', payload);

  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/section/${payload?.id}/`,
    );
    const { status, data } = result;

    if (status === 200) {
      yield put({
        type: GET_SINGLE_SECTION_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      yield put({
        type: GET_SINGLE_SECTION_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    yield put({
      type: GET_SINGLE_SECTION_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchGetSingleSectionSaga() {
  yield takeLatest(GET_SINGLE_SECTION, getSingleSectionSaga);
}

