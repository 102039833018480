import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SectionWrapper } from '../../atoms/SectionWrapper';
import gmail from '../../images/gmail.png';
import googleCalendar from '../../images/google-calendar.png';
import zoom from '../../images/zoom.png';
import hubspot from '../../images/hubspot.png';
import thunder from '../../images/thunder.svg';
import connectIcon from '../../images/connect.svg';
import disconnectIcon from '../../images/disconnect.svg';

const mockData = [
  { name: 'Gmail', icon: gmail, isConnected: true },
  { name: 'Google Calendar', icon: googleCalendar, isConnected: false },
  { name: 'Zoom Meetings', icon: zoom, isConnected: false },
  { name: 'Hubspot CRM', icon: hubspot, isConnected: false },
];

export const CompanyIntegrations = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Container fluid className="section-container">
      <Row>
        <h2 className="company-section-title mb-3">Integrations</h2>
      </Row>
      <SectionWrapper>
        <SectionWrapper>
          <div className="d-flex align-items-center justify-content-start mb-2">
            <img src={thunder} alt="thunder" className="me-2" />
            <h2 className="company-section-title">3rd Party Integrations</h2>
          </div>
          <div className="company-section-subtitle">
            You can integrate your existing workflows or tools to import your
            current data. We are constantly adding more integrations.{' '}
            <span>Request an integration</span>
          </div>
        </SectionWrapper>
        <div className="company-3rd-party-list">
          {mockData.map((p, idx) => {
            return (
              <div key={idx} className="company-3rd-party-item">
                <div className="company-3rd-party--info">
                  <img src={p.icon} alt={p.name} />
                  <div className="company-3rd-party--name">{p.name}</div>
                </div>
                <div className="company-3rd-party--btns">
                  {p.isConnected ? (
                    <>
                      <div className="btn-text">Connected</div>
                      <div className="btn-with-icon red">
                        <img src={disconnectIcon} alt="disconnect" />
                        <div>Disconnect</div>
                      </div>
                    </>
                  ) : (
                    <div className="btn-with-icon">
                      <img src={connectIcon} alt="connect" />
                      <div>Connect</div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </SectionWrapper>
    </Container>
  );
};
