/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Button } from '../../../atoms/Button';
import {editSection} from '../../../react-shared/src/Actions/DealsActions';
import { ToastMessage } from '../../../atoms/Toast';
const EditSectionForm = (props) => {
  const { updateDealRoomDetails, dealRoomDetails, handleClose} = props;
  console.log('dealRoomDetails', dealRoomDetails);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [formData, setFormData] = useState({
    // sectionname: '',
    notes: '',
  });
  const dispatch = useDispatch();
  useState(() => {
    setFormData({
      // sectionname: dealRoomDetails?.name,
      notes: dealRoomDetails?.notes,
    })
  }, [])
  console.log('dealRoomDetails', dealRoomDetails);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValue(name, value); // Update react-hook-form state
    // setUpdatedNotes(formData)
  };

  const onSubmit = (data) => {
    console.log(data); // Handle form submission
    setLoading(true);
    dispatch(editSection({
      id:dealRoomDetails?.id,
      params: {
        // name: formData?.sectionname,
        notes: formData?.notes,
      }
    }, response => {
      ToastMessage({
        success: true,
        message: 'Edited notes section successfully',
      });
      updateDealRoomDetails({
        sectionupdatedResponse: response,
      })
      handleClose();
      setLoading(false);
    }, error => {
      console.log('error', error);
      ToastMessage({
        success: false,
        message: 'Something went wrong please try again',
      });
      handleClose();
      setLoading(false);
    }))
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className='inputGroup'>
          <label>Section Name</label>
          <input
            type="text"
            className={errors.sectionname ? 'errorfield' : ''}
            name='sectionname'
            placeholder="Enter section name"
            {...register('sectionname', { required: true })}
            value={formData.sectionname}
            onChange={handleInputChange}
          />
          {errors.sectionname && <span>This field is required</span>}
        </div> */}
        <div className='inputGroup'>
          <label>Notes</label>
          <textarea
            className={errors.notes ? 'errorfield' : ''}
            name='notes'
            placeholder="Enter notes"
            {...register('notes')}
            value={formData.notes}
            onChange={handleInputChange}
          />
        </div>
        <Row className='sticky-button'>
        <Col md={6}>
          <Button
            varient="secondary"
            text="Cancel"
            type="button"
            onClickHandler={handleClose}>
            Close
          </Button>
        </Col>
        <Col md={6}>
          <Button
            varient="primary"
            text="Save"
            type="submit"
            // disabled={fields.length === 0}
            loading={loading}>
          </Button>
        </Col>
      </Row>
      </form>
    </>
  );
};

export default EditSectionForm;
