export const Dealroomtabs = [
  {
    text: 'All Deals',
    status: '',
  },
  {
    text: 'Active',
    status: 'A',
  },
  {
    text: 'Closed',
    status: 'C',
  },
  {
    text: 'On Hold',
    status: 'O',
  },
  {
    text: 'Canceled',
    status: 'C',
  },
];