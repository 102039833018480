import React, { useEffect, useCallback } from 'react';
import { LeftPanelMainOptions } from '../../organisms/MainLeftPannel';
import { NoDealHeader } from '../../organisms/NoDealPanel';
import { Row, Col } from 'react-bootstrap';
import { NavBar } from '../../organisms/NavBar';
import { SectionTabs } from '../../atoms/SectionTabs';
import { CompanyProfileForm } from '../../organisms/Company/CompanyProfile';
import { CompanyContacts } from '../../organisms/Company/CompanyContacts';

import './style.scss';
import { CompanyIntegrations } from '../../organisms/Company/CompanyIntegrations';

export const Company = props => {
  const tabs = [
    { label: 'Company Profile', content: <CompanyProfileForm /> },
    { label: 'Manage Contacts', content: <CompanyContacts /> },
    { label: 'Welcome Page', content: <NoDealHeader /> },
    { label: 'Integrations', content: <CompanyIntegrations /> },
  ];

  return (
    <Row className="company-page-container">
      <Col md={2}>
        <LeftPanelMainOptions />
      </Col>
      <Col md={10}>
        <NavBar text="Company" />
        <SectionTabs data={tabs} />
      </Col>
    </Row>
  );
};
