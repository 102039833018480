/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AddFileForm from '../Forms/AddFileForm';
import { Documents } from '../../../components/Documents';
import { DocumentsFolderDetails } from '../../../components/DocumentsFolderDetails';
import './styles.scss';

export const AddDocuments = props => {
  console.log('props', props);
  const { filesFromDocument, SetFilesFromDocument, modalShow, handleClose } =
    props;

  const [folderId, setFolderID] = useState('');
  const [showFolder, setFolder] = useState(false);
  const [folderDetails, setFolderDetails] = useState('');
  const [selectedFileList, setSelectedFileList] = useState(filesFromDocument);
  

  const redirectToFolderView = id => {
    console.log('id', id);
    setFolderID(id);
    setFolder(true);
  };

  const getFolderDetails = data => {
    console.log('data----> folder', data);
    setFolderDetails(data);
  };

  const backToFiles = () => {
    setFolderDetails('');
    setFolderID('');
    setFolder(false);
  }

  const uploadDocumentList = (data) => {
    setSelectedFileList((prevList) => {
      // Check if the file with the same id exists
      const isDuplicate = prevList.some(file => file.id === data.id);
  
      // Create the updated list by either adding or removing the file
      const updatedList = isDuplicate
        ? prevList.filter(file => file.id !== data.id) // Remove duplicate
        : [...prevList, data]; // Add new file
  
      // Call getFilesDetails with the updated list
      SetFilesFromDocument(updatedList);
  
      // Return the updated list to update the state
      return updatedList;
    });
  };

  console.log('selectedFileList', selectedFileList);

  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className='documentModal'
      backdropClassName="backdrop-custom"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {showFolder ? (
            <>
              <span className="material-symbols-rounded documentsFlolderBack" onClick={backToFiles}>
                arrow_back
              </span>
              <span>{folderDetails?.name}</span>
            </>
          ) : (
            'Add files'
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showFolder && (
          <Documents
            noAction={true}
            redirectToFolderView={redirectToFolderView}
            uploadDocumentList={uploadDocumentList}
            selectedFileList={selectedFileList}
          />
        )}
        {showFolder && (
          <DocumentsFolderDetails
            noAction={true}
            folderID={folderId}
            setFolder={setFolder}
            getFolderDetails={getFolderDetails}
            uploadDocumentList={uploadDocumentList}
            selectedFileList={selectedFileList}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
