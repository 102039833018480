import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SectionWrapper } from '../../atoms/SectionWrapper';
import addPeople from '../../images/add-people.svg';
import groupPeople from '../../images/group-people.svg';
import { imageTextWrapper } from '../../common/utils';

const mockData = [
  { name: 'Olivia Martinez', companyName: 'Relia Software', role: 'Sales' },
  { name: 'Sophia Johnson', companyName: 'Relia Software', role: 'HR' },
  { name: 'Ethan Robinson', companyName: 'Relia Software', role: 'Finance' },
  { name: 'Ava Thompson', companyName: 'Relia Software', role: 'Legal' },
];

export const CompanyContacts = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Container fluid className="section-container">
      <Row>
        <h2 className="company-section-title mb-3">Manage Contacts</h2>
      </Row>
      <SectionWrapper>
        <h2 className="company-section-title">Your Contacts</h2>
        <div className="company-section-subtitle mb-3">
          Basic Information: Company name, industry, size, location, website,
          and contact details.
        </div>
        <SectionWrapper>
          <div className="d-flex justify-content-between align-items-center company-section-top-content">
            <div className="d-flex justify-content-flex-start align-items-center company-group-info">
              <img src={groupPeople} alt="group" className="me-2" />
              <div>From you company({4})</div>
            </div>
            <div className="company-invite-btn">
              <img src={addPeople} alt="add" className="me-2" />
              <div>Invite Colleageus</div>
            </div>
          </div>
          <div className="company-contact-list">
            {mockData.map((p, idx) => {
              return (
                <div key={idx} className="company-contact-item">
                  {imageTextWrapper('')}
                  <div className="company-contact--name">{p.name}</div>
                  <div className="company-contact--info">{`${p.companyName} - ${p.role}`}</div>
                </div>
              );
            })}
          </div>
        </SectionWrapper>
      </SectionWrapper>
    </Container>
  );
};
