import {
  GET_TASK_LIST,
  ADD_TASK_COMMENT,
  GET_TASK_COMMENT,
  EDIT_TASK_COMMENT,
  ADD_TASK_FILE,
} from '../Constants/constants';

export const getallTaskAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: GET_TASK_LIST,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const addTaskCommentAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: ADD_TASK_COMMENT,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getTaskCommentAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: GET_TASK_COMMENT,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const editTaskCommentAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: EDIT_TASK_COMMENT,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const addTaskfileAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: ADD_TASK_FILE,
    payload: payload,
    successCallback,
    errorCallback,
  };
};
