/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import AddActionItemForm from '../Forms/AddActionItemForm';
import './styles.scss';

export const AddActionItems = props => {
  const { updateDealRoomDetails, taskDetails, handleClose, modalShow } = props;
  console.log('taskDetails', taskDetails);
  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className='action-item-modal'
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Action Item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='task-name-action'>{taskDetails?.name}</p>
        <AddActionItemForm
          updateDealRoomDetails={updateDealRoomDetails}
          taskDetails={taskDetails}
          // updateActionItems={updateActionItems}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};
