import React from 'react';
import MainHeader from '../../organisms/MainHeader';
import { LoginFooter } from '../../molecules/LoginFooter';
import logo from '../../images/logo.svg';
import { Col, Container, Row } from 'react-bootstrap';
import './style.scss';
import { FormResetPassword } from '../../organisms/Auth/Container/FormResetPassword';

export const FormResetPasswordComponent = props => {
  return (
    <>
      <MainHeader />
      <div
        className=" auth-body d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}>
        <div className="d-flex justify-content-center align-items-center authWrapper">
          <div className="auth-container">
            <div className="auth-header">
              <h1>Reset Password</h1>
              <p>
                Enter your email to receive instructions on how to reset your
                password.
              </p>
            </div>
            <FormResetPassword />
          </div>
        </div>
        <LoginFooter />
      </div>
    </>
  );
};
