// const GET_LOGIN = 'GET_LOGIN';
import {
  DEAL_LIST,
  CREATE_NEW_DEAL,
  EACH_DEAL,
  EACH_SECTION,
  ADD_SECTION,
  FILE_UPLOAD,
  TASK_COMPLETE,
  CREAT_TASK,
  UPDATE_DEAL,
  GET_PINNED,
  ADD_PINNED,
  INVITE_COLLABORATORS,
  EDIT_SECTION,
  UPDATE_TASK,
  DELETE_FILE,
  REMOVE_COLLABORATORS,
  GET_COLLABORATORS,
  GET_SINGLE_SECTION,
} from '../Constants/constants';

export const dealListAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: DEAL_LIST,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const newDealAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: CREATE_NEW_DEAL,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const eachDealDetails = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: EACH_DEAL,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const eachSection = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: EACH_SECTION,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const addSectionAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: ADD_SECTION,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const uploadFileAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: FILE_UPLOAD,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const taskCompleteAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: TASK_COMPLETE,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const createTaskAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: CREAT_TASK,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const updateDealAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: UPDATE_DEAL,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getPinnedDeals = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: GET_PINNED,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const addPinnedDeals = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: ADD_PINNED,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const inviteColloboratorsDealAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: INVITE_COLLABORATORS,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const editSection = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: EDIT_SECTION,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const updateTasks = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: UPDATE_TASK,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const deleteFileAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: DELETE_FILE,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const removeCollaboratorsAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: REMOVE_COLLABORATORS,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getCollaboratorsAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: GET_COLLABORATORS,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getSingleSectionAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: GET_SINGLE_SECTION,
    payload: payload,
    successCallback,
    errorCallback,
  };
};
