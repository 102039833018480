import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../images/logo.svg';
import './style.scss';

const JoinDealRedirect = () => {
  const { dynamicPart } = useParams();
  const [emailId, setEmail] = useState('');
  console.log('dynamicPart', dynamicPart);
  const screenHeight = window.innerHeight;
  const handlejointext = e => {
    console.log('email', emailId, e.target.value);
    // setEmail(e.tagrget.value);
  };

  const url = new URL(window.location.href);

  // Extract the value of 'deal_room_name'
  const dealRoomName = url.searchParams.get('deal_room_name');

  // Log or use the extracted value
  console.log(dealRoomName);

  //https://oppanda-be.onrender.com/en/deal/join_deal_link/eyJkZWFsX3Jvb21faWQiOiJkYjcyZTA2YS0xNGJhLTQ1MjktOTJhMi04M2YwNzNiN2QxZDUifQ.Zh3JfPdG4LaEGqfsCd0OK3pYdVY
  // Redirect to the login page and pass the dynamicPart as state
  // "temporary_password_detail": [
  //       {
  //           "email": "pavan3@aroundusapp.com",
  //           "temporary_password": "EJZsCKYS"
  //       },
  //       {
  //           "email": "pavan2@aroundusapp.com",
  //           "temporary_password": "CRJQJSJX"
  //       }
  //   ]
  return (
    <>
      <div className="notfound">
        <img src={logo} alt="logo" />
      </div>
      <div
        className=" auth-body d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}>
        <div className="d-flex justify-content-center align-items-center authWrapper">
          <div className="auth-container">
            <div className="auth-header">
              <h1>{dealRoomName}</h1>
              <p>Enter your email and the PIN to access the deal room.</p>
            </div>
            <LoginForm dealURl={dynamicPart} />
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinDealRedirect;
