import React from 'react';
import logo from '../../images/main-logo.svg';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

import './styles.scss';

const MainHeader = () => {
  return (
    <Navbar sticky="top" expand="lg" className='main-header'>
      <Container fluid>
        <Navbar.Brand href="#home">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {' '}
            <NavDropdown
              title={
                <>
                  <span className="material-symbols-rounded">language</span>
                  EN
                </>
              }
              id="settings-dropdown">
            </NavDropdown>
            <Nav.Link href="#contact">Login</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainHeader;
