/* eslint-disable react/prop-types */
import React from "react";
import { Col, Row } from "react-bootstrap";
export const CommentsFilters = (props) => {
  const {count} = props;
  return (
    <Row className="deal-comments-filters">
    <Col md={6}>
      <p className="comments">
        <span className="material-symbols-rounded">forum</span>
        {count?.length > 0 ? count?.length : 'No'} Comments
      </p>
    </Col>
    {/* <Col md={6} className="text-end">
      <div className="deal-sort">
        <span className="material-symbols-rounded">sort</span>
        <select
          className="form-select"
          aria-label="Default select example">
          <option selected>Newest First</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
    </Col> */}
  </Row>
  )
}