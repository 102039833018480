/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Tab, Accordion } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DocumentFileItem } from '../../atoms/DocumentFileItem';
import moment from 'moment';
import {
  deleteDocumentFileAction,
  getFileDetailAction,
  getFileListAction,
  updateDocumentFileAction,
  uploadDocumentFileAction,
} from '../../react-shared/src/Actions/fileManager';
import { FileVersionHeader } from './FileVersionHeader';
import { ToastMessage } from '../../atoms/Toast';
import { ModalConfirmAction } from '../../atoms/ModalConfirmAction';

export const FileDetail = ({
  handleClose,
  show,
  data,
  handleDeleteFile,
  refreshFolderData,
  folderId = null,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('0');
  const [fileDetailData, setFileDetailData] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    handleGetFileDetail(data);
  }, [data]);

  const handleGetFileDetail = fileData => {
    if (!fileData) return;
    dispatch(
      getFileDetailAction(
        { name: fileData?.name },
        response => {
          console.log('response', response);
          setFileDetailData(response);
        },
        error => {
          ToastMessage({
            error: true,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleOpenFile = () => {
    window.open(data?.file, '_blank');
  };

  const handleActiveVersion = id => {
    dispatch(
      updateDocumentFileAction(
        { default_version: true, id },
        response => {
          console.log('response', response);
          ToastMessage({
            success: true,
            message: 'Updated successfully',
          });
          handleGetFileDetail(data);
        },
        error => {
          ToastMessage({
            error: true,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleConfirmDelete = () => {
    setShowConfirm(false);
    handleDeleteFile();
  };

  const handleDeleteVersion = () => {
    setSelectedVersion(null);
    dispatch(
      deleteDocumentFileAction(
        { id: selectedVersion?.id },
        response => {
          console.log('response', response);
          ToastMessage({
            success: true,
            message: 'Deleted file successfully',
          });
          handleGetFileDetail(data);
          dispatch(getFileListAction());
          if (refreshFolderData) {
            refreshFolderData();
          }
          if (fileDetailData?.results?.length === 1) {
            handleClose();
          }
        },
        error => {
          ToastMessage({
            error: true,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleDownload = async () => {
    const fileUrl = data?.file;
    const fileName = data?.name;

    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadFile = event => {
    const files = [...event.target.files];
    if (!files) return;
    if (files?.length === 0) {
      ToastMessage({
        error: true,
        message: 'Please select files first!',
      });
      return;
    }
    if (files[0]?.name !== data?.name) {
      ToastMessage({
        error: true,
        message: 'File name do not match, please try again',
      });
      return;
    }
    const formData = new FormData();
    files?.forEach((file, idx) => {
      formData.append(`files[${idx}]`, file);
    });
    if (folderId) {
      formData.append('folder', folderId);
    }
    event.target.value = null;

    dispatch(
      uploadDocumentFileAction(
        formData,
        response => {
          console.log('response', response);
          ToastMessage({
            success: true,
            message: 'Upload file sucessfully',
          });
          dispatch(getFileListAction());
          handleGetFileDetail(data);
          if (refreshFolderData) {
            refreshFolderData();
          }
        },
        error => {
          ToastMessage({
            error: true,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleClickInput = () => {
    fileInputRef.current.click();
  };

  if (!data) return null;

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        className="modal-file-detail"
        size="md"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{data?.name || 'file'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleUploadFile}
          />
          <Tabs
            activeKey={activeTab}
            className="creactSectionTabs"
            fill
            onSelect={t => setActiveTab(t)}>
            <Tab eventKey="0" title="Info">
              <div className="file-tag-version">Version {data?.version}</div>
              <DocumentFileItem
                fileName={data?.name}
                fileSize={data?.size_in_mb}
                fileUrl={data?.file}
              />
              <div className="file-info-item border-top">
                <div className="file-info-item--title">File type</div>
                <div className="file-info-item--value">
                  {data?.mime_type || data?.type}
                </div>
              </div>
              <div className="file-info-item">
                <div className="file-info-item--title">Upload</div>
                <div className="file-info-item--value">
                  {moment(data?.creation_date).format('hh:mm A, DD MMMM YYYY')}
                </div>
              </div>
              <div className="file-info-item">
                <div className="file-info-item--title">Last updated</div>
                <div className="file-info-item--value">
                  {moment(data?.last_mod_date).format('hh:mm A, DD MMMM YYYY')}
                </div>
              </div>
              <div className="file-info-item">
                <div className="file-info-item--title">Owner</div>
                <div className="file-info-item--value">You</div>
              </div>
              <div
                className="file-info-action-btn mt-4"
                onClick={handleOpenFile}>
                Open File
              </div>
              <div className="file-info-action-btn" onClick={handleClickInput}>
                Upload New Version
              </div>
              <div className="file-info-action-btn" onClick={handleDownload}>
                Download File
              </div>
              <div
                className="file-info-action-btn red"
                onClick={() => setShowConfirm(true)}>
                Delete File
              </div>
            </Tab>
            <Tab eventKey="1" title="Versions">
              <div className="file-info-action-btn" onClick={handleClickInput}>
                Upload New Version
              </div>
              <Accordion defaultActiveKey={'0'}>
                {fileDetailData?.results?.map((file, idx) => {
                  return (
                    <div className="file-version-section" key={idx?.toString()}>
                      <FileVersionHeader
                        version={file?.version}
                        eventKey={idx?.toString()}
                        isLastest={idx === 0}
                      />
                      <Accordion.Collapse eventKey={idx?.toString()}>
                        <>
                          <DocumentFileItem
                            fileName={file?.name}
                            fileSize={`${file?.size_in_mb} MB`}
                            fileUrl={file?.file}
                          />
                          <div className="file-info-item border-top">
                            <div className="file-info-item--title">
                              File type
                            </div>
                            <div className="file-info-item--value">
                              {file?.mime_type || file?.type}
                            </div>
                          </div>
                          <div className="file-info-item">
                            <div className="file-info-item--title">Upload</div>
                            <div className="file-info-item--value">
                              {moment(file?.creation_date).format(
                                'hh:mm A, DD MMMM YYYY',
                              )}
                            </div>
                          </div>
                          <div className="file-info-item mb-4">
                            <div className="file-info-item--title">Owner</div>
                            <div className="file-info-item--value">You</div>
                          </div>
                          {file?.default_version ? (
                            <div className="file-info-action-btn blue disabled">
                              Active Version
                            </div>
                          ) : (
                            <div
                              className="file-info-action-btn"
                              onClick={() => handleActiveVersion(file?.id)}>
                              Make Active Version
                            </div>
                          )}
                          <div
                            className="file-info-action-btn red"
                            onClick={() => setSelectedVersion(file)}>
                            Remove Version
                          </div>
                        </>
                      </Accordion.Collapse>
                    </div>
                  );
                })}
              </Accordion>
            </Tab>
            <Tab eventKey="2" title="Analytics">
              <div className="text-normal">Usage</div>
              <div className="d-flex align-items-center justify-content-flex-start">
                <div className="text-title me-2">27</div>
                <div className="text-normal">times in deals</div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <ModalConfirmAction
        show={showConfirm}
        title="Are you sure?"
        btnText="Confirm"
        btnCancel="Cancel"
        handleClose={() => setShowConfirm(false)}
        handleCancel={() => setShowConfirm(false)}
        handleConfirm={handleConfirmDelete}
      />
      <ModalConfirmAction
        show={!!selectedVersion}
        title="Are you sure?"
        btnText="Confirm"
        btnCancel="Cancel"
        handleClose={() => setSelectedVersion(null)}
        handleCancel={() => setSelectedVersion(null)}
        handleConfirm={handleDeleteVersion}
      />
    </>
  );
};
