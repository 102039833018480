/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFormatedDate, imageTextWrapper } from '../../common/utils';
import './styles.scss';
import CustomProgressBar from '../../atoms/ProgressBar';
import { updateActionItemAction } from '../../react-shared/src/Actions/ActionItemAction';
// import { updateTasks, getSingleAction} from '../../react-shared/src/Actions/DealsActions';
import { ToastMessage } from '../../atoms/Toast';
import { areYouaOwner } from '../../common/utils';
import { AddTaskTemplate } from '../../organisms/DealRoomDetails/Templates/AddTaskTemplate';
import { Tooltip } from 'react-tooltip';
import { assign } from 'lodash';

const modifyTaskDetails = task => {
  return {
    id: task.id,
    name: task.name,
    due_date: task.due_date,
    status: task.status.code === 'C' ? true : false,
    assignee: null,
  };
};

export const ActionItemSection = props => {
  const {
    actionItem,
    updateActionItems,
    // checked = false,
    isItActionItem = false,
    // handleTaskStatus
  } = props;
  console.log('actionItem', actionItem?.id, actionItem);
  const [checked, setChecked] = useState(false);

  const [EditTask, setEditTask] = useState(false);

  const editTaskSection = () => {
    setEditTask(true);
  };
  const handleClose = () => {
    setEditTask(false);
  };

  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.loginReducer);

  useEffect(() => {
    actionItem?.status?.code === 'C' && setChecked(true);
  }, [actionItem]);

  // useEffect(() => {
  //   console.log('dispatch', actionItem)
  //   dispatch(getSingleAction({
  //     id: actionItem?.id,
  //   }, response => {
  //     console.log('respose', response);
  //   }))
  // }, [actionItem])

  const calculatePercantageCompleted = item => {

    if (item?.action_item_count) {
      const openItems = item?.task_action_item?.filter(
        subItem => subItem?.status?.code === 'O',
      );
      return (
        ((item?.action_item_count - openItems.length) /
          item?.action_item_count) *
        100
      );
    } else {
      return item?.status?.code === 'O' ? 0 : 100;
    }
  };

  const handleTaskStatus = (id, value, isItActionItem) => {
    setChecked(previous => !previous);
    if (isItActionItem) {
      dispatch(
        updateActionItemAction(
          {
            id: id,
            params: {
              status: value ? 'C' : 'O',
            },
          },
          response => {
            updateActionItems({
              taskstatusChangesResponse: response,
            });
            ToastMessage({
              success: true,
              message: 'Task status Changes',
            });
            console.log('response', response);
          },
          error => {
            ToastMessage({
              success: false,
              message: 'Something went wrong please try again',
            });
            console.log('error', error);
          },
        ),
      );
    } else {
      dispatch(
        updateTasks(
          {
            id: id,
            params: {
              status: value ? 'C' : 'O',
            },
          },
          response => {
            ToastMessage({
              success: true,
              message: 'Task status Changes',
            });
            updateActionItems({
              taskstatusChangesResponse: response,
            });
          },
          error => {
            ToastMessage({
              success: false,
              message: 'Something went wrong please try again',
            });
            console.log('error', error);
          },
        ),
      );
    }
  };

  const checkAllActionItemStatus = items => {
    const checkStatus = items?.task_action_item?.filter(
      item => item?.status?.code === 'O',
    );
    return checkStatus?.length > 0 ? true : false;
  };

  const isDisabled = checkAllActionItemStatus(actionItem);

  return (
    <>
    <Row>
      <Col md={isItActionItem? 8 : 10}>
      <div
        className="tasklist-container"
        data-tooltip-id={isDisabled ? `tooltip-${actionItem?.id}` : undefined}>
        <input
          className="styled-checkbox"
          id={`styled-checkbox-1-${actionItem?.id}`}
          type="checkbox"
          onChange={event =>
            handleTaskStatus(
              actionItem?.id,
              event.target.checked,
              isItActionItem,
            )
          }
          checked={checked}
          disabled={isDisabled}
          // data-tooltip-id={isDisabled ? `tooltip-${actionItem?.id}` : undefined}
        />
        <label htmlFor={`styled-checkbox-1-${actionItem.id}`}>
          {/* {imageTextWrapper(actionItem?.owner)} */}
          <span className={`taskname ${checked ? 'strike-text' : ''}`}>
            {actionItem?.name}
          </span>
        </label>
        {/* {actionItem?.due_date && (
          <span className="dueDate">{actionItem?.due_date}</span>
        )} */}
        {isDisabled && (
          <Tooltip
            id={`tooltip-${actionItem?.id}`}
            style={{ backgroundColor: '#0984E3', color: '#fff' }}
            content="You cannot change the status due to open tasks"
          />
        )}
      </div>
      </Col>
      {
        isItActionItem ? (
          <Col md={4}>
            <div className='actionitems-actions'>
          <div>
          {imageTextWrapper(actionItem?.owner)}
          </div>
          <div>
          {actionItem?.due_date && (
          <span className="dueDate">{actionItem?.due_date}</span>
        )} 
          </div>
          <div>

          </div>
          </div>
        </Col>
        ) :  (
        <Col md={2} className="update-task-wrapper">
            {areYouaOwner(userProfile, actionItem?.owner) && (
              <button type="button" onClick={editTaskSection}>
                Edit Task
              </button>
            )}
          </Col>
        )
      }
      
    </Row>
      {actionItem?.description && (
        <p className="actionItemDescription">{actionItem?.description}</p>
      )}
      {/* {!isItActionItem && (
        <CustomProgressBar
          progress={calculatePercantageCompleted(actionItem)}
        />
      )} */}
      {!isItActionItem && (
        <Row>
          <Col md={12} className="tasktimings">
            <ul className="list-inline">
              <li className="list-inline-item">
                <p>
                  <span>Created:</span>
                  {getFormatedDate(actionItem?.created_at)}
                </p>
              </li>
              <li className="list-inline-item">
                <p>
                  <span>Last Edited:</span>
                  {getFormatedDate(actionItem?.modified_at)}
                </p>
              </li>
            </ul>
            <p>
              <span>Appears in section:</span>
              {actionItem?.section_name}
            </p>
          </Col>
        </Row>
      )}
      <AddTaskTemplate
        updateDealRoomDetails={updateActionItems}
        dealRoomDetails={modifyTaskDetails(actionItem)}
        modalShow={EditTask}
        handleClose={handleClose}
        editMode={true}
        sectionid = {actionItem?.id}
      />
    </>
  );
};
