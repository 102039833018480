import {
  GET_DASHBOARD_ACTIVITY,
  GET_DASHBOARD_ACTIVITY_FAILURE,
  GET_DASHBOARD_ACTIVITY_SUCCESS,
} from '../Constants/constants';

const initialState = {
  loading: false,
  dashboardActivity: {},
};
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ACTIVITY:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardActivity: action?.data,
      };
    case GET_DASHBOARD_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
