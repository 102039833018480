/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import { EditNotesTemplate } from './EditNotesTemplate';

export const SectionNotes = props => {
  const {updateDealRoomDetails, dealRoomDetails} = props;
  const [show, setShow] = useState(false);
  const editNotesHandler = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className="notes-wrapper">
      <div className="notes-header">
        <div className="notes-heading">
          <span className="material-symbols-rounded">notes</span>
          <p>Notes</p>
        </div>
        <button type="button" onClick={editNotesHandler}>
          Edit Note
        </button>
      </div>
      <div className="notes">
        <p>{props?.notes}</p>
      </div>
      <EditNotesTemplate modalShow={show}
      handleClose ={handleClose}
      updateDealRoomDetails={updateDealRoomDetails}
      dealRoomDetails={dealRoomDetails}
      />
    </div>
  );
};
