/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import {
  imageTextWrapper,
  getFormatedDate,
  areYouaOwner,
} from '../../common/utils';
import { TaskTemplate } from './Templates/TaskTemplate';
import { SectionNotes } from './Templates/NotesTemplate';
import { FileSection } from './Templates/FileTemplates';
import { CommentSectionTemplate } from './Templates/CommentTemplate';
import { AddNewSection } from './AddNewSection';
import { eachSection } from '../../react-shared/src/Actions/DealsActions';
import { ManageSectionCollaborators } from './Templates/ManageSectionCollaborators';

export const DealRoomEachSection = props => {
  // console.log(props, 'props each section');
  const { redirectToOtherSection } = props;
  const [show, setShow] = useState(false);
  // const [complete, SetCompleted] = useState(false);
  const [updatedsection, setupdatedsection] = useState({});
  const [expandSection, setExapndSection] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const userProfile = useSelector(state => state.loginReducer);

  const dispatch = useDispatch();
  const updateSectionDetails = useCallback(params => {
    setupdatedsection &&
      setupdatedsection(prevState => ({ ...prevState, ...params }));
  }, []);
  const getParticipantsList = data => {
    return data?.map(item => {
      return { value: item?.id, label: item?.email };
    });
  };
  useEffect(() => {
    updateSectionDetails({
      ...props,
      participantsList: getParticipantsList(props?.participants),
    });
  }, [updateSectionDetails, props]);

  const exapndAndCollapse = () => {
    setExapndSection(!expandSection);
  };

  const editSectionHandler = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  console.log('propsDealRoom', props);
  return (
    <>
      <div className="dealroomsection-conatiner" id={props?.id}>
        <Row className="dealroosection-header">
          <Col md={8}>
            <div className="dealroosection-name">
              <h3>
                <span className="material-symbols-rounded drag">
                  drag_indicator
                </span>
                {props?.name}
              </h3>
            </div>
          </Col>
          <Col md={4}>
            <div className="dealroom-header-button">
              <button onClick={exapndAndCollapse}>
                {expandSection ? (
                  <>
                    <span className="material-symbols-rounded">
                      unfold_less
                    </span>
                    Collapse
                  </>
                ) : (
                  <>
                    <span className="material-symbols-rounded">
                      unfold_more
                    </span>
                    Exapnd
                  </>
                )}
              </button>
              {areYouaOwner(userProfile, props?.owner) && (
                <button onClick={editSectionHandler}>
                  <span className="material-symbols-rounded">edit_note</span>
                  Update
                </button>
              )}
            </div>
          </Col>
          <Col md={12} className="dealroom-time-wrapper">
            <p className="dealroom-time">
              <span>Created:</span>
              {getFormatedDate(props?.created_at)}
            </p>
          </Col>
          {!expandSection && (
            <Col md={12}>
              <div className="dealRoom-details-wrappper">
                <div className="dealroom-members">
                  <span className="material-symbols-rounded">groups</span>
                  <ul>
                    {props?.participants?.map((item, index) => {
                      return <li key={index}>{imageTextWrapper(item)}</li>;
                    })}
                  </ul>
                </div>
                {props?.section_task?.length > 0 && (
                  <div className="deal-details">
                    <span className="material-symbols-rounded">task_alt</span>
                    {props?.section_task?.length} tasks
                  </div>
                )}
                <div className="deal-details">
                  <span className="material-symbols-rounded">folder</span>
                  {props?.section_file?.length} files
                </div>
                <div className="deal-details">
                  <span className="material-symbols-rounded">forum</span>
                  {props?.section_comment?.length > 0
                    ? `${props?.section_comment?.length} comments`
                    : 'No Comments'}
                </div>
              </div>
            </Col>
          )}
        </Row>
        <Row
          className={`Sectionontent ${expandSection ? 'expanded' : 'collapsed'}`}>
          <Col md={12} className="dealroom-section">
            <div>
              {/* Manage section particiapanet */}
              {
                <ManageSectionCollaborators
                  participants={props?.participants}
                  dealRoomDetails={updatedsection}
                  updateDealRoomDetails={props?.updateDealRoomDetails}
                />
              }
              {/* Notes */}
              {
                <SectionNotes
                  notes={props?.notes}
                  dealRoomDetails={updatedsection}
                  updateDealRoomDetails={props?.updateDealRoomDetails}
                />
              }
              {/* Tasks */}
              {
                <TaskTemplate
                  dealRoomDetails={updatedsection}
                  updateDealRoomDetails={props?.updateDealRoomDetails}
                  redirectToOtherSection={redirectToOtherSection}
                  membersList={updatedsection?.participantsList}
                />
              }
              {/* Documents */}
              {
                <FileSection
                  filesDate={props?.section_file}
                  dealRoomDetails={updatedsection}
                  updateDealRoomDetails={props?.updateDealRoomDetails}
                  membersList={updatedsection?.participantsList}
                />
              }
              <CommentSectionTemplate commentsList={props} />
            </div>
          </Col>
          {/* <Col md={12}>
            
          </Col> */}
        </Row>
      </div>
      <AddNewSection
        dealRoomDetails={updatedsection}
        updateDealRoomDetails={props?.updateDealRoomDetails}
        idFromPanel={props?.id}
        editMode={true}
        openModal={show}
        handleClose={handleClose}
      />
    </>
  );
};
