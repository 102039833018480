// import { PayloadAction } from "@reduxjs/toolkit";
// import { takeEvery, call, put, fork } from 'redux-saga/effects';

import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';
import { APPURL } from '../config';
import {
  // GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  JOIN_DEAL_ROOM,
  JOIN_DEAL_ROOM_SUCCESS,
  JOIN_DEAL_ROOM_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from '../Constants/constants';
// const Rurl = 'https://mmerge.onrender.com/en/login/'
const GET_LOGIN = 'GET_LOGIN';
export function* getUserSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request, payload);
  try {
    const result = yield call(axios.post, `${APPURL}login/`, payload?.params, payload?.header && {
      headers: {
        "X-Bypass-Extusr-Logval" : true,
 
      }
    });
    console.log('result', result);
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: GET_LOGIN_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: GET_LOGIN_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: GET_LOGIN_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchGetUser() {
  yield takeLatest('GET_LOGIN', getUserSaga);
}

export function* joinDealSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request, payload);
  try {
    const result = yield call(
      axios.post,
      `${APPURL}deal/join_deal_link/${payload?.url}/?email=${payload?.email}`,
    );
    console.log('result', result);
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: JOIN_DEAL_ROOM_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: JOIN_DEAL_ROOM_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: GET_LOGIN_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchjoinDealSaga() {
  yield takeLatest(JOIN_DEAL_ROOM, joinDealSaga);
}

export function* resetPasswordSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request, payload);
  try {
    const result = yield call(
      axios.post,
      `${process.env.REACT_APP_PASS_WORD_RESET_URL}/password/reset/`,
      payload?.data,
    );
    console.log('result', result);
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: RESET_PASSWORD_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: RESET_PASSWORD_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchResetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}
