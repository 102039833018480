/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import Select, { components } from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import { LeftPanelMainOptions } from '../../organisms/MainLeftPannel';
import {
  eachDealDetails,
  eachSection,
} from '../../react-shared/src/Actions/DealsActions';
import { DealRoomRightPanel } from '../../organisms/DealRoomDetails/DealRoomRightPanel';
import { useDispatch, useSelector } from 'react-redux';
import NeDealDetails from '../../images/nodealDetails.svg';
import { NavBar } from '../../organisms/NavBar';
import { Row, Col, Tabs, Tab, Form, Dropdown } from 'react-bootstrap';
import { DealRoomEachSection } from '../../organisms/DealRoomDetails/DealRoomSection';
import { NoDealHeader } from '../../organisms/NoDealPanel';
import { AddNewSection } from '../../organisms/DealRoomDetails/AddNewSection';
import { areYouaOwner } from '../../common/utils';
import { ActionItems } from '../ActionItems';
export const noDeal = [
  {
    text: 'This is your deal room',
    details: `Start by adding a section to add details before sharing the deal room with your client.`,
    image: NeDealDetails,
  },
];

const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

const CustomOption = props => {
  const { data, isSelected, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isSelected ? '#d1e7dd' : 'white', // Change selected background color
        padding: '10px',
        cursor: 'pointer',
      }}>
      <input
        type="radio"
        name="custom-radio"
        checked={isSelected}
        onChange={() => null}
        style={{ marginRight: '10px' }}
      />
      <label>{data.label}</label>
    </div>
  );
};

export const getDropDownSectionList = list => {
  console.log('listedsdddddd', list);
  return list?.map(item => {
    console.log('item', item);
    const { id, name, ...rest } = item;
    return {
      value: id,
      label: name,
    };
  });
  // console.log('sectionArray', sectionArray);
};

export const DealRoomDetails = props => {
  const [dealRoomDetails, setDealRoomDetails] = useState({});
  const [idFromPanel, setIdFromPanel] = useState('');
  const [text, setText] = useState('');
  const [owner, setOwner] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tabActive, setTabActive] = useState('showDealRoom');
  const [dropDownOption, setDropDownOption] = useState([]);
  const [redirectSection, setRedirectSection] = useState('');
  const [key, setKey] = useState('tab1');
  const loggedInUser = useSelector(state => state.loginReducer);
  const dealListReducer = useSelector(state => state.dealsReducers);
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state || {};

  // setIdFromPanel(id);
  const updateDealRoomDetails = useCallback(params => {
    setDealRoomDetails &&
      setDealRoomDetails(prevState => ({ ...prevState, ...params }));
  }, []);
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const Pathid = pathname.split('/')[2];
  useEffect(() => {
    location.state && setIdFromPanel(id);
  }, [location.state]);

  useEffect(() => {
    setIdFromPanel(Pathid);
  }, [location?.pathname.href]);

  // const getParticipantsList = (data) => {
  //   console.log('data getParticipantsList', data);
  //   return data?.map(item => {
  //     return {value : item?.id, label: item?.email}
  //   })
  // }
  useEffect(() => {
    dispatch(
      eachSection(
        {
          id: idFromPanel,
        },
        response => {
          console.log('response', response);
          updateDealRoomDetails({
            dealRoomSection: response,
            getOptionsList: response?.results,
          });
        },
      ),
    );
  }, [
    idFromPanel,
    tabActive,
    dealRoomDetails?.sectionCreateResponse,
    dealRoomDetails?.sectionCreateTaskResponse,
    dealRoomDetails?.sectionFileResponse,
    dealRoomDetails?.pinnned,
    dealRoomDetails?.collaboratorsResponse,
    dealRoomDetails?.updatedTaskResponse,
    dealRoomDetails?.sectionTaskMarked,
    dealRoomDetails?.taskAddedModalSucessfully,
    dealRoomDetails?.addedFileSuccess,
    dealRoomDetails?.addedActionItemSuccess,
    dealRoomDetails?.sectionupdatedResponse,
    dealRoomDetails?.manageCollaboratorsResponse,
  ]);

  useEffect(() => {
    setDropDownOption(getDropDownSectionList(dealRoomDetails?.getOptionsList));
  }, [dealRoomDetails?.getOptionsList]);

  useEffect(() => {
    // const idFromUrl = getIdFromUrl();
    // console.log('Extracted pathId:', idFromUrl); //
    if (idFromPanel) {
      dispatch(
        eachDealDetails(
          { id: idFromPanel, from: 'dealroom right' },
          response => {
            console.log('response', response);
            updateDealRoomDetails({
              DealMainDetails: response,
            });
          },
          error => {
            console.log('Error fetching deal details:', error);
          },
        ),
      );
    }
  }, [idFromPanel]);

  const selectDealDetails = ids => {
    setIdFromPanel(ids);
    navigate(`/deal/${ids}`, { state: ids });
  };

  const addSection = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  // const getDropDownSectionList = (list) => {
  //   console.log('getDropDownSectionList', list);
  // }
  useEffect(() => {
    setOwner(
      areYouaOwner(loggedInUser, dealRoomDetails?.DealMainDetails?.owner),
    );
    // setDropDownOption(getDropDownSectionList(dealRoomDetails?.dealRoomSection?.results))
  }, [dealRoomDetails?.DealMainDetails]);

  const setTabsHandler = id => {
    setTabActive(id);
  };

  const redirectToOtherSection = id => {
    setTabActive('showActionPlan');
    setRedirectSection(id);
  };
  const [selectedOption, setSelectedOption] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSelect = (valueSelected, label) => {
    setSelectedOption(label);
    setDropdownOpen(false);
    window.history.pushState(null, '', `#${valueSelected}`);
    setTimeout(() => {
      const targetElement = document.getElementById(valueSelected);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      } else {
        console.warn(`Element with ID ${event?.target?.value} not found`);
      }
    }, 100);
  };

  return (
    <Row className="component-Wrapper">
      {owner && (
        <Col md={2} className="leftpanel-fixed">
          <LeftPanelMainOptions
            {...dealListReducer?.dealList}
            onOptionClick={selectDealDetails}
          />
        </Col>
      )}
      <Col md={owner ? 10 : 12} style={{ paddingRight: 0 }}>
        <NavBar text={text} backarrowShow={'Show'} owner={owner} />
        <div className="deal-details-tabs sticky-top stick-header">
          <ul>
            {tabActive !== 'showDealRoom' && (
              <li
                className={`${tabActive === 'showDealRoom' && 'active'}`}
                onClick={() => setTabsHandler('showDealRoom')}
                id="dealRoom">
                Deal Room
              </li>
            )}
            {tabActive === 'showDealRoom' && (
              <li>
                <Dropdown
                  show={dropdownOpen}
                  onToggle={isOpen => setDropdownOpen(isOpen)}
                  className="section-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    {selectedOption ? `Sections-${selectedOption}` : 'Select an section'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="dropdown-header">Jump to section</div>
                    {dropDownOption?.map((option, index) => (
                      <Form.Check
                        type="radio"
                        key={index}
                        id={`radio-${index}`}
                        name="custom-radio"
                        label={option.label}
                        value={option.value}
                        checked={selectedOption === option?.label}
                        onChange={() =>
                          handleSelect(option?.value, option?.label)
                        }
                        className="dropdown-option"
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}
            <li
              className={`${tabActive === 'showActionPlan' && 'active'}`}
              onClick={() => setTabsHandler('showActionPlan')}>
              Action Plan{' '}
            </li>
            {/* {owner && <li>Analytics</li>}
            {owner && <li>Insights</li>} */}
          </ul>
        </div>

        {/* <DealRoomHeader/> */}

        <Row>
          {tabActive === 'showDealRoom' && (
            <Col md={{ span: 8, offset: owner ? 0 : 2 }}>
              {owner && (
                <div className="newsection-wrapper" onClick={addSection}>
                  <h3>
                    <span className="material-symbols-rounded">add</span>New
                    Section
                  </h3>
                </div>
              )}
              {owner && (
                <AddNewSection
                  dealRoomDetails={dealRoomDetails}
                  updateDealRoomDetails={updateDealRoomDetails}
                  idFromPanel={idFromPanel}
                  openModal={openModal}
                  handleClose={handleClose}
                />
              )}
              {dealRoomDetails?.dealRoomSection?.results?.length ? (
                dealRoomDetails?.dealRoomSection?.results?.map(
                  (item, index) => {
                    return (
                      <DealRoomEachSection
                        key={item?.id}
                        {...item}
                        updateDealRoomDetails={updateDealRoomDetails}
                        redirectToOtherSection={redirectToOtherSection}
                      />
                    );
                  },
                )
              ) : (
                <NoDealHeader {...noDeal} />
              )}
            </Col>
          )}
          {tabActive === 'showActionPlan' && (
            <Col md={{ span: 8, offset: owner ? 0 : 2 }}>
              {/* <h1>Action plan</h1> */}
              <ActionItems
                idFromPanel={idFromPanel}
                redirectSection={redirectSection}
              />
            </Col>
          )}
          {owner && (
            <Col md={4}>
              <DealRoomRightPanel
                dealRoomDetails={dealRoomDetails}
                updateDealRoomDetails={updateDealRoomDetails}
                setText={setText}
                sectionCount={dealRoomDetails?.dealRoomSection?.count}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
