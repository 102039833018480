import React, { useEffect, useCallback, useState } from 'react';
import { LeftPanelMainOptions } from '../../organisms/MainLeftPannel';
import { NoDealHeader } from '../../organisms/NoDealPanel';
import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { rules } from 'eslint-plugin-prettier';
import { SectionTabs } from '../../atoms/SectionTabs';
import { NavBar } from '../../organisms/NavBar';
import { DashboardActivity } from '../../organisms/Dashboard/DashboardActivity';
import './style.scss';

const forms = [
  {
    type: 'input',
    labelRequired: true,
    label: 'Deal Name',
    name: 'dealName',
    rules: {
      required: true,
      message: 'Deal Name is required',
    },
    onChange: e => console.log(e.target.value),
  },
  {
    type: 'textarea',
    labelRequired: true,
    label: 'Deal Description',
    name: 'dealDescription',
    rules: {
      required: true,
      message: 'Deal Description required',
    },
    // onChange: (e) => console.log(e.target.value),
  },
];
const addEventHandlers = form => {
  form.onHover = () => console.log(`Hovered over ${form.label}`);
  form.onSubmit = () => console.log(`Submitted ${form.label}`);
  return form;
};

const forms2 = [
  {
    type: 'input',
    labelRequired: true,
    label: 'Deal Name',
    name: 'dealName',
    placeholder: 'Enter deal name',
    defaultValue: 'Default Deal Name',
    rules: {
      required: {
        value: true,
        message: 'Deal Name is required',
      },
    },
    onChange: e => console.log(e.target.value),
  },
  {
    type: 'textarea',
    labelRequired: true,
    label: 'Deal Description',
    name: 'dealDescription',
    placeholder: 'Enter deal description',
    defaultValue: 'Default Deal Description',
    rules: {
      required: {
        value: true,
        message: 'Deal Description is required',
      },
    },
  },
  {
    type: 'password',
    labelRequired: true,
    label: 'Password',
    name: 'password',
    placeholder: 'Enter password',
    defaultValue: 'defaultpassword',
    rules: {
      required: {
        value: true,
        message: 'Password is required',
      },
    },
  },
].map(addEventHandlers);

// console.log(forms2);

const tabs = [
  { label: 'Sales Performance Metrics', content: <NoDealHeader /> },
  { label: 'Activity Overview', content: <DashboardActivity /> },
  { label: 'Top Opportunities', content: <NoDealHeader /> },
  { label: 'Sales Funnel', content: <NoDealHeader /> },
  { label: 'Client Insights', content: <NoDealHeader /> },
  { label: 'Team Performance', content: <NoDealHeader /> },
];

export const DashBoard = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = data => {
    console.log(data);
  };
  // const onSubmit = (data) => console.log(data)

  return (
    <Row className="component-Wrapper">
      <Col md={2}>
        <LeftPanelMainOptions />
      </Col>
      <Col md={10}>
        <NavBar text="Dashboard" />
        <SectionTabs data={tabs} />
        {/* <form onSubmit={handleSubmit(onSubmit)}>
      {forms.map((field, index) => (
        <div key={index}>
          {field.labelRequired && <label>{field.label}</label>}
          {field.type === 'input' && (
            <input 
              {...register(field.name, field.rules)} 
              onChange={field.onChange}
              placeholder={field.placeholder || field.name }

            />
          )}
          {field.type === 'textarea' && (
            <textarea {...register(field.name, field.rules)}/>
          )}
          {errors[field.name] && <p>{errors[field.name].message}</p>}
        </div>
      ))}
      <button type="submit">Submit</button>
    </form> */}
      </Col>
    </Row>
  );
};
