/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'react-bootstrap';

const defaultTasks = [
  {
    id: 1,
    status: false,
    name: '',
    // assignee: '',
    due_date: new Date(),
  },
];

const TaskForm = forwardRef((props, ref) => {
  const { getTaskValues, newSectionDetails} = props;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasks: newSectionDetails?.tasks?.length > 0 ? newSectionDetails?.tasks : defaultTasks,
    },
  });

  useImperativeHandle(ref, () => ({
    trigger,
  }));

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });

  const onSubmit = data => {
    console.log(data);
  };

  const handleFieldChange = () => {
    const tasks = getValues('tasks');
    getTaskValues(tasks);
  };

  const validateTaskFields = index => {
    return value => {
      const tasks = getValues('tasks');
      const task = tasks[index];
      // const otherFields = ['name', 'assignee', 'due_date', 'status'];
      const otherFields = ['name', 'status'];
      const filledFields = otherFields.filter(field => task[field]);
      if (filledFields.length > 0 && !value) {
        return 'This field is required';
      }

      return true;
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => (
        <Row key={item.id} className="addTaskContainer">
          <Col md={1}>
            <div className="checkbox-div">
              <input
                type="checkbox"
                className="styled-checkbox"
                id={`status-${index}`}
                {...register(`tasks.${index}.status`)}
                defaultChecked={item.status}
                onChange={e => {
                  setValue(`tasks.${index}.status`, e.target.checked);
                  handleFieldChange();
                }}
              />
              <label htmlFor={`status-${index}`}></label>
            </div>
          </Col>
          <Col md={6}>
            <label htmlFor={`tasks.${index}.name`}>Task Name</label>
            <input
              {...register(`tasks.${index}.name`, {
                validate: validateTaskFields(index),
              })}
              placeholder="Name"
              defaultValue={item.name}
              onChange={handleFieldChange}
            />
            {errors.tasks?.[index]?.name && (
              <p className="error">{errors.tasks[index].name.message}</p>
            )}
          </Col>

          {/* <Col md={2}>
            <label htmlFor={`tasks.${index}.assignee`}>Assignee</label>
            <input
              {...register(`tasks.${index}.assignee`, {
                validate: validateTaskFields(index),
              })}
              placeholder="Assignee"
              defaultValue={item.assignee}
              onChange={handleFieldChange}
            />
            {errors.tasks?.[index]?.assignee && (
              <p className="error">{errors.tasks[index].assignee.message}</p>
            )}
          </Col> */}
          <Col md={4}>
            <label htmlFor={`tasks.${index}.due_date`}>Due Date</label>
            <Controller
              control={control}
              name={`tasks.${index}.due_date`}
              defaultValue={item.due_date}
              placeholder={'Due Date'}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={date => {
                    setValue(`tasks.${index}.due_date`, date);
                    handleFieldChange();
                  }}
                />
              )}
              rules={{
                validate: validateTaskFields(index),
              }}
            />
            {errors.tasks?.[index]?.due_date && (
              <p className="error">{errors.tasks[index].due_date.message}</p>
            )}
          </Col>
          <Col md={1}>
            <button className="deletebutton" onClick={() => remove(index)}>
              <span className="material-symbols-rounded">delete</span>
            </button>
          </Col>
        </Row>
      ))}
      <button
        type="button"
        className="addTasks"
        onClick={() => {
          append({
            id: Date.now(),
            status: false,
            name: '',
            // assignee: '',
            due_date: new Date(),
          });
          handleFieldChange();
        }}>
        <span className="material-symbols-rounded">playlist_add</span> Add
        Fields
      </button>
    </form>
  );
});

export default TaskForm;
