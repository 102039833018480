import {
  GET_ACTION_ITEM_LIST,
  GET_ACTION_ITEM_LIST_SUCCESS,
  GET_ACTION_ITEM_LIST_FAILURE,
  ADD_ACTION_ITEM,
  ADD_ACTION_ITEM_SUCCESS,
  ADD_ACTION_ITEM_FAILURE,
  UPDATE_ACTION_ITEMS,
  UPDATE_ACTION_ITEMS_SUCCESS,
  UPDATE_ACTION_ITEMS_FAILURE,
} from '../Constants/constants';

const initialState = {
  loading: false,
  actionItemList: {},
  addActionItem: {},
  updateActionItems: {},
};

const actionItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTION_ITEM_LIST:
      // case JOIN_DEAL_ROOM:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case GET_ACTION_ITEM_LIST_SUCCESS:
      // case JOIN_DEAL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        actionItemList: action?.data,
        // token: action?.data?.access,
      };
    case GET_ACTION_ITEM_LIST_FAILURE:
      // case JOIN_DEAL_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        actionItemList: action?.data,
      };

    case ADD_ACTION_ITEM:
      // case JOIN_DEAL_ROOM:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case ADD_ACTION_ITEM_SUCCESS:
      // case JOIN_DEAL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        addActionItem: action?.data,
        // token: action?.data?.access,
      };
    case ADD_ACTION_ITEM_FAILURE:
      // case JOIN_DEAL_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        addActionItem: action?.data,
      };

    case UPDATE_ACTION_ITEMS:
      // case JOIN_DEAL_ROOM:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case UPDATE_ACTION_ITEMS_SUCCESS:
      // case JOIN_DEAL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        updateActionItems: action?.data,
        // token: action?.data?.access,
      };
    case UPDATE_ACTION_ITEMS_FAILURE:
      // case JOIN_DEAL_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        updateActionItems: action?.data,
      };

    default:
      return state;
  }
};

export default actionItemReducer;
