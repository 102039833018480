import {
  ADD_COMMNENT,
  ADD_COMMNENT_SUCCESS,
  ADD_COMMNENT_FAILURE,
  GET_COMMNENTS,
  GET_COMMNENTS_SUCCESS,
  GET_COMMNENTS_FAILURE,
  EDIT_COMMNENTS,
  EDIT_COMMNENTS_SUCCESS,
  EDIT_COMMNENTS_FAILURE,
} from '../Constants/constants';

const initialState = {
  loading: false,
  addComment: {},
  getComment: {},
  editComment:{},
};
const commentReducer = (state = initialState, action) => {
  switch (action.type) { 
    case ADD_COMMNENT:
    case GET_COMMNENTS:
      return {
        ...state,
        loading: true,
      };
    case ADD_COMMNENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addComment: action?.data,
      };
    case ADD_COMMNENT_FAILURE:
      return {
        ...state,
        loading: false,
        addComment: action?.data,
      };
      case GET_COMMNENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getComment: action?.data,
      };
    case GET_COMMNENTS_FAILURE:
      return {
        ...state,
        loading: false,
        getComment: action?.data,
      };
    default:
      return state;
  }
}
export default commentReducer;