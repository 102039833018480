
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK_TITLE = "UPDATE_TASK_TITLE";
export const UPDATE_TASK_DESCRIPTION = "UPDATE_TASK_DESCRIPTION";
const initialState = {
    tasks: [],
    taskTitle: "jai",
    taskDescription: "jai"
  };
  
  const taskReducer2 = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_TASK_TITLE:
        return {
          ...state,
          tasks: [...state.tasks, action.payload],
          taskTitle: "jai",
          taskDescription: "jai"
        };
      default:
        return state;
    }
  };

  export default taskReducer2;