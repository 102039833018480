/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../../atoms/Button';
import { Form } from '../../atoms/Form';
import {sectionConfig} from './DealRoomUtils';


export const SectionForm = props => {
  const {
    getSectionDetails,
    formRef,
    dealRoomDetails,
    editMode
  } = props;
  
  const sectionComponents = sectionConfig({
    OnChange: getSectionDetails,
    defaultValues: {
      name: editMode && dealRoomDetails?.name,
      notes: editMode&& dealRoomDetails?.notes,
    },
  });

  return (
    <>
      <Form
        sectionComponents
        ref={formRef}
        requestedFields={sectionComponents}
      />
    </>
  );
};
