/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AddTaskForm from '../Forms/AddTaskForm';
import { getSingleSectionAction} from '../../../react-shared/src/Actions/DealsActions';
import './styles.scss';

export const AddTaskTemplate = props => {

  const {
    updateDealRoomDetails,
    dealRoomDetails,
    modalShow,
    handleClose,
    editMode = false,
    membersList,
    sectionid
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    modalShow && dispatch(getSingleSectionAction({
      id:sectionid,
    }, response => {
      console.log('response', response);
    }, error => {
      console.log('error', error);
    }))
  },[modalShow, editMode])

  console.log('dealRoomDetails', dealRoomDetails);
  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-custom-addnewTask"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{editMode ? `Edit ${dealRoomDetails?.name}` : 'Add Tasks'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddTaskForm
          updateDealRoomDetails={updateDealRoomDetails}
          dealRoomDetails={dealRoomDetails}
          handleClose={handleClose}
          editMode={editMode}
          membersList={membersList}
          sectionid={sectionid}
        />
      </Modal.Body>
    </Modal>
  );
};
