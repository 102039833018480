// import { PayloadAction } from "@reduxjs/toolkit";
// import { takeEvery, call, put, fork } from 'redux-saga/effects';

import axios from "axios";
import { put, takeLatest, call } from "redux-saga/effects";
import { APPURL, apiConfig } from '../config';
import {
  GET_TASK_LIST,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_LIST_FAILURE,
  ADD_TASK_COMMENT,
  ADD_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_FAILURE,
  GET_TASK_COMMENT,
  GET_TASK_COMMENT_SUCCESS,
  GET_TASK_COMMENT_FAILURE,
  EDIT_TASK_COMMENT,
  EDIT_TASK_COMMENT_SUCCESS,
  EDIT_TASK_COMMENT_FAILURE,
  ADD_TASK_FILE,
  ADD_TASK_FILE_SUCCESS,
  ADD_TASK_FILE_FAILURE,
} from "../Constants/constants";
// const Rurl = 'https://mmerge.onrender.com/en/login/'
// const GET_LOGIN = 'GET_LOGIN';
export function* getAllTaskSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/task/?deal_room=${payload?.deal_room}`,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: GET_TASK_LIST_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: GET_TASK_LIST_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: GET_TASK_LIST_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchgetAllTaskSaga() {
  yield takeLatest(GET_TASK_LIST, getAllTaskSaga);
}

export function* getTaskCommentsSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/task_comment/?task=${payload?.taskID}`,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: GET_TASK_COMMENT_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: GET_TASK_COMMENT_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: GET_TASK_COMMENT_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchgetTaskCommentsSaga() {
  yield takeLatest(GET_TASK_COMMENT, getTaskCommentsSaga);
}

export function* addTaskCommentsSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.post,
      `${APPURL}deal/task_comment/`,
      payload,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 201) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: ADD_TASK_COMMENT_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: ADD_TASK_COMMENT_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: ADD_TASK_COMMENT_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchaddTaskCommentsSaga() {
  yield takeLatest(ADD_TASK_COMMENT, addTaskCommentsSaga);
}

export function* editTaskCommentsSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/task_comment/${payload?.id}/`,
      payload?.params,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: EDIT_TASK_COMMENT_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: ADD_TASK_COMMENT_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: EDIT_TASK_COMMENT_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watcheditTaskCommentsSaga() {
  yield takeLatest(EDIT_TASK_COMMENT, editTaskCommentsSaga);
}



export function* addTaskFileSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.post,
      `${APPURL}task_file/`,
      payload?.formData,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 201) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: ADD_TASK_FILE_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: ADD_TASK_FILE_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: ADD_TASK_FILE_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

export function* watchaddTaskFileSaga() {
  yield takeLatest(ADD_TASK_FILE, addTaskFileSaga);
}
