import {
  ADD_COMMNENT,
  GET_COMMNENTS,
  EDIT_COMMNENTS,
  DELETE_COMMNENTS,
} from '../Constants/constants';

export const addCommentAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: ADD_COMMNENT,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getCommentsAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: GET_COMMNENTS,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const editCommentAction = (
  payload,
  successCallback = {},
  errorCallback = {},

) => {
  return {
    type: EDIT_COMMNENTS,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const deleteCommentAction = (
  payload,
  successCallback = {},
  errorCallback = {},

) => {
  return {
    type: DELETE_COMMNENTS,
    payload: payload,
    successCallback,
    errorCallback,
  };
}