import { GET_DASHBOARD_ACTIVITY } from '../Constants/constants';

export const getDashboardActivityAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: GET_DASHBOARD_ACTIVITY,
    payload: payload,
    successCallback,
    errorCallback,
  };
};
