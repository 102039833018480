/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { imageTextWrapper } from '../../common/utils';

const getArrayWithoutOwner = (arrayList, owner) =>
  arrayList?.filter(item => item?.id !== owner?.id);

const InviteSection = props => {
  const { handleCollaborators, newSectionDetails, editMode = false } = props;
  const [userSelected, setSelectedList] = useState([]);
  const [selectedInviteType, setSelectedInviteType] = useState('custom');
  const [inviteList, setInviteList] = useState([]);
  const [getOwner, setOwner] = useState('');
  const GetValuesfromReducer = useSelector(
    state => state.dealsReducers?.eachDealDeatils,
  );

  useEffect(() => {
    setInviteList(
      getArrayWithoutOwner(
        GetValuesfromReducer?.participants,
        GetValuesfromReducer?.owner,
      ),
    );
    editMode &&
      setSelectedList(
        getArrayWithoutOwner(
          newSectionDetails?.participants,
          GetValuesfromReducer?.owner,
        ),
      );
    setOwner(GetValuesfromReducer?.owner);
  }, []);

  useEffect(() => {
    if (selectedInviteType === 'all') {
      setSelectedList(inviteList || []);
    } else if (!editMode && selectedInviteType === 'custom') {
      setSelectedList([]);
    }
  }, [selectedInviteType, inviteList]);

  const handleCheckboxChange = (user, isChecked) => {
    setSelectedList(prevState => {
      if (isChecked) {
        // Add user if not already in the list
        if (!prevState?.some(item => item.id === user.id)) {
          return [...prevState, user];
        }
      } else {
        // Remove user
        return prevState.filter(item => item.id !== user.id);
      }
    });
  };

  useEffect(() => {
    handleCollaborators(userSelected);
  }, [userSelected]);

  const handleRadioChange = event => {
    const value = event.target.value;
    setSelectedInviteType(value);
  };

  return (
    <>
      <Row className="inviteSectionCollaborators">
        <Col md={6}>
          <p className="share">Share with</p>
        </Col>
        <Col md={6}>
          <div className="modal-manage-collaborators--access-deal-radio">
            <Form.Check
              type="radio"
              id="invite-type1"
              label="Everyone in the deal room"
              name="invite-type"
              value="all"
              checked={selectedInviteType === 'all'}
              onChange={handleRadioChange}
            />
            <Form.Check
              type="radio"
              id="invite-type2"
              label="Custom"
              name="invite-type"
              value="custom"
              checked={selectedInviteType === 'custom'}
              onChange={handleRadioChange}
            />
          </div>
        </Col>
        <p className="sharesubheading">
          To add more people, invite them to the Deal Room first.
        </p>
      </Row>
      <Row className="colbaration-wrapper">
        <Col md={12}>
          <div className="col-contact-list">
            <p className="collbration-list-count">
              <span className="material-symbols-rounded">groups</span>
              Collaborators ({userSelected?.length + 1})
            </p>
            {getOwner && (
              <div key={getOwner?.id} className="company-contact-item">
                <div className="company-details">
                  {imageTextWrapper(getOwner)}
                  <div className="company-contact--name">{`${getOwner?.first_name} ${getOwner?.last_name}`}</div>
                  {/* <div className="company-contact--info">{`${getOwner?.companyName} - ${getOwner?.role}`}</div> */}
                  <div className="company-contact--info">Owner</div>
                
                </div>
                <div>
                  <div className="checkbox-div">
                    <Form.Check
                      label=""
                      name={getOwner?.id}
                      type="checkbox"
                      id={getOwner?.id}
                      disabled={true}
                      checked={true}
                    />
                  </div>
                </div>
              </div>
            )}
            {inviteList?.map(p => (
              <div key={p?.id} className="company-contact-item">
                <div className="company-details">
                  {imageTextWrapper(p)}
                  <div className="company-contact--name">{`${p?.first_name} ${p?.last_name}`}</div>
                  {/* <div className="company-contact--info">{`${p?.companyName} - ${p?.role}`}</div> */}
                  <div className="company-contact--info">Client</div>
                </div>
                <div>
                  <div className="checkbox-div">
                    <Form.Check
                      label=""
                      name={p?.id}
                      type="checkbox"
                      id={p?.id}
                      onChange={event =>
                        handleCheckboxChange(p, event.target.checked)
                      }
                      disabled={selectedInviteType === 'all'}
                      checked={userSelected?.some(user => user.id === p.id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InviteSection;
