/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ListElement = props => {
  const [active, setActive] = useState(1);
  const {
    key = '',
    text = '',
    imageURL = '',
    ChangeColor = '',
    iconName = '',
    redirectURL = '',
    id = '',
    name = '',
  } = props;

  const navigate = useNavigate();
  const redirectToOtherPage = key => {
    redirectURL && navigate(`${redirectURL}`);
  };

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split('/');
    const segment = parts[parts.length - 1];
    segment && setActive(segment);
  }, []);
  return (
    <li
      key={id}
      onClick={() => redirectToOtherPage(id)}
      className={`${active === name ? 'active' : ''}`}>
      {iconName && <span className="material-symbols-rounded">{iconName}</span>}
      {text}
    </li>
  );
};
