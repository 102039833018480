import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeftPanelMainOptions } from '../../organisms/MainLeftPannel';
import { DealRoomHeader } from '../../organisms/DealRoomHeader';
import { NoDealHeader } from '../../organisms/NoDealPanel';
import { DealList } from '../../organisms/DealList';
import { dealListAction } from '../../react-shared/src/Actions/DealsActions';
import { useDispatch, useSelector } from 'react-redux';
import { NavBar } from '../../organisms/NavBar';
import { DealRoomPanel } from './DealRoomPanel';
import { Dealroomtabs } from './dealRoomUtils';
import { Loader } from '../../atoms/Loader';
import { Row, Col, Navbar, Nav} from 'react-bootstrap';
import './style.scss';

export const DealRoom = props => {
  const [dealList, setDealList] = useState({ getDealStatus: '' });
  const [active, setActive] = useState('');
  const [getDealStatus, SetDealStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const dealListValuesfromReducer = useSelector(state => state?.dealsReducers);

  const updateDealList = useCallback(params => {
    setDealList && setDealList(prevState => ({ ...prevState, ...params }));
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(
      dealListAction(
        {
          status: dealList?.getDealStatus,
          // pinned : "N",
          // deal_room
        },
        response => {
          setLoading(false);
          updateDealList({
            ListofDeals: response,
          });
        },
        error => {
          console.log('error response', error);
        },
      ),
    );
  }, [dealList?.getDealStatus, dealList?.newDeal, dealList?.successResponse, dealList?.pinned]);

  const dealStatus = status => {
    setActive(status);
    SetDealStatus(status);
    updateDealList({
      ...dealList,
      getDealStatus: status,
    });
  };

  const selectDealDetails = id => {
    navigate(`/deal/${id}`, { state: id });
  };

  return (
    <>
      <Row className='component-Wrapper'>
        <Col md={2}>
          <LeftPanelMainOptions
            // {...dealList?.ListofDeals}
            listofDeals = {dealList}
            onOptionClick={selectDealDetails}
          />
        </Col>
        <Col md={10} style={{paddingRight:0}}>
          <NavBar />
          
          <DealRoomHeader dealList={dealList} updateDealList={updateDealList} />
          <DealRoomPanel
            Dealroomtabs={Dealroomtabs}
            dealStatus={dealStatus}
            active={active}
          />
          {loading && (
            <div className="text-center">
              <Loader />
            </div>
          )}
          {dealList?.ListofDeals?.count > 0 ? (
            <DealList dealList = {dealList} updateDealList={updateDealList}/>
          ) : (
            !loading && <NoDealHeader />
          )}
        </Col>
      </Row>
    </>
  );
};
