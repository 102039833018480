/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Modal, Row, Col } from 'react-bootstrap';
import InviteSection from '../invite';
import { Button } from "../../../atoms/Button";
import { useDispatch } from "react-redux";
import { editSection } from "../../../react-shared/src/Actions/DealsActions";
import {ToastMessage} from '../../../atoms/Toast';
import './styles.scss';
import { ManageCollaborators } from "../ManageCollaborators";

export const ManageParticipants = (props) => {
  const { updateDealRoomDetails, dealRoomDetails, modalShow, handleClose, participants } =
    props;
    const dispatch = useDispatch();
  const [particiapanetList, setparttipanelist] = useState();
  const handleCollaborators = (user) => {
    console.log('user', user, dealRoomDetails);
    setparttipanelist(user);
  }
  const onSubmitparticipant = () => {
    const userIds = particiapanetList?.map(
      user => user.id,
    );
    dispatch(editSection({
        params: { participants: userIds },
        id: dealRoomDetails?.id,
    }, response => {
      ToastMessage({
        success: true,
        message: 'Manage Collaborators successfully',
      });
      updateDealRoomDetails({
        manageCollaboratorsResponse: response,
      })
      handleClose();
    }, error => {
      console.log('error', error);
      ToastMessage({
        success: true,
        message: 'Something went wrong please try again',
      });
    }))
  }
  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="editManageColModal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Mange collaborators</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InviteSection handleCollaborators={handleCollaborators} newSectionDetails={dealRoomDetails}/>

      </Modal.Body>
      <Modal.Footer>
          <Row>
            <Col md={6}>
              <Button
                varient="secondary"
                text={'Cancel'}
                type="button"
                onClickHandler={handleClose}>
                Close
              </Button>
            </Col>
            <Col md={6}>
              <Button
                varient="primary"
                text={'Save'}
                type="button"
                // tabName={key}
                onClickHandler={onSubmitparticipant}
                >
                {`Invite ${particiapanetList?.length}`}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
    </Modal>
  );
}