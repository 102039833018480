/* eslint-disable react/prop-types */
import React from 'react';
import {
  DropdownItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'react-bootstrap';
import './style.scss';
import { useDrop } from 'react-dnd';

export const DocumentFolderItem = ({
  fileName,
  fileNo,
  description,
  onClickRename,
  onClick,
  onDropFile,
  id,
  noAction,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'FILE',
    drop: item => onDropFile(item.id, id),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }));
  return (
    <div
      ref={drop}
      style={{
        backgroundColor: isOver ? 'lightgreen' : 'white',
      }}
      className="document-folder-card"
      onClick={onClick ? onClick : undefined}>
      <div className="document-folder-card--header mb-3">
        <div className="document-folder-card--name">{fileName}</div>
        {!noAction && (
          <div onClick={e => e.stopPropagation()}>
            <Dropdown className="nav-bar-custom-dropdown">
              <DropdownToggle className="custom-button-dropdown-toggle">
                <span className="material-symbols-rounded">more_vert</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={onClickRename}>
                  Rename Folder
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="document-folder-card--size mb-3">
        {fileNo} file{fileNo > 1 ? 's' : ''}
      </div>
      <div className="document-folder-card--size">{description}</div>
    </div>
  );
};
