/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, FormLabel } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Button } from '../../atoms/Button';
import {
  createFolderAction,
  getFolderListAction,
  updateFolderAction,
} from '../../react-shared/src/Actions/fileManager';
import { ToastMessage } from '../../atoms/Toast';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup
  .object({
    name: yup.string().required('Name is required!'),
    description: yup.string().required('Description is required!'),
  })
  .required();

export const CreateFolder = ({ handleClose, show, data = null }) => {
  const dispatch = useDispatch();
  const isUpdate = !!data;

  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (!!data) {
      setValue('name', data?.name);
      setValue('description', data?.description);
    }
  }, [data]);

  const handleCreate = ({ name, description }) => {
    if (!data) {
      dispatch(
        createFolderAction(
          { name, description },
          response => {
            console.log('response', response);
            ToastMessage({
              success: true,
              message: 'Created folder sucessfully',
            });
            dispatch(getFolderListAction());
            handleCloseModal();
          },
          error => {
            ToastMessage({
              success: false,
              message: 'Something went wrong',
            });
          },
        ),
      );
      return;
    }
    dispatch(
      updateFolderAction(
        { name, description, id: data?.id },
        response => {
          console.log('response', response);
          ToastMessage({
            success: true,
            message: 'Updated folder sucessfully',
          });
          handleCloseModal();
        },
        error => {
          ToastMessage({
            success: faile,
            message: 'Something went wrong',
          });
        },
      ),
    );
  };

  const handleCloseModal = () => {
    setValue('name', '');
    setValue('description', '');
    clearErrors();
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      className="modal-create-folder"
      size="lg"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>New Folder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="fw-bold">Folder Details</div>
        <div className="mt-4">
          <FormLabel>Name</FormLabel>
          <input
            {...register('name')}
            placeholder="Name your folder"
            className={errors.name ? 'error' : ''}
          />
          {errors.name && <p className="error">{errors.name?.message}</p>}
        </div>
        <div className="mt-2 mb-5">
          <FormLabel>Description</FormLabel>
          <textarea
            {...register('description')}
            placeholder="Type folder description here..."
            className={errors.description ? 'error' : ''}
          />
          {errors.description && (
            <p className="error">{errors.description?.message}</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col md={6}>
            <Button
              varient="secondary"
              text={'Cancel'}
              type="button"
              onClickHandler={handleCloseModal}
            />
          </Col>
          <Col md={6}>
            <Button
              varient="primary"
              text={isUpdate ? 'Update' : 'Create Folder'}
              type="button"
              onClickHandler={handleSubmit(handleCreate)}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
