/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { imageTextWrapper } from '../../common/utils';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const SectionTaskList = props => {
  const { item, handleInputChange } = props;
  const [checked, setChecked] = useState(item?.status?.code === 'C');

  useEffect(() => {
    setChecked(item?.status?.code === 'C');
  }, [item?.status?.code]);

  const handleTaskStatus = (id, isChecked) => {
    const newStatus = isChecked ? 'C' : 'O';
    handleInputChange(id, newStatus);
    setChecked(isChecked);
  };

  // to check all action items status 

  const checkAllActionItemStatus= (items) => {
    const checkStatus = items?.task_action_item?.filter(item => item?.status?.code === 'O');
    return checkStatus?.length > 0 ?  true : false
  }
  const isDisabled = checkAllActionItemStatus(item);
  return (

    <div className="tasklist-container" 
    data-tooltip-id={isDisabled ? `tooltip-${item?.id}` : undefined}>
      <input
        className="styled-checkbox"
        id={`styled-checkbox-1-${item?.id}`}
        type="checkbox"
        onChange={event => handleTaskStatus(item?.id, event.target.checked)}
        checked={checked}
        disabled={checkAllActionItemStatus(item)}
      />

      <label htmlFor={`styled-checkbox-1-${item.id}`}>
        {/* {imageTextWrapper(item?.owner)} */}
        <span className={`taskname ${checked ? 'strike-text' : ''}`}>
          {item?.name}
        </span>
      </label>
      {/* {item?.due_date && <span className="dueDate">{item?.due_date}</span>} */}
      <span className="material-symbols-rounded">more_horiz</span>
      {isDisabled && (
          <Tooltip
            id={`tooltip-${item?.id}`}
            // place="left"
            style={{ backgroundColor: '#0984E3', color: '#fff' }}
            content="You cannot change the status due to open tasks"
          />
        )}
    </div>
  );
};
