/* eslint-disable react/prop-types */
import React from 'react';
import './style.scss';

export const SelectInput = props => {
  const { value, onSelect = () => {}, options = [], icon = 'sort' } = props;

  return (
    <div className="select-input-group">
      <span className="material-symbols-rounded">{icon}</span>
      <select className="form-select" value={value} onChange={onSelect}>
        {options?.map(opt => {
          return (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
