import {
  GET_TASK_LIST,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_LIST_FAILURE,
  ADD_TASK_COMMENT,
  ADD_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_FAILURE,
  GET_TASK_COMMENT,
  GET_TASK_COMMENT_SUCCESS,
  GET_TASK_COMMENT_FAILURE,
  EDIT_TASK_COMMENT,
  EDIT_TASK_COMMENT_SUCCESS,
  EDIT_TASK_COMMENT_FAILURE,
  ADD_TASK_FILE,
  ADD_TASK_FILE_SUCCESS,
  ADD_TASK_FILE_FAILURE,
} from '../Constants/constants';

const initialState = {
  loading: false,
  getTaskList: {},
  addTaskComment: {},
  getTaskComment: {},
  editTaskComment: {},
  addTaskFile: {},
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK_LIST:
      // case JOIN_DEAL_ROOM:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case GET_TASK_LIST_SUCCESS:
      // case JOIN_DEAL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        getTaskList: action?.data,
        // token: action?.data?.access,
      };
    case GET_TASK_LIST_FAILURE:
      // case JOIN_DEAL_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        getTaskList: action?.data,
      };

    case ADD_TASK_COMMENT:
      // case JOIN_DEAL_ROOM:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case ADD_TASK_COMMENT_SUCCESS:
      // case JOIN_DEAL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        addTaskComment: action?.data,
        // token: action?.data?.access,
      };
    case ADD_TASK_COMMENT_FAILURE:
      // case JOIN_DEAL_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        addTaskComment: action?.data,
      };

    case GET_TASK_COMMENT:
      // case JOIN_DEAL_ROOM:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case GET_TASK_COMMENT_SUCCESS:
      // case JOIN_DEAL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        getTaskComment: action?.data,
        // token: action?.data?.access,
      };
    case GET_TASK_COMMENT_FAILURE:
      // case JOIN_DEAL_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        getTaskComment: action?.data,
      };

      case EDIT_TASK_COMMENT:
        // case JOIN_DEAL_ROOM:
        return {
          ...state,
          loading: true,
          // UserDetails : action.response,
        };
      case EDIT_TASK_COMMENT_SUCCESS:
        // case JOIN_DEAL_ROOM_SUCCESS:
        return {
          ...state,
          loading: false,
          editTaskComment: action?.data,
          // token: action?.data?.access,
        };
      case EDIT_TASK_COMMENT_FAILURE:
        // case JOIN_DEAL_ROOM_FAILURE:
        return {
          ...state,
          loading: false,
          editTaskComment: action?.data,
        };
  
        case ADD_TASK_FILE:
        // case JOIN_DEAL_ROOM:
        return {
          ...state,
          loading: true,
          // UserDetails : action.response,
        };
      case ADD_TASK_FILE_SUCCESS:
        // case JOIN_DEAL_ROOM_SUCCESS:
        return {
          ...state,
          loading: false,
          addTaskFile: action?.data,
          // token: action?.data?.access,
        };
      case ADD_TASK_FILE_FAILURE:
        // case JOIN_DEAL_ROOM_FAILURE:
        return {
          ...state,
          loading: false,
          addTaskFile: action?.data,
        };
  
        default:
      return state;
  }
};

export default taskReducer;
