/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'react-bootstrap';
import { getJustFormatedDate } from '../../common/utils';
import { updateDealAction } from '../../react-shared/src/Actions/DealsActions';
import { useDispatch, useSelector } from 'react-redux';
import { imageTextWrapper, areYouaOwner } from '../../common/utils';
import { ToastMessage } from '../../atoms/Toast';

export const DealCard = props => {
  const { item, updateDealList } = props;
  const {
    owner = '',
    description = '',
    created_at = '',
    participants = '',
    companyUrl = '',
    score = '',
    id = '',
    name = '',
    client = '',
    pinned = '',
  } = item;
  const visibleParticipants = participants?.slice(0, 3);
  const remainingCount = participants?.length - 3;

  const userProfile = useSelector(state => state.loginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectDeal = id => {
    navigate(`/deal/${id}`, { state: id });
  };
  const handleDropdownClick = event => {
    event.stopPropagation();
  };

  const pinnedDeal = (id, status) => {
    dispatch(
      updateDealAction(
        {
          id,
          params: {
            pinned: status === 'N' ? 'Y' : 'N',
          },
        },
        response => {
          console.log('response', response);
          updateDealList({
            pinned: response,
          });
          ToastMessage({
            success: true,
            message: `${response?.pinned === 'Y' ? 'Pinned deal succesfully' : 'Unpinned deal succesfully'}`,
          });
        },
        error => {
          console.log('error', error);
          ToastMessage({
            success: false,
            message: 'something went wrong please try again later',
          });
        },
      ),
    );
  };

  return (
    <div className="dealCard" onClick={() => selectDeal(id)} key={id}>
      <Row>
        <Col md={6}>
          <p className="clientName">{client}</p>
          {/* <img src={} /> */}
        </Col>
        <Col md={6} className="text-end">
          {areYouaOwner(userProfile, owner) && (
            <div className="ribbon">
              <Dropdown
                className="nav-bar-custom-dropdown"
                onClick={handleDropdownClick}>
                <DropdownToggle className="custom-button-dropdown-toggle">
                  <span className="material-symbols-rounded"> more_vert </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <div className="sign-out-dropdown-item">
                      {/* <img src={signoutIcon} alt="signout" /> */}
                      <div onClick={() => pinnedDeal(id, pinned)}>
                        {pinned === 'N' ? 'Add to' : 'Remove from'} Pinned
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </Col>
        <Col md={12}>
          <h3>{name}</h3>
          <div className='dealcardbref-wrapper'>
            <p className="dealcardbref">{description}</p>
          </div>
        </Col>
        <Col md={6}>
          <ul className="participantList">
            {visibleParticipants?.map((item, index) => {
              return <li key={index}>{imageTextWrapper(item)}</li>;
            })}
            {remainingCount > 0 && (
            <li>
              <div className="profilpartname">+{remainingCount}</div>
            </li>
            )}
          </ul>
        </Col>
        <Col md={6}>
          <p className="text-end deal-date">
            <span className="material-symbols-rounded">calendar_today</span>
            {getJustFormatedDate(created_at)}
          </p>
        </Col>
      </Row>
    </div>
  );
};
