/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'react-bootstrap';
import Form from './Form';
import '../styles.scss';

export const AddActionFiles = props => {
  
  const { actionItems, updateActionItems, openModal, handleClose } = props;

  return (
    <Modal
      show={openModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className='action-item-modal'
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Action Item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='task-name-action'>{actionItems?.name}</p>
        <Form
          actionItems={actionItems}
          updateActionItems={updateActionItems}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};
