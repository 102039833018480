/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Button } from '../../../atoms/Button';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ToastMessage } from '../../../atoms/Toast';
import { createTaskAction, updateTasks, getSingleSectionAction} from '../../../react-shared/src/Actions/DealsActions';
import './styles.scss';



const defaultTask = {
  id: 1,
  status: false,
  name: '',
  assignee: null,
  due_date: new Date(),
};

const AddTaskForm = props => {
  const { updateDealRoomDetails, dealRoomDetails, handleClose, editMode, membersList, sectionid} = props;
  const assigneeOptionss = [
    { value: 5, label: 'Pavanuser3 (ID: 5)' },
    { value: 7, label: 'Pavanuser3 (ID: 7)' },
    { value: 8, label: 'Pavanuser3 (ID: 8)' },
  ];
  console.log('dealRoomDetails', dealRoomDetails, membersList, assigneeOptionss);
  const [getMembers, setMembers] = useState(sectionid);
  const dispatch = useDispatch();

  useEffect(() => {
    setMembers(sectionid);
  },[sectionid])

  // useEffect(() => {
  //   dispatch(getSingleSectionAction({
  //     id: getMembers,
  //   }, response => {
  //     console.log('response', response);
  //   }, error => {
  //     console.log('error', error);
  //   }))
  // }, [])

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasks: [defaultTask],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });
  // console.log('tasks', tasks)
  useEffect(() => {
    if (editMode && dealRoomDetails) {
      const task = {
        id: dealRoomDetails.id || Date.now(),
        status: dealRoomDetails.status,
        name: dealRoomDetails.name || '',
        assignee: dealRoomDetails?.participantsList?.find(option => option.value === dealRoomDetails.assignee) || null,
        due_date: dealRoomDetails.due_date ? new Date(dealRoomDetails.due_date) : new Date(),
      };
      reset({ tasks: [task] });
    }
  }, [editMode, reset, dealRoomDetails]);

  const [loading, setLoading] = useState(false);

  const assigneeOptions = (data) => {
    console.log(data, 'data');
  }

  const onSubmit = data => {
    setLoading(true);
    const formattedTasks = data?.tasks?.map(({ id, ...rest }) => ({
      ...rest,
      due_date: new Date(rest.due_date).toISOString().split('T')[0],
      status: rest.status ? 'C' : 'O',
      assignee: rest?.assignee?.value,
    }));
    if(editMode) {
    dispatch(
      updateTasks(
        {
          id: dealRoomDetails?.id,
          params: {
            tasks: formattedTasks,
          }
          
        },
        response => {
          setLoading(false);
          ToastMessage({
            success: true,
            message: 'Task updated successfully',
          });
          updateDealRoomDetails({
            taskupdatedModalSuccessfully: response,
          });
          handleClose();
        },
        error => {
          setLoading(false);
          ToastMessage({
            success: false,
            message: 'Something went wrong. Please try again',
          });
        }
      )
    );
    }
    else {
    dispatch(
      createTaskAction(
        {
          deal_room: dealRoomDetails?.deal_room,
          section: dealRoomDetails?.id,
          tasks: formattedTasks,
        },
        response => {
          setLoading(false);
          ToastMessage({
            success: true,
            message: 'Task added successfully',
          });
          updateDealRoomDetails({
            taskAddedModalSucessfully: response,
          });
          handleClose();
        },
        error => {
          setLoading(false);
          ToastMessage({
            success: false,
            message: 'Something went wrong. Please try again',
          });
        }
      )
    );
  }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='task-form'>
      <div className='task-wrapper'>
      {fields.map((item, index) => (
        <Row key={item.id} className="taskContainer">
          {/* Status */}
          <Col md={1}>
            <Form.Check
              type="checkbox"
              {...register(`tasks.${index}.status`)}
              className="custom-checkbox"
              defaultChecked={item.status}
            />
          </Col>
          
          {/* Task Name */}
          <Col md={4}>
            <label>Task Name</label>
            <input
              {...register(`tasks.${index}.name`, {
                required: 'Task name is required',
              })}
              defaultValue={item.name}
              placeholder="Enter task name"
            />
            {errors?.tasks?.[index]?.name && (
              <p className="error">{errors.tasks[index].name.message}</p>
            )}
          </Col>
          
          {/* Assignee */}
          <Col md={3}>
            <label>Assignee</label>
            <Controller
              name={`tasks.${index}.assignee`}
              control={control}
              // rules={{ required: 'Assignee is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={membersList}
                  defaultValue={item.assignee}
                  placeholder="Select assignee"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body} 
                />
              )}
            />
            {errors?.tasks?.[index]?.assignee && (
              <p className="error">{errors.tasks[index].assignee.message}</p>
            )}
          </Col>
          
          {/* Due Date */}
          <Col md={3}>
            <label>Due Date</label>
            <Controller
              name={`tasks.${index}.due_date`}
              control={control}
              rules={{ required: 'Due date is required' }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value || new Date(item.due_date)}
                  onChange={date => field.onChange(date)}
                  dateFormat="yyyy-MM-dd"
                />
              )}
            />
            {errors?.tasks?.[index]?.due_date && (
              <p className="error">{errors.tasks[index].due_date.message}</p>
            )}
          </Col>

          {/* Delete Button */}
          <Col md={1}>
            {!editMode && (<button
              type="button"
              className="deletebutton-task"
              onClick={() => remove(index)}>
              <span className="material-symbols-rounded">delete</span>
            </button>)}
          </Col>
        </Row>
      ))}
      </div>
      {/* Add Task Button */}
      {
        !editMode && (
          <div className='addTask-button-wrapper'>
          <button
          className="addTasks"
          type="button"
          onClick={() =>
            append({
              id: Date.now(),
              status: false,
              name: '',
              assignee: null,
              due_date: new Date(),
            })
          }
          
          >
          <span className="material-symbols-rounded">playlist_add</span> Add Task
        </button>
        </div>

        )
      }


      {/* Form Action Buttons */}
      {/* <Modal.bottom> */}
      <Row className='sticky-button'>
        <Col md={6}>
          <Button
            varient="secondary"
            text="Cancel"
            type="button"
            onClickHandler={handleClose}>
            Close
          </Button>
        </Col>
        <Col md={6}>
          <Button
            varient="primary"
            text="Save"
            type="submit"
            disabled={fields.length === 0}
            loading={loading}>
          </Button>
        </Col>
      </Row>
      {/* </Modal.bottom> */}
    </form>
  );
};

export default AddTaskForm;
