// import { PayloadAction } from "@reduxjs/toolkit";
// import { takeEvery, call, put, fork } from 'redux-saga/effects';

import axios from "axios";
import { put, takeLatest, call } from "redux-saga/effects";
import { APPURL, apiConfig } from '../config';
import {
  GET_ACTION_ITEM_LIST,
  GET_ACTION_ITEM_LIST_SUCCESS,
  GET_ACTION_ITEM_LIST_FAILURE,
  ADD_ACTION_ITEM,
  ADD_ACTION_ITEM_SUCCESS,
  ADD_ACTION_ITEM_FAILURE,
  UPDATE_ACTION_ITEMS,
  UPDATE_ACTION_ITEMS_SUCCESS,
  UPDATE_ACTION_ITEMS_FAILURE,
} from "../Constants/constants";
// const Rurl = 'https://mmerge.onrender.com/en/login/'
// const GET_LOGIN = 'GET_LOGIN';
export function* getAllActionItemsSaga(request) {
  console.log(request, 'request');
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/action_item/`,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: GET_ACTION_ITEM_LIST_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: GET_ACTION_ITEM_LIST_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: GET_ACTION_ITEM_LIST_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchgetAllActionItemsSaga() {
  yield takeLatest(GET_ACTION_ITEM_LIST, getAllActionItemsSaga);
}

export function* addActionItemsSaga(request) {
  console.log(request, 'request');
  const { payload = {}, successCallback = {}, errorCallback = {} } = request;
  // console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.post,
      `${APPURL}deal/action_item/`,
      payload,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 201) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: ADD_ACTION_ITEM_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: ADD_ACTION_ITEM_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: ADD_ACTION_ITEM_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchgaddActionItemsSaga() {
  yield takeLatest(ADD_ACTION_ITEM, addActionItemsSaga);
}

export function* updateActionItemsSaga(request) {
  console.log(request, 'request');
  const { payload = {}, successCallback = {}, errorCallback = {} } = request;
  // console.log("request", request, payload);
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/action_item/${payload?.id}/`,
      payload?.params,
    );
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: UPDATE_ACTION_ITEMS_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: UPDATE_ACTION_ITEMS_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: UPDATE_ACTION_ITEMS_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchupdateActionItemsSaga() {
  yield takeLatest(UPDATE_ACTION_ITEMS, updateActionItemsSaga);
}

