import {
  CREAT_FOLDER,
  DELETE_DOCUMENT_FILE,
  GET_FILE_DETAIL,
  GET_FILE_LIST,
  GET_FOLDER_DETAIL,
  GET_FOLDER_LIST,
  UPDATE_DOCUMENT_FILE,
  UPDATE_FOLDER,
  UPLOAD_FILE,
  LINK_FILE,
  LINK_FILE_TASK
} from '../Constants/constants';

export const createFolderAction = (payload, successCallback, errorCallback) => {
  console.log('payload', payload);
  return {
    type: CREAT_FOLDER,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getFolderListAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: GET_FOLDER_LIST,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getFileListAction = (payload, successCallback, errorCallback) => {
  console.log('payload', payload);
  return {
    type: GET_FILE_LIST,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const updateFolderAction = (payload, successCallback, errorCallback) => {
  console.log('payload', payload);
  return {
    type: UPDATE_FOLDER,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const uploadDocumentFileAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: UPLOAD_FILE,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getFolderDetailAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: GET_FOLDER_DETAIL,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const getFileDetailAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: GET_FILE_DETAIL,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const deleteDocumentFileAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: DELETE_DOCUMENT_FILE,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const updateDocumentFileAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: UPDATE_DOCUMENT_FILE,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const linkFileAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: LINK_FILE,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const linkFileTaskAction = (
  payload,
  successCallback,
  errorCallback,
) => {
  console.log('payload', payload);
  return {
    type: LINK_FILE_TASK,
    payload: payload,
    successCallback,
    errorCallback,
  };
};
