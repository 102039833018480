/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  joinDealRoomAction,
  loginAction,
} from '../../react-shared/src/Actions/login';
import { Container, Row, Col } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

function LoginForm(props) {
  const { dealURl = '' } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [emailVerified, setEmailVerified] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitEmail = data => {
    const { email } = data;
    const isVerified = verifyEmail(email, dealURl, dispatch, setEmailVerified);
    // const isVerified = true;
    if (isVerified) {
      setEmailVerified(true);
    } else {
      // alert('Email verification failed');
    }
  };

  // Function to handle password submission
  const onSubmitPassword = data => {
    const { password, email } = data;
    // Handle password submission logic here
    dispatch(
      loginAction(
        {
          header: true,
          params: {
            email,
            password,
          },
        },
        response => {
          navigate(`/deal/${dealURl}`, { state: dealURl });
        },
        error => {
          console.log('error', error);
        },
      ),
    );
    console.log('Password submitted:', password);
  };

  return (
    // <div>
    <Container>
      <Row>
        <form
          onSubmit={handleSubmit(
            emailVerified ? onSubmitPassword : onSubmitEmail,
          )}>
          <Col md={12} className="inputGroup">
            <label>Email:</label>

            <input
              type="email"
              className={errors.email ? 'errorfield' : ''}
              placeholder="Enter email id"
              {...register('email', { required: 'Email is required' })}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </Col>

          {emailVerified && (
            <Col md={12} className="inputGroup pin-help">
              <label>
                Pin:{' '}
                <a
                  data-tooltip-id="my-tooltip-inline"
                  data-tooltip-content="Please check your mail for the pin"
                  // data-tooltip-variant="light"
                >
                  <span className="material-symbols-rounded">help</span>
                  <Tooltip
                    id="my-tooltip-inline"
                    style={{ backgroundColor: '#0984E3', color: '#fff' }}
                    // className='help-container'
                  />
                </a>
              </label>
              <input
                type="password"
                className={errors.password ? 'errorfield' : ''}
                placeholder="Enter pin"
                {...register('password', { required: 'pin is required' })}
              />
              {errors.password && (
                <p className="error">{errors.password.message}</p>
              )}
            </Col>
          )}

          <button type="submit" className="joindealdeal-button">
            {emailVerified ? 'Submit Password' : 'Verify Email'}
          </button>
        </form>
        {/* </div> */}
      </Row>
    </Container>
  );
}

// Dummy email verification function
function verifyEmail(email, url, dispatch, setEmailVerified) {
  // Replace this with actual verification logic (e.g., API call)
  dispatch(
    joinDealRoomAction(
      {
        url,
        email,
      },
      response => {
        setEmailVerified(true);
      },
      error => {
        console.log('error', error);
      },
    ),
  );
}

export default LoginForm;
