import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';
import { APPURL, apiConfig } from '../config';
import {
  ADD_COMMNENT,
  ADD_COMMNENT_SUCCESS,
  ADD_COMMNENT_FAILURE,
  GET_COMMNENTS,
  GET_COMMNENTS_SUCCESS,
  GET_COMMNENTS_FAILURE,
  EDIT_COMMNENTS,
  EDIT_COMMNENTS_SUCCESS,
  EDIT_COMMNENTS_FAILURE,
  DELETE_COMMNENTS,
  DELETE_COMMNENTS_SUCCESS,
  DELETE_COMMNENTS_FAILURE,
} from '../Constants/constants';

export function* postCommentSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request, payload);
  try {
    const result = yield call(
      apiConfig.post,
      `${APPURL}deal/comment/`,
      payload,
    );
    console.log('result', result);
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 201) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: ADD_COMMNENT_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: ADD_COMMNENT_FAILURE,
        data,
      });
      errorCallback?.(JSON(data));
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: ADD_COMMNENT_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchpostCommentSaga() {
  yield takeLatest(ADD_COMMNENT, postCommentSaga);
}

export function* getCommentsSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request, payload);
  try {
    const result = yield call(
      apiConfig.get,
      `${APPURL}deal/comment/?deal_room=${payload?.deal_room}&section=${payload?.section}`,
    );
    console.log('result', result);
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: GET_COMMNENTS_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: GET_COMMNENTS_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: GET_COMMNENTS_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watchgetCommentsSaga() {
  yield takeLatest(GET_COMMNENTS, getCommentsSaga);
}

export function* editCommentSaga(request) {
  const { payload, successCallback = {}, errorCallback = {} } = request;
  console.log('request', request, payload);
  try {
    const result = yield call(
      apiConfig.patch,
      `${APPURL}deal/comment/${payload?.id}/`,
      payload?.params,
    );
    console.log('result', result);
    const { status, data } = result;
    // console.log("data", JSON.parse(result?.data));
    if (status === 200) {
      // console.log("data", JSON.parse(result?.data));
      yield put({
        type: EDIT_COMMNENTS_SUCCESS,
        data,
      });
      successCallback?.(data);
    } else {
      // console.log("data", JSON.parse(data));
      yield put({
        type: EDIT_COMMNENTS_FAILURE,
        data,
      });
      errorCallback?.(data);
    }
  } catch (error) {
    // const { data } = error;
    // console.log("data", data);
    yield put({
      type: EDIT_COMMNENTS_FAILURE,
      error,
    });
    errorCallback?.(error);
  }
}

// Generator function
export function* watcheditCommentSaga() {
  yield takeLatest(EDIT_COMMNENTS, editCommentSaga);
}
