/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Tabs, Tab, Form, FormLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '../../atoms/Button';
import { EmptyCollaborators } from './EmptyCollaborators';
import { SectionWrapper } from '../../atoms/SectionWrapper';
import groupIcon from '../../images/group-people.svg';
import { CollaboratorItem } from './CollaboratorItem';
import { MultiTagInput } from '../../atoms/MultiTagsInput';
import { inviteColloboratorsDealAction, removeCollaboratorsAction} from '../../react-shared/src/Actions/DealsActions';
import { ToastMessage } from '../../atoms/Toast';
import { RemoveCollabModal } from '../../organisms/DealRoomDetails/Templates/RemoveCollabModal';

export const ManageCollaborators = props => {
  const {
    handleClose,
    show,
    editMode = false,
    dealDetails,
    updateRoomDetails,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('invite');
  const [customMessage, setCustomMessage] = useState('');
  const [shareSetting, setShareSetting] = useState('Y');
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reinviteMembers, setReinviteMembers] = useState([]);
  const [reinvite, setReinvite] = useState(false);
  const [remove, setRemove] = useState({});
  // const removeConfirmHandler = id => {
  //   setRemove(prevState => ({
  //     ...prevState,
  //     [id]: true,
  //   }));
  // };
  useEffect(() => {
    const temp = [];
    // dealDetails?.participants?.forEach(p => temp.push(p.email));
    setMembers([...temp]);
  }, [dealDetails]);

  const handleSave = () => {
    const payload = {
      id: dealDetails?.id,
      params: {
        emails: reinvite ? [reinviteMembers] : [...members],
        deal_room_link: dealDetails?.public_link,
        invite_custom_message: customMessage,
        invite_other_collaborators: shareSetting,
      },
    };

    setLoading(true);
    dispatch(
      inviteColloboratorsDealAction(
        payload,
        data => {
          ToastMessage({
            success: true,
            message: 'Invited Collaborators successfully',
          });
          updateRoomDetails({
            inviteCollaboratorsSucess: data,
          });
          setReinviteMembers([]);
          setMembers([]);
          setReinvite(false);
          handleClose();
          setCustomMessage('');
          setLoading(false);
        },
        error => {
          ToastMessage({
            success: false,
            message: 'Something went wrong Please try again later',
          });
          setLoading(false);
          setReinviteMembers([]);
          setReinvite(false);
          setMembers([]);
          setCustomMessage('');
          handleClose();
        },
      ),
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(dealDetails?.public_link);
    ToastMessage({
      success: true,
      message: 'Copied',
    });
  };

  const isEmptyPaticipant =
    !dealDetails || dealDetails?.participants?.length === 0;

  const removeConfirmHandler = id => {
    setRemove(prevState => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the specific modal's visibility
    }));
  };

  const removeCollaboratorsHandler = data => {
    dispatch(removeCollaboratorsAction({
      deal_room: dealDetails?.id,
      userID: data?.id
    }, response => {
      ToastMessage({
        success: true,
        message: 'removed Collaborators successfully',
      });
      updateRoomDetails({
        removeCollaboratorsSucess: response,
      });
      setReinviteMembers([]);
      setMembers([]);
      setReinvite(false);
      handleClose();
      setCustomMessage('');
      setLoading(false);
    }, error => {
      ToastMessage({
        success: false,
        message: 'Something went wrong Please try again later',
      });
      setLoading(false);
      setReinviteMembers([]);
      setReinvite(false);
      setMembers([]);
      setCustomMessage('');
      handleClose();
    }))
  };
  const handleCloseRemoveModal = () => {
    setRemove(false);
  };

  const resendInvite = data => {
    console.log('data', data);
    setReinvite(true);
    setReinviteMembers(data?.email);
    handleSave();
  };

  const getCustomMessageHandler = e => {
    setCustomMessage(e?.target?.value);
  };
  const getSettingDetailsHandler = event => {
    setShareSetting(event.target.value);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal-manage-collaborators"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Invite Collaborators to {dealDetails?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          activeKey={activeTab}
          className="creactSectionTabs"
          fill
          onSelect={t => setActiveTab(t)}>
          <Tab eventKey="invite" title="Invite">
            <div className="modal-manage-collaborators-title">
              Share Settings
            </div>
            {/* <div className="modal-manage-collaborators-get-link mb-4">
              <div>Get a shareable link to this Deal Room</div>
              <div onClick={handleCopyLink}>Copy Link</div>
            </div> */}
            <div className="modal-manage-collaborators-access-deal">
              <div className="modal-manage-collaborators--access-deal-title">
                Collaborators can invite other collaborators
              </div>
              <div className="modal-manage-collaborators--access-deal-radio">
                <Form.Check
                  type="radio"
                  id="invite-type1"
                  label="Yes"
                  name="invite-type"
                  value="Y"
                  onChange={getSettingDetailsHandler}
                  checked={shareSetting === 'Y'}
                />
                <Form.Check
                  type="radio"
                  id="invite-type2"
                  label="No"
                  name="invite-type"
                  value="N"
                  onChange={getSettingDetailsHandler}
                  checked={shareSetting === 'N'}
                />
              </div>
            </div>
            <div className="modal-manage-collaborators-title mt-4 mb-4">
              Invite
            </div>
            <div>
              <FormLabel>
                Invite people by email (use comma to invite multiple users)
              </FormLabel>
              <MultiTagInput tags={members} setTags={setMembers} />
            </div>
            <div className="mt-4">
              <FormLabel>Add a custom message (optional)</FormLabel>
              <textarea
                placeholder="Add a custom welcome message to your invite"
                value={customMessage}
                onChange={getCustomMessageHandler}
              />
            </div>
          </Tab>
          <Tab
            eventKey="manage"
            title={`Manage Collaborators (${dealDetails?.participants?.length || 0})`}>
            {isEmptyPaticipant ? (
              <EmptyCollaborators />
            ) : (
              <SectionWrapper>
                <div className="manage-collaborators-title-group">
                  <img src={groupIcon} alt="group" />
                  <div>
                    Collaborators ({dealDetails?.participants?.length || 0})
                  </div>
                </div>
                <div>
                  {dealDetails?.participants?.map(p => {
                    return (
                      <>
                        <CollaboratorItem
                          key={p.id}
                          data={p}
                          // handleRemove={removeCollaboratorsHandler}
                          handleRemove={removeConfirmHandler}
                          resendInvite={resendInvite}
                          // remove={remove}
                        />
                        <RemoveCollabModal
                          data={p}
                          btnText="Remove From Deal Room"
                          show={remove[p.id]} // This ensures only the relevant modal shows
                          handleConfirm={removeCollaboratorsHandler}
                          handleCloseRemoveModal={handleCloseRemoveModal}
                          handleCopyLink={handleCopyLink}
                          handleClose={() =>
                            setRemove(prevState => ({
                              ...prevState,
                              [p.id]: false,
                            }))
                          }
                        />
                      </>
                    );
                  })}
                </div>
              </SectionWrapper>
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
      {activeTab === 'invite' && (
        <Modal.Footer>
          <Row>
            <Col md={6}>
              <Button
                varient="secondary"
                text={'Close'}
                type="button"
                onClickHandler={handleClose}
              />
            </Col>
            <Col md={6}>
              <Button
                varient="primary"
                text={`Send Invite ${members.length ? members?.length : ''}`}
                type="button"
                onClickHandler={handleSave}
                loading={loading}
                disabled={!members?.length > 0}
              />
            </Col>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  );
};
