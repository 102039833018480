import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo.svg';
import '../App.scss';

import { LoginComponent } from '../components/Login/Login';
import { DashBoard } from '../components/Dashboard';
import { DealRoom } from '../components/DealRoom';
import { DealRoomDetails } from '../components/DealRoomDetails';
import { Company } from '../components/Company';
import { Profile } from '../components/Profile';
import { Documents } from '../components/Documents';
// import { Provider } from "react-redux";
import { Provider } from 'react-redux';
import { store, persistor } from '../react-shared/src/store';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import { ResetPasswordComponent } from '../components/ResetPassword';
import { FormResetPasswordComponent } from '../components/FormResetPassword';
import JoinDealRedirect from '../components/JoinDealPage';
import { NotFound } from '../components/NotFound';
import { DocumentsFolderDetails } from '../components/DocumentsFolderDetails';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const AppWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginComponent />} />
        <Route path="reset-password">
          <Route path="/reset-password" element={<ResetPasswordComponent />} />
          <Route
            path="/reset-password/submit-form"
            element={<FormResetPasswordComponent />}
          />
        </Route>
        <Route
          path="/en/deal/join_deal_link/:dynamicPart"
          element={<JoinDealRedirect />}
        />

        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/dealroom" element={<DealRoom />} />
          <Route path="/deal/:id" element={<DealRoomDetails />} />
          <Route path="/company" element={<Company />} />
          <Route path="/document" element={<Documents />} />
          <Route
            path="/document/folder/:id"
            element={<DocumentsFolderDetails />}
          />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  // const dispatch = useDispatch();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <DndProvider backend={HTML5Backend}>
            <AppWrapper />
          </DndProvider>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
