/* eslint-disable react/prop-types */
import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AddActionItem } from './Templates/AddActionItem';
import './styles.scss';
import { ActionItemSection } from '../../molecules/ActionItemSection';
import { TaskComments } from './Templates/TaskComments';
import { AddActionFiles } from './Templates/AddActionFiles';
import { FileSection } from '../DealRoomDetails/Templates/FileTemplates';
import { AddActionTask } from './Templates/AddActionTask';

export const ActionItem = props => {
  const { actionItems, updateActionItems, redirectSection } = props;
  const [modalStates, setModalStates] = useState({});
  const [fileModalStates, setFileModalStates] = useState({});
  const [addNewTask, setAddNewTask] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [itemsRendered, setItemsRendered] = useState(false);
  const hasScrolled = useRef(false);

  const handleCloseHandler = id => {
    setModalStates(prevState => ({
      ...prevState,
      [id]: false,
    }));
    setFileModalStates(prevState => ({
      ...prevState,
      [id]: false,
    }));
  };

  const openModalHandler = id => {
    setModalStates(prevState => ({
      ...prevState,
      [id]: true,
    }));
  };

  const openModalHandlerFile = id => {
    setFileModalStates(prevState => ({
      ...prevState,
      [id]: true,
    }));
  };

  const openModalHandlerNewTask = () => {
    setAddNewTask(true);
  };

  const closeHandlerNewTask = () => {
    setAddNewTask(false);
    setModalStates(false);
  };

  useEffect(() => {
    if (redirectSection) {
      window.history.pushState(null, '', `#${redirectSection}`);
      setTimeout(() => {
        const targetElement = document.getElementById(redirectSection);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        } else {
          console.warn(`Element with ID ${redirectSection} not found`);
        }
      }, 100);
    }
  }, [redirectSection, actionItems]);
  return (
    <Row className="action-plan-tasks">
      <div
        className="action-plan-create-new-task"
        onClick={openModalHandlerNewTask}>
        <h2>
          <span className="material-symbols-rounded">add</span>New Task
        </h2>
      </div>
      {actionItems &&
        actionItems?.actionTasks?.map(item => {
          const isModalOpen = modalStates[item?.id] || false;
          const isFileModalOpen = fileModalStates[item?.id] || false;
          console.log('item', item);
          return (
            <Col
              md={12}
              key={item?.id}
              id={item?.id}
              className="actionplan-section-id">
              <div className={`actionplan-section ${item?.status?.code === 'C' ? 'sectiongreen' : ''}`}>
                <div className="action-details">
                  <ActionItemSection
                    actionItem={item}
                    updateActionItems={updateActionItems}
                    key={item?.id}
                  />
                </div>
                <div className="actionplan-Details-conatiner">
                  <div className="actionplan-taks">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <p className="name">
                        <span className='material-symbols-rounded'>task_alt</span>
                        {item?.action_item_count} Action Items
                        </p>
                      </li>
                      <li className="list-inline-item additem">
                        <p onClick={() => openModalHandlerFile(item?.id)}>
                          Add Action Item
                        </p>
                      </li>
                    </ul>
                    {
                      item?.task_action_item.length > 0 && (
                        <div className="timeline">
                        {item?.task_action_item?.map(subItem => (
                          <div className="timeline-item" key={subItem?.id}>
                            <ActionItemSection
                              actionItem={subItem}
                              updateActionItems={updateActionItems}
                              key={subItem?.id}
                              isItActionItem={true}
                            />
                          </div>
                        ))}
                      </div>
                      )
                    }
                  </div>
                  <div className="actionplan-taks">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <p className="name">
                          <span className='material-symbols-rounded'>folder</span>
                          {item?.task_file_count} Files
                        </p>
                      </li>
                      <li className="list-inline-item additem">
                        <p onClick={() => openModalHandler(item?.id)}>
                          Add file
                        </p>
                      </li>
                    </ul>
                    <FileSection
                      filesDate={item?.task_file}
                      DontShowCount={true}
                    />
                  </div>
                  <div className="actionitem-comments">
                    <TaskComments
                      commentsList={item}
                      isitfromActionPlan={true}
                    />
                  </div>
                </div>
              </div>
              <AddActionItem
                handleClose={closeHandlerNewTask}
                openModal={isModalOpen}
                actionItems={item}
                updateActionItems={updateActionItems}
              />
              <AddActionFiles
                handleClose={() => handleCloseHandler(item?.id)}
                openModal={isFileModalOpen}
                actionItems={item}
                updateActionItems={updateActionItems}
              />
            </Col>
          );
        })}
      <AddActionTask
        handleClose={closeHandlerNewTask}
        openModal={addNewTask}
        actionItems={actionItems}
        updateActionItems={updateActionItems}
      />
    </Row>
  );
};
