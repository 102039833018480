import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SectionWrapper } from '../../atoms/SectionWrapper';
import uploadIcon from '../../images/upload.svg';

const schema = yup
  .object({
    companyName: yup.string().required('Required!'),
    industry: yup.string().required('Required!'),
    companySize: yup.string().required('Required!'),
    companyLocation: yup.string().required('Required!'),
    companyWebsite: yup.string().required('Required!'),
  })
  .required();

export const CompanyProfileForm = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = data => {
    console.log('submit: ', data);
  };

  return (
    <Container fluid className="section-container">
      <Row>
        <h2 className="company-section-title mb-3">Company Profile</h2>
      </Row>
      <SectionWrapper>
        <h2 className="company-section-title">Basis Infomation</h2>
        <div className="company-section-subtitle mb-3">
          Basic Information: Company name, industry, size, location, website,
          and contact details.
        </div>
        <Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col md={12} className="inputGroup">
              <label>Company Name</label>
              <input
                {...register('companyName')}
                placeholder={'Give this section a name'}
                autoComplete="false"
                className={errors.companyName ? 'error' : ''}
              />
              {errors.companyName && (
                <p className="error">{errors.companyName?.message}</p>
              )}
            </Col>
            <Col md={12} className="inputGroup">
              <label>Industry</label>
              <input
                {...register('industry')}
                placeholder={'Give this section a name'}
                autoComplete="false"
                className={errors.industry ? 'error' : ''}
              />
              {errors.industry && (
                <p className="error">{errors.industry?.message}</p>
              )}
            </Col>
            <Col md={12} className="inputGroup">
              <label>Company Size</label>
              <input
                {...register('companySize')}
                placeholder={'Give this section a name'}
                autoComplete="false"
                className={errors.companySize ? 'error' : ''}
              />
              {errors.companySize && (
                <p className="error">{errors.companySize?.message}</p>
              )}
            </Col>
            <Col md={12} className="inputGroup">
              <label>Comany Location</label>
              <input
                {...register('companyLocation')}
                placeholder={'Give this section a name'}
                autoComplete="false"
                className={errors.companyLocation ? 'error' : ''}
              />
              {errors.companyLocation && (
                <p className="error">{errors.companyLocation?.message}</p>
              )}
            </Col>
            <Col md={12} className="inputGroup">
              <label>Company Website</label>
              <input
                {...register('companyWebsite')}
                placeholder={'Give this section a name'}
                autoComplete="false"
                className={errors.companyWebsite ? 'error' : ''}
              />
              {errors.companyWebsite && (
                <p className="error">{errors.companyWebsite?.message}</p>
              )}
            </Col>
          </Form>
        </Row>
      </SectionWrapper>
      <SectionWrapper className="mt-4">
        <h2 className="company-section-title">Company Decks</h2>
        <div className="company-section-subtitle mb-3">
          Basic Information: Company name, industry, size, location, website,
          and contact details.
        </div>
        <div className="company-section-upload-container">
          <img src={uploadIcon} alt="upload" />
          <div>Upload File</div>
        </div>
      </SectionWrapper>
    </Container>
  );
};
