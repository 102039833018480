import { put, takeLatest, call, take } from 'redux-saga/effects';
import { apiConfig, APPURL } from '../config';
import {
  GET_DASHBOARD_ACTIVITY,
  GET_DASHBOARD_ACTIVITY_FAILURE,
  GET_DASHBOARD_ACTIVITY_SUCCESS,
} from '../Constants/constants';

export function* getDashboardActivitySaga(request) {
  const { successCallback = null, errorCallback = null, payload } = request;

  try {
    const page = payload?.page || 1;
    const result = yield call(
      apiConfig.get,
      `${APPURL}activity_overview/?page=${page}`,
    );
    const { status, data } = result;
    if (status === 200) {
      yield put({
        type: GET_DASHBOARD_ACTIVITY_SUCCESS,
        data,
      });
      if (typeof successCallback === 'function') {
        successCallback?.(data);
      }
    } else {
      yield put({
        type: GET_DASHBOARD_ACTIVITY_FAILURE,
        data,
      });
      if (typeof errorCallback === 'function') {
        errorCallback?.(data);
      }
    }
  } catch (error) {
    yield put({
      type: GET_DASHBOARD_ACTIVITY_FAILURE,
      error,
    });
    if (typeof errorCallback === 'function') {
      errorCallback?.(error);
    }
  }
}

export function* watchGetDashboardActivity() {
  yield takeLatest(GET_DASHBOARD_ACTIVITY, getDashboardActivitySaga);
}
