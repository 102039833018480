/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './style.scss';

export const ModalConfirm = ({
  show = false,
  handleClose,
  btnText = 'Close',
  bodyText = '',
  title = '',
  icon = null,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal-custom"
      centered
      backdrop="static"
      size="md">
      <Modal.Body>
        {icon && <img src={icon} alt="icon" />}
        <div className="modal-custom--title">{title}</div>
        <div className="modal-custom--text">{bodyText}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{btnText}</Button>
      </Modal.Footer>
    </Modal>
  );
};
