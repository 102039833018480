import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalConfirm } from '../../../atoms/ModalConfirm';
import tickIcon from '../../../images/tick-success.svg';

const schema = yup
  .object({
    password: yup
      .string()
      .test('len', 'Minimum 6 letters', val => val?.length >= 6)
      .required('Required!'),
    retypePassword: yup
      .string()
      .required('Required!')
      .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  })
  .required();

export const FormResetPassword = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShow, setIsShow] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onClickResetPassword = data => {
    setTimeout(() => {
      setIsShow(!isShow);
    }, 1000);
  };

  const handleClose = () => {
    navigate('/');
  };

  const buttonList = [
    {
      text: 'Save Password',
      varient: 'primary',
      size: 12,
      type: 'submit',
    },
  ];

  return (
    <>
      <Container>
        <Row>
          <Form
            autoComplete="new-password"
            onSubmit={handleSubmit(onClickResetPassword)}>
            <Col md={12} className="inputGroup">
              <label>Enter New Password</label>
              <input
                {...register('password')}
                placeholder={'Enter your new password'}
                autoComplete="false"
                className={errors.password ? 'error' : ''}
                type={'password'}
              />
              {errors.password && (
                <p className="error">{errors.password?.message}</p>
              )}
            </Col>
            <Col md={12} className="inputGroup">
              <label>Retype New Password</label>
              <input
                {...register('retypePassword')}
                placeholder={'Retype your new password'}
                autoComplete="false"
                className={errors.retypePassword ? 'error' : ''}
                type={'password'}
              />
              {errors.retypePassword && (
                <p className="error">{errors.retypePassword?.message}</p>
              )}
            </Col>
            <Button
              {...buttonList[0]}
              disabled={!watch('password') || !watch('retypePassword')}
            />
          </Form>
        </Row>
      </Container>
      <ModalConfirm
        show={isShow}
        icon={tickIcon}
        btnText="Sign In"
        title="Password changed"
        bodyText="Your new password has been successfully saved. You can now sign in with your new password."
        handleClose={handleClose}
      />
    </>
  );
};
