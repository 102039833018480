/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { FormCheck, Spinner } from 'react-bootstrap';
import docType from '../../images/doc.svg';
import navIcon from '../../images/nav-file.svg';
import './style.scss';
import { useDrag } from 'react-dnd';

export const DocumentFileItem = ({
  fileName,
  fileSize,
  isLoading,
  fileUrl,
  onClick,
  id,
  canDrag = false,
  noAction = false,
  clickSelectHandler,
  params,
  selectedFileList,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'FILE',
    item: { id },
    canDrag: !noAction && canDrag,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const handleClickNav = e => {
    e.stopPropagation();
    window.open(fileUrl, '_blank');
  };

  const [isChecked, setIsChecked] = useState(() =>
    selectedFileList?.some(item => item.id === id),
  );

  useEffect(() => {
    setIsChecked(selectedFileList?.some(item => item.id === id));
  }, [selectedFileList, id]);

  const handleCheckboxChangeHandler = (item) => {

    setIsChecked(prev => !prev);
    clickSelectHandler(item);
  };

  return (
    <div
      className={`document-file-card ${isChecked ? 'active' : ''}`}
      onClick={() => {
        if (!noAction) {
          onClick();
        } else {
          handleCheckboxChangeHandler(params); // Toggle checkbox when item is clicked
        }
      }}
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: noAction ? 'pointer' : 'move',
      }}>
      <div className="document-file-card--info">
        {noAction && (
          <FormCheck
            aria-label="option 1"
            className="custom-form-check"
            onChange={() => handleCheckboxChangeHandler(params)}
            checked={isChecked}
          />
        )}
        <img src={docType} alt="file" />
        <div>
          <div className="document-file-card--name">{fileName}</div>
          <div className="document-file-card--size">{fileSize}</div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <img
            className="document-file-card--btn"
            src={navIcon}
            alt="nav"
            onClick={handleClickNav}
          />
        )}
      </div>
    </div>
  );
};
