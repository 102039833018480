/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import iconDelete from '../../images/backspace.svg';
import './style.scss';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const converStringToEmails = string => {
  const temp = string.replaceAll(' ', '').split(',');
  const result = [];

  if (temp && temp.length > 0) {
    temp.forEach(v => {
      if (emailRegex.test(v.trim())) {
        result.push(v);
      }
    });
  }
  return result;
};

export const MultiTagInput = props => {
  const { tags = [], setTags } = props;

  const [inputValue, setInputValue] = useState('');

  const handleChange = e => {
    const val = e.target.value?.replaceAll(' ', '');
    setInputValue(val);
  };

  const handleBlur = e => {
    const val = e.target.value;
    const result = converStringToEmails(val);
    setTags([...tags, ...result]);
    setInputValue('');
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && inputValue.trim()) {
      const result = converStringToEmails(inputValue);
      setTags([...tags, ...result]);
      setInputValue('');
    }
  };

  const handleDelete = val => {
    const temp = tags?.filter(t => t !== val);
    setTags([...temp]);
  };

  return (
    <div className="multi-tags-input">
      {tags?.map(t => {
        return (
          <div key={t} className="multi-tags-input--tag-item">
            <div>{t}</div>
            <img
              src={iconDelete}
              alt="delete"
              onClick={() => handleDelete(t)}
            />
          </div>
        );
      })}
      <div className="multi-tags-input--input-wrapper">
        <input
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};
