/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute({ children, ...rest }) {
  const token = useSelector(state => state.loginReducer.token);

  return token ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;
