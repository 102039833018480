/* eslint-disable react/prop-types */
import React from 'react';
import './styles.scss'; // Optional: use this for CSS styles

const CustomProgressBar = ({ progress }) => {
  // Determine the color based on the progress percentage
  let progressColor;
  if (progress <= 25) {
    progressColor = '#FF7675'; // Red for 0-25%
  } else if (progress <= 50) {
    progressColor = '#ffcc00'; // Yellow for 26-50%
  } else if (progress <= 75) {
    progressColor = '#66cc66'; // Green for 51-75%
  } else {
    progressColor = '#4CAF50'; // Dark Green for 76-100%
  }

  return (
    <div className="custom-progress-bar-container">
      <div
        className="custom-progress-bar"
        style={{
          width: `${progress}%`,
          backgroundColor: progressColor,
        }}
      >
        {/* <span className="custom-progress-text">{progress}%</span> */}
      </div>
    </div>
  );
};

export default CustomProgressBar;
