/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {imageTextWrapper} from '../../../common/utils';
import './styles.scss';

export const RemoveCollabModal = ({
data = {},
show,
handleConfirm,
btnText,
handleCloseRemoveModal,
handleCopyLink
}) => {

  return (
    <Modal
      show={show}
      onHide={handleCloseRemoveModal}
      className="modal-confirm-custom"
      backdropClassName="backdrop-custom"
      centered
      backdrop="static"
      size="sm">
      <Modal.Body>
        <div className='header-section-wrapper'>
          <div className='header-section'>
            <div>
{imageTextWrapper(data)}
            </div>
            <div>
              <div className='header-section--name'>{`${data?.first_name} ${data?.last_name}`}</div>
              <div className='header-section--position'>client</div>
            </div>
          </div>
        </div>
        <div className='sub-section'>
          <div className='sub-section--name'>Invited:</div>
          <div className='sub-section--details'>03:00 PM, 21 May 2023</div>
        </div>
        <div className='sub-section'>
          <div className='sub-section--name'>Accepted:</div>
          <div className='sub-section--details'>03:00 PM, 21 May 2023</div>
        </div>
        <div className='sub-section'>
          <div className='sub-section--name'>Email:</div>
          <div className='sub-section--details'>{data?.email} <span className="material-symbols-rounded sub-section--copy" onClick={() =>handleCopyLink(data?.email)}>
content_copy
</span></div>
        </div>
        <div className='sub-section'>
        <div className='sub-section--name'>Collaborating on 2 sections:</div>
          {data?.sections.map((item, index) => {
            <div className='sub-section--details' key={index}>{item}</div>
          })}
          
          
          {/* <div className='sub-section--details'>Pricing</div> */}
        </div>
       
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-confirm-custom--footer-container">
          {/* {btnCancel && (
            <Button variant="outline-secondary" onClick={handleCancel}>
              {btnCancel}
            </Button>
          )} */}
          <Button onClick={() => handleConfirm(data)}>{btnText}</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
