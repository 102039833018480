import React from 'react';
import emptyImg from '../../images/empty-collaborators.svg';

export const EmptyCollaborators = () => {
  return (
    <div className="empty-collaborators">
      <img src={emptyImg} alt="empty" />
      <div className="empty-collaborators--title mb-3">No one’s here yet!</div>
      <div className="empty-collaborators--text">
        When you’re ready, share this deal room with people to collaborate with
        them.
      </div>
    </div>
  );
};
