/* eslint-disable react/prop-types */
import React from 'react';
import { imageTextWrapper } from '../../common/utils';
import { useSelector } from 'react-redux';
import { areYouaOwner, getFormatedDate } from '../../common/utils';

export const CollaboratorItem = props => {
  const { handleRemove, handleResend, data, resendInvite} = props;
  const userProfile = useSelector(state => state.loginReducer);
  return (
    <div className="deal-room-collaborator-item">
      <div className="deal-room-collaborator-item--info">
        <div className="deal-room-collaborator-item--personal-info">
          {imageTextWrapper(data)}
          <div className="deal-room-collaborator-item--name">
            {data.first_name + ' ' + data?.last_name}
          </div>
          <div className="deal-room-collaborator-item--role">Client</div>
        </div>
        <div className="deal-room-collaborator-item--email">{data.email}</div>
        <div className="deal-room-collaborator-item--time">
          <div>
            <span>Invited:</span> {getFormatedDate(data?.invited_time)}
          </div>
          <div>
            <span>
              {data?.accepted_time ? 'Accepted:' : 'Not accepted yet'}{' '}
            </span>{' '}
            {data?.accepted_time && getFormatedDate(data?.accepted_time)}
          </div>
        </div>
      </div>
      <div className="deal-room-collaborator-item--actions">
        {!areYouaOwner(userProfile, data) && (
          <>
          <div>
            {!data?.accepted_time && (
            <div
              className="deal-room-collaborator-item--btn-resend"
              onClick={() => resendInvite(data)}>
              Resend Invite
            </div>
            )
            }
          </div>
          <div
            className="deal-room-collaborator-item--btn-remove"
            onClick={() => handleRemove(data?.id)}>
            Remove
          </div>
          
          </>
        )}
      </div>
    </div>
  );
};
