/* eslint-disable react/prop-types */
import React, { useContext, useRef } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

export const FileVersionHeader = ({ eventKey, version, isLastest = false }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  return (
    <div
      className={`file-version-section-header ${isLastest ? 'lastest-version' : ''}`}>
      <span
        className={`material-symbols-rounded ${isCurrentEventKey ? 'expand' : ''}`}
        onClick={decoratedOnClick}>
        expand_all
      </span>
      <div className="file-tag-version">Version {version}</div>
    </div>
  );
};
