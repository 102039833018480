/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import { getallTaskAction } from '../../react-shared/src/Actions/tasksAction';
import {getCollaboratorsAction} from '../../react-shared/src/Actions/DealsActions';
import { useDispatch } from 'react-redux';
import { ActionItem } from '../../organisms/ActionItem';
// import { getAllActionItemsAction } from '../../react-shared/src/Actions/ActionItemAction';
// import { rules } from 'eslint-plugin-prettier';

export const ActionItems = props => {
  // console.log('redirectSection', redirectSection);
  const {redirectSection} = props;
  const [idFromPanel, setIdFromPanel] = useState('');
  const [actionItems, setActionItems] = useState('');
  
  const dispatch = useDispatch();

  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const Pathid = pathname.split('/')[2];
  
  const updateActionItems = useCallback(params => {
    setActionItems &&
      setActionItems(prevState => ({ ...prevState, ...params }));
  }, []);


  useEffect(() => {
    setIdFromPanel(Pathid);
  }, [Pathid]);
  
  useEffect(() => {
    dispatch(
      getallTaskAction(
        { deal_room: Pathid },
        respone => {
          console.log('respone', respone);
          updateActionItems({
            actionTasks: respone?.results,
            actionTaskDetails: respone,
          });
        },
        error => {
          console.log(error);
        },
      ),
    );
  }, [
    Pathid,
    actionItems?.addedActionItemSuccess,
    actionItems?.addedActionItemFileSuccess,
    actionItems?.addTaskSuccesfully,
    actionItems?.taskstatusChangesResponse,
   actionItems?.taskupdatedModalSuccessfully,
  ]);
  return (
    <ActionItem
      actionItems={actionItems}
      updateActionItems={updateActionItems}
      redirectSection={redirectSection}
    />
  );
};
