/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  addSectionAction,
  createTaskAction,
  uploadFileAction,
  editSection,
  updateTasks,
} from '../../react-shared/src/Actions/DealsActions';
import { linkFileAction } from '../../react-shared/src/Actions/fileManager';

import { Row, Col } from 'react-bootstrap';
import { Button } from '../../atoms/Button';
import { SectionForm } from './SectionForm';
import TaskForm from './CreateTasks';
import UploadFiles from './UploadFiles';
import InviteSection from './invite';
import { isEmptyObject, isEmptyArray } from '../../common/utils';

export const getTaskStatus = task => {
  const { id, ...rest } = task;
  return {
    ...rest,
    due_date: new Date(rest.due_date).toISOString().split('T')[0],
    status: rest?.status ? 'C' : 'O',
  };
};

const updateAndSeparate = (oldArr, newArr) => {
  const oldIds = new Set(oldArr?.map(item => item.id));
  const updatedOldArray = oldArr?.map(item => {
    const newItem = newArr?.find(newItem => newItem?.id === item?.id);
    return newItem ? { ...item, ...newItem } : item;
  });

  const newIdsArray = newArr?.filter(item => !oldIds.has(item.id)) || [];

  return { updatedOldArray, newIdsArray };
};

export const AddNewSection = props => {
  const {
    dealRoomDetails,
    updateDealRoomDetails,
    idFromPanel,
    editMode = false,
    openModal = false,
    handleClose,
  } = props;

  const [modalShow, setModalShow] = useState(openModal);
  const [newSectionDetails, setnewSectionDetails] = useState({
    sectionCreateTaskResponse: {},
    taskResponse: {},
    sectionResponse: {},
    files: [],
    section: {},
    tasks: {},
    collaborators: [],
    sectionCreateResponse: {},
    pinnned: {},
  });

  const dispatch = useDispatch();
  const formRef = useRef();
  const fomrRef2 = useRef();

  const updateNewSectionDetails = useCallback(params => {
    setnewSectionDetails &&
      setnewSectionDetails(prevState => ({ ...prevState, ...params }));
  }, []);

  useEffect(() => {
    editMode && setModalShow(true);
  }, [editMode]);

  useEffect(() => {
    editMode &&
      updateNewSectionDetails({
        tasks: dealRoomDetails?.section_task?.map(
          ({ name, status, created_at, id }) => ({
            name,
            status: status?.code === 'C' ? true : false,
            due_date: created_at,
            id,
          }),
        ),
        Oldfiles: dealRoomDetails?.section_file
      });
  }, [editMode, dealRoomDetails]);

  useEffect(() => {
    console.log('filesupload', newSectionDetails);
    if (newSectionDetails?.sectionResponse) {
      if (
        newSectionDetails?.tasks ||
        newSectionDetails?.files ||
        newSectionDetails?.linkFile ||
        newSectionDetails?.collaborators
      ) {
        if (!isEmptyArray(newSectionDetails?.files)) {
          const formData = new FormData();
          formData.append(
            'deal_room',
            newSectionDetails?.sectionResponse?.deal_room,
          );
          formData.append('section', newSectionDetails?.sectionResponse?.id);
          newSectionDetails.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
          dispatch(
            uploadFileAction(formData, response => {
              updateDealRoomDetails({
                ...dealRoomDetails,
                sectionFileResponse: response,
              });
              handleClose();
              toast.success('File uploaded');
              updateNewSectionDetails({ files: [] });
            }),
          );
        }
        if (!isEmptyObject(newSectionDetails?.tasks)) {
          const formattedTasks = newSectionDetails?.tasks?.task?.map(
            ({ id, ...rest }) => ({
              ...rest,
              due_date: new Date(rest.due_date).toISOString().split('T')[0],
              status: rest.status ? 'C' : 'O',
            }),
          );
          if (editMode) {
            if(newSectionDetails?.tasks?.task) {
            const oldTask = dealRoomDetails?.section_task?.map?.(
              ({ name, status, created_at, id }) => ({
                name,
                status: status?.code,
                due_date: created_at,
                id,
              }),
            );
            const { updatedOldArray, newIdsArray } = updateAndSeparate(
              oldTask,
              newSectionDetails?.tasks?.task,
            );
            if (!isEmptyArray(updatedOldArray) || !isEmptyArray(newIdsArray)) {
              updatedOldArray?.map(task => {
                dispatch(
                  updateTasks(
                    {
                      id: task?.id,
                      params: getTaskStatus(task),
                    },
                    response => {
                      updateDealRoomDetails({
                        ...dealRoomDetails,
                        updatedTaskResponse: response,

                      });
                      handleClose();
                      updateNewSectionDetails({ tasks: {}, section: {}, });
                      toast.success('tasks updated uploaded');
                      // console.log('response task update', response);
                    },
                  ),
                );
              });
              if (!isEmptyArray(newIdsArray)) {
                const newArray = newIdsArray?.map(array => {
                  return getTaskStatus(array);
                });
                dispatch(
                  createTaskAction(
                    {
                      deal_room: dealRoomDetails?.deal_room,
                      section: dealRoomDetails?.id,
                      tasks: newArray,
                    },
                    response => {
                      updateNewSectionDetails({ tasks: {}, section: {}, });
                      updateDealRoomDetails({
                        ...dealRoomDetails,
                        updatedTaskResponse: response,
                      });
                      handleClose();
                      toast.success('tasks updated uploaded');
                    },
                  ),
                );
              }
            } else {
              updateDealRoomDetails({
                ...dealRoomDetails,
                updatedTaskResponse: {},
                section: {},
              });
              handleClose();
            }
            }
            else {
              updateDealRoomDetails({
                ...dealRoomDetails,
                updatedTaskResponse: {},
                section: {},
              });
              handleClose();
            }

          } else {
            dispatch(
              createTaskAction(
                {
                  deal_room: newSectionDetails?.sectionResponse?.deal_room,
                  section: newSectionDetails?.sectionResponse?.id,
                  tasks: formattedTasks,
                },
                response => {
                  updateDealRoomDetails({
                    ...dealRoomDetails,
                    sectionCreateTaskResponse: response,
                  });
                  handleClose();
                  toast.success('tasks uploaded');
                  updateNewSectionDetails({ tasks: [], section: {}, });
                },
              ),
            );
          }
        }
        if (!isEmptyArray(newSectionDetails?.collaborators)) {
          const userIds = newSectionDetails?.collaborators?.map(
            user => user.id,
          );
          dispatch(
            editSection(
              {
                params: { participants: userIds },
                id: newSectionDetails?.sectionResponse?.id,
              },
              response => {
                updateNewSectionDetails({ collaborators: [] });
                updateDealRoomDetails({
                  ...dealRoomDetails,
                  collaboratorsResponse: response,
                });
                handleClose();
                toast.success('Invited Collaborators');
              },
              error => {
                console.log('error', error);
              },
            ),
          );
        }
        if (!isEmptyArray(newSectionDetails?.linkFile)) {
          dispatch(
            linkFileAction(
              {
                section_id: newSectionDetails?.sectionResponse?.id,
                file_ids: newSectionDetails?.linkFile?.map(item => item.id),
              },
              response => {
                updateDealRoomDetails({
                  ...dealRoomDetails,
                  sectionFileResponse: response,
                });
                handleClose();
                toast.success('File uploaded');
                updateNewSectionDetails({ files: [] });
              },
            ),
          );
        }
      }
    }
  }, [newSectionDetails?.sectionResponse]);

  const createSection = async () => {
    const isValid = await formRef?.current?.triggerValidation();
    const isvalid2 = await fomrRef2?.current?.trigger();
    if (!isValid) {
      console.log('Validation failed');
      toast.error('Section name is Mandatory');
      return;
    }
    if (!isvalid2) {
      toast.error('fill task deatils');
      return;
    }
    if (isValid && newSectionDetails?.section) {
      if (editMode) {
        dispatch(
          editSection(
            {
              id: dealRoomDetails?.id,
              params: {
                name: newSectionDetails?.section?.name,
                notes: newSectionDetails?.section?.notes,
              },
            },
            response => {
              if (
                isEmptyObject(newSectionDetails?.tasks) &&
                isEmptyArray(newSectionDetails?.files) &&
                isEmptyArray(newSectionDetails?.collaborators) && 
                isEmptyArray(newSectionDetails?.linkFile)
              ) {
                updateDealRoomDetails({
                  ...dealRoomDetails,
                  sectionCreateResponse: response,
                });
                handleClose();
                toast.success('Section created');
                updateNewSectionDetails({
                  section: {},
                  tasks: {},
                });
              } else {
                updateNewSectionDetails({
                  ...newSectionDetails,
                  sectionResponse: response,
                });
              }
            },
            error => {
              console.log('error', error);
            },
          ),
        );
      } else {
        dispatch(
          addSectionAction(
            {
              name: newSectionDetails?.section?.name,
              notes: newSectionDetails?.section?.notes,
              deal_room: idFromPanel,
            },
            response => {
              if (
                isEmptyObject(newSectionDetails?.tasks) &&
                isEmptyArray(newSectionDetails?.files) &&
                isEmptyArray(newSectionDetails?.collaborators) && 
                isEmptyArray(newSectionDetails?.linkFile)
              ) {
                updateDealRoomDetails({
                  ...dealRoomDetails,
                  sectionCreateResponse: response,
                });
                handleClose();
                toast.success('Section created');
                updateNewSectionDetails({
                  section: {},
                });
              } else {
                updateNewSectionDetails({
                  ...newSectionDetails,
                  sectionResponse: response,
                });
              }
            },
          ),
        );
      }
    }
  };

  const getSectionDetails = e => {
    const { name, value } = e.target;
    updateNewSectionDetails({
      ...newSectionDetails,
      section: {
        ...newSectionDetails?.section,
        [name]: value,
      },
    });
  };

  const getTaskValues = task => {
    updateNewSectionDetails({
      ...newSectionDetails,
      tasks: {
        task,
      },
    });
  };

  const handleFieldChange = ({updatedFiles, filesFromDocument}) => {
    console.log('updatedFiles', updatedFiles, filesFromDocument);
    updateNewSectionDetails({
      ...newSectionDetails,
      files : updatedFiles,
      linkFile: filesFromDocument,
    });
  };

  const handleCollaborators = user => {
    updateNewSectionDetails({
      ...newSectionDetails,
      collaborators: user,
    });
  };

  return (
    <>
      <Modal
        show={openModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='addsection-modal'
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {editMode ? `Edit ${dealRoomDetails?.name}` : 'Add New Section'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey={'Section'}
            id="fill-tab-example"
            className="creactSectionTabs"
            fill>
            <Tab eventKey="Section" title="Section">
              <div className="newSectionContainer">
                <SectionForm
                  getSectionDetails={getSectionDetails}
                  formRef={formRef}
                  dealRoomDetails={dealRoomDetails}
                  editMode ={editMode}
                />
              </div>
            </Tab>
            <Tab eventKey="Tasks" title="Tasks">
              <div>
                <TaskForm
                  newSectionDetails={newSectionDetails}
                  updateNewSectionDetails={updateNewSectionDetails}
                  getTaskValues={getTaskValues}
                  ref={fomrRef2}
                />
              </div>
            </Tab>
            <Tab eventKey="Files" title="Files">
              <div>
                <UploadFiles
                  newSectionDetails={newSectionDetails}
                  updateNewSectionDetails={updateNewSectionDetails}
                  handleFieldChange={handleFieldChange}
                />
              </div>
            </Tab>
            <Tab eventKey="Collaborators" title="Collaborators">
              <div>
                <InviteSection
                  newSectionDetails={dealRoomDetails}
                  updateNewSectionDetails={updateNewSectionDetails}
                  handleCollaborators={handleCollaborators}
                  editMode={editMode}
                />
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md={6}>
              <Button
                varient="secondary"
                text={'Cancel'}
                type="button"
                onClickHandler={handleClose}>
                Close
              </Button>
            </Col>
            <Col md={6}>
              <Button
                varient="primary"
                text={'Save'}
                type="button"
                // tabName={key}
                onClickHandler={createSection}>
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
