import React from "react";
import './styles.scss';
export const LoginFooter = () => {
  return (
    <div className='footer-container d-flex justify-content-center'>
          <ul>
            <li className="brand">© 2024 Oppanda</li>
            <li>Terms of Service</li>
            <li>Privacy Policy</li>
            <li>Support</li>
          </ul>
        </div>
  )
}