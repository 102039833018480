/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import { fileTypes, getFormatedDate, imageTextWrapper} from '../../common/utils';
import { FileIcon } from '@drawbotics/file-icons';
import '@drawbotics/file-icons/dist/style.css';
import profilePicture from '../../images/Profile-Picture.png';
import './styles.scss';

export const SectionFileDetails = props => {
  const { item = {} } = props;
  console.log('itemmmmms', fileTypes(item?.mime_extension), item);
  const handleView = async() => {
    const fileUrl = item?.file;
  const fileName = item?.name;

  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  };
  const handleFileView = () => {
    window?.open(item?.file, '_blank');
  }
  return (
    <div className="fileDisplay-wrapper">
      <div className="file-name" onClick={handleFileView}>
      <FileIcon file={item?.mime_extension} />
        {/* <img src={fileTypes(item?.mime_extension)} /> */}
        {/* {item?.mime_extension} */}
        {item?.name}
      </div>
      <div className="fileDetails">
        <div className="time-owner">
          {getFormatedDate(item?.last_mod_date)}
          {imageTextWrapper(item?.owner)}
          {/* <img src={profilePicture} /> */}
        </div>
        <div className="more-options">
          <span className="material-symbols-rounded" onClick={handleView}>download</span>
        </div>
        <div className="more-options">
          <span className="material-symbols-rounded">more_horiz</span>
        </div>
      </div>
    </div>
  );
};
