/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Modal, Row, Col } from 'react-bootstrap';
import { Button } from "../../../atoms/Button";
// import { useForm } from 'react-hook-form';
import EditSectionForm from '../Forms/EditSectionForm';
import './styles.scss';

export const EditNotesTemplate = (props) => {
  console.log('props', props);

  const { updateDealRoomDetails, dealRoomDetails, modalShow, handleClose } = props;
  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="editMotes-modal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditSectionForm
          updateDealRoomDetails={updateDealRoomDetails}
          dealRoomDetails={dealRoomDetails}
          handleClose={handleClose}
          // setUpdatedNotes={setUpdatedNotesHandler}
        />
      </Modal.Body>
      {/* <Modal.Footer>
          <Row>
            <Col md={6}>
              <Button
                varient="secondary"
                text={'Cancel'}
                type="button"
                onClickHandler={handleClose}>
                Close
              </Button>
            </Col>
            <Col md={6}>
              <Button
                varient="primary"
                text={'Save'}
                type="button"
                // tabName={key}
                // onClickHandler={createSection}
                >
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Footer> */}
    </Modal>
  );
}