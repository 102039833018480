/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Navbar,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { imageTextWrapper } from '../../common/utils';
import signoutIcon from '../../images/signout.svg';
import Logo from '../../images/dashboard.logo.svg';
import { signOutAction } from '../../react-shared/src/Actions/login';

export const NavBar = props => {
  const dispatch = useDispatch();

  const { text = '', backarrowShow = '', owner = false, onBack } = props;
  const [isAtTop, setIsAtTop] = useState(true);
  const navigate = useNavigate();
  const getUserInfo = useSelector(state => state.loginReducer?.UserDetails);
  // const getDealdetails = useSelector(state => state.loginReducer?.UserDetails?.user)

  const redirectToDealPage = () => {
    navigate(`/dealroom`);
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };
  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const Pathid = pathname.split('/')[2];


  const handleScroll = () => {
    // Check if the user is at the top of the page or a specific element
    console.log('window.scrollY', window.scrollY);
    if (window.scrollY === 0) {
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }
  };

  useEffect(() => {
    // Add scroll event listener on component mount
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar className={`sticky-top stick-header ${isAtTop ? 'isAtTopScroll' : ''}`}>
      <Row className="navbar-panel">
        {!owner && Pathid && (
          <Col md={2} className="collbarator-logs">
            <img
              src={Logo}
              alt="Logo"
              title="Logo"
              onClick={redirectToDealPage}
            />
          </Col>
        )}
        <Col md={Pathid && !owner ? 8 : 10}>
          <div className="navbar-panel--col1">
            <h2>
              {backarrowShow && owner && (
                <span
                  className="material-symbols-rounded"
                  onClick={!onBack ? redirectToDealPage : onBack}>
                  arrow_back
                </span>
              )}
              {text ? text : 'Deal Room'}
            </h2>
          </div>
        </Col>
        <Col md={2}>
          <div className="navbar-panel--col2">
            <Dropdown className="nav-bar-custom-dropdown">
              <DropdownToggle className="custom-button-dropdown-toggle">
                <ul className="text-end">
                  <li>{imageTextWrapper(getUserInfo)}</li>
                  <li className="text-start">
                    <p>
                      {getUserInfo?.first_name} {getUserInfo?.last_name}
                    </p>
                    <p>Relia Software</p>
                  </li>
                </ul>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handleSignOut}>
                  <div className="sign-out-dropdown-item">
                    <img src={signoutIcon} alt="signout" />
                    <div>Sign Out</div>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Navbar>
  );
};
