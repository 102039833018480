export const creatDealFormComponets = ({ OnChange, submit, defaultValues}) => {
  const dealName = {
    type: 'input',
    labelRequired: true,
    label: 'Deal Name',
    name: 'dealName',
    placeholder: 'Enter deal name',
    rules: {
      required: {
        value: true,
        message: 'Deal Name is required',
      },
    },
    defaultValue: defaultValues?.name,
    onChange: OnChange,
    onSubmit: submit,
  };
  const clientName = {
    type: 'input',
    labelRequired: true,
    label: 'Client',
    name: 'Client',
    placeholder: 'Enter Client',
    rules: {
      required: {
        value: true,
        message: 'Client Name is required',
      },
    },
    defaultValue: defaultValues?.client,
    onChange: OnChange,
    onSubmit: submit,
  };
  const description = {
    type: 'textarea',
    labelRequired: true,
    label: 'Deal Brief',
    name: 'Dealbrief',
    placeholder: 'Type deal description here',
    rules: {
      required: {
        value: true,
        message: 'Deal Description is required',
      },
      maxLength: {
        value: 300,
        message: 'max length should be 300',
      }
    },
    defaultValue: defaultValues?.description,
    onChange: OnChange,
    onSubmit: submit,
  };
  return [dealName, clientName, description];
};
