/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import { downLoadOrView } from '../../../molecules/DownloadAndView/Download';
import { AddFileTemplate } from './AddFileTemplate';
import profilePicture from '../../../images/Profile-Picture.png';
import {SectionFileDetails} from '../../../molecules/SectionFileDetails'

export const FileSection = props => {
  const { filesDate = [], DontShowCount = false, dealRoomDetails, updateDealRoomDetails} = props;
  const [modalShow, setModalShow] = useState(false);
  const addNewfileHandler = () => {
    setModalShow(true);
  };
  const handleClose = () => {
    setModalShow(false);
  };
  return (
    <div className='section-details-files-wrapper'>
      {!DontShowCount && (
        <p className="filecount">
          <span className="material-symbols-rounded">folder</span>
          {filesDate?.length > 0 && (
            <span className="count">{filesDate?.length}</span>
          )}
          Files{' '}
          
          <span className="addfile"><button onClick={addNewfileHandler} type='button'>Add file</button></span>
        </p>
      )}

      <div className="file-list-wrapper">
      {filesDate?.map((item, index) => {
       return (<SectionFileDetails item = {item} key={index}/>)
      })}
      </div>
      <AddFileTemplate updateDealRoomDetails={updateDealRoomDetails}
        dealRoomDetails={dealRoomDetails}
        handleClose={handleClose}
        modalShow={modalShow}/>
    </div>
  );
};
