/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from 'react-bootstrap';
import './style.scss';

export const SectionWrapper = ({ className = '', children }) => {
  return (
    <Container fluid className={`section-wrapper ${className}`}>
      {children}
    </Container>
  );
};
