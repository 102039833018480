import {GET_ACTION_ITEM_LIST, ADD_ACTION_ITEM, UPDATE_ACTION_ITEMS} from '../Constants/constants'

export const getAllActionItemsAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  return {
    type: GET_ACTION_ITEM_LIST,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const AddActionItemAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: ADD_ACTION_ITEM,
    payload: payload,
    successCallback,
    errorCallback,
  };
}

export const updateActionItemAction = (
  payload,
  successCallback = {},
  errorCallback = {},
) => {
  console.log('payload', payload);
  return {
    type: UPDATE_ACTION_ITEMS,
    payload: payload,
    successCallback,
    errorCallback,
  };
}