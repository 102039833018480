/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { DealCard } from '../../molecules/DealCard';
import { Row, Col } from 'react-bootstrap';
import {NoDealHeader} from '../../organisms/NoDealPanel';

export const DealList = props => {
  const { dealList = {}, updateDealList} = props;
  const [searchTerm, setSearchTerm] = useState();
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = event => {
    setSelectedStatus(event.target.value);
  };
  // search function
  const filteredData = searchTerm
    ? dealList?.ListofDeals?.results?.filter(item =>
        item.name.toLowerCase().includes(searchTerm?.toLowerCase()),
      )
    : dealList?.ListofDeals?.results;

  // sort function
  const sortData = selectedStatus
    ? filteredData?.filter(item => item?.status?.code == selectedStatus)
    : filteredData;

  const cleartext = () => {
    setSearchTerm('');
  };
  return (
    <>
      <Row>
        <Col md={4}>
          <div className="dealSearch">
            <span className="material-symbols-rounded">search</span>
            <input
              placeholder="Find anything here"
              value={searchTerm}
              onChange={handleSearch}
            />
            {searchTerm && (
              <span
                className="material-symbols-rounded clear"
                onClick={cleartext}>
                cancel
              </span>
            )}
          </div>
        </Col>
        <Col md={8}>
          <div className="deal-sort-conatiner">
            <div className="deal-sort">
              <span className="material-symbols-rounded">sort</span>
              <select
                className="form-select"
                aria-label="Default select example"
                value={selectedStatus}
                onChange={handleStatusChange}>
                <option selected value="">
                  All Status
                </option>
                <option value="A">Active</option>
                <option value="C">Completed</option>
                <option value="B">Inactive</option>
                {/* <option value="3">Three</option> */}
              </select>
            </div>
            {/* Will include filter later */}
            {/* <div className="deal-sort">
              <span className="material-symbols-rounded">tune</span>
              <select className="form-select" aria-label="Default select example">
                <option selected>Filter</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div> */}
          </div>
        </Col>
      </Row>
      <Row>
        {
          sortData.length === 0 && <NoDealHeader {...[{
            text: ''
          }]}/>
        }
        {sortData?.map((item, index) => {
          return (
            <Col md={4} key={index} className='align-items-stretch'>
              <DealCard item = {item} updateDealList={updateDealList}/>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
