import React from 'react';

import { Login } from '../../organisms/Auth/Container/Login';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../images/main-logo.svg';
import MainHeader from '../../organisms/MainHeader';
import {LoginFooter} from '../../molecules/LoginFooter'
import './styles.scss';

// import loginHero from '../../images/loginhearo.png';

export const LoginComponent = props => {
  const screenHeight = window.innerHeight;

  return (
    <>
      <MainHeader />
      <div
        className=" auth-body d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}>
        <div className="d-flex justify-content-center align-items-center authWrapper">
          <div className="auth-container">
            <div className='auth-header'>
              <h1>Welcome to Oppanda</h1>
              <p>
                Your digital hub to streamline your sales process and
                connect with clients effortlessly.
              </p>
            </div>
            <Login />
          </div>
          <p className='check-invite'>Looking for a deal room that you were invited? <span>Click here</span></p>
        </div>
        <LoginFooter />
      </div>
    </>
  );
};
