import {
  CREAT_FOLDER,
  CREAT_FOLDER_FAILURE,
  CREAT_FOLDER_SUCCESS,
  DELETE_DOCUMENT_FILE,
  DELETE_DOCUMENT_FILE_FAILURE,
  DELETE_DOCUMENT_FILE_SUCCESS,
  GET_FILE_DETAIL,
  GET_FILE_DETAIL_FAILURE,
  GET_FILE_DETAIL_SUCCESS,
  GET_FILE_LIST,
  GET_FILE_LIST_FAILURE,
  GET_FILE_LIST_SUCCESS,
  GET_FOLDER_DETAIL,
  GET_FOLDER_DETAIL_FAILURE,
  GET_FOLDER_DETAIL_SUCCESS,
  GET_FOLDER_LIST,
  GET_FOLDER_LIST_FAILURE,
  GET_FOLDER_LIST_SUCCESS,
  UPDATE_DOCUMENT_FILE,
  UPDATE_DOCUMENT_FILE_FAILURE,
  UPDATE_DOCUMENT_FILE_SUCCESS,
  UPDATE_FOLDER,
  UPDATE_FOLDER_FAILURE,
  UPDATE_FOLDER_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  LINK_FILE,
  LINK_FILE_SUCCESS,
  LINK_FILE_FAILURE,
  LINK_FILE_TASK,
  LINK_FILE_TASK_SUCCESS,
  LINK_FILE_TASK_FAILURE,
} from '../Constants/constants';

const initialState = {
  loading: false,
  folderData: {},
  fileData: {},
};
const fileManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREAT_FOLDER:
    case GET_FOLDER_LIST:
    case GET_FILE_LIST:
    case UPDATE_FOLDER:
    case UPLOAD_FILE:
    case GET_FOLDER_DETAIL:
    case GET_FILE_DETAIL:
    case DELETE_DOCUMENT_FILE:
    case UPDATE_DOCUMENT_FILE:
    case LINK_FILE:
    case LINK_FILE_TASK:
      return {
        ...state,
        loading: true,
      };
    case CREAT_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREAT_FOLDER_FAILURE:
      return {
        ...state,
        loading: false,
        folderData: action?.data,
      };
    case GET_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        folderData: action?.data,
      };
    case GET_FOLDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        folderData: action?.data,
      };
    case GET_FILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        fileData: action?.data,
      };
    case GET_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        fileData: action?.data,
      };
    case UPDATE_FOLDER_SUCCESS:
      console.log('action?.data: ', action?.data);
      const temp = [...state?.folderData?.results];
      const idx = temp?.findIndex(f => f.id === action?.data?.id);

      if (idx !== -1) {
        const updatedFolder = {
          ...temp[idx],
          name: action?.data?.name,
          description: action?.data?.description,
        };
        temp[idx] = updatedFolder;
      }

      const newFolderData = { ...state?.folderData, results: [...temp] };
      return {
        ...state,
        folderData: newFolderData,
        loading: false,
      };
    case UPDATE_FOLDER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_FOLDER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_FOLDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_FILE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_FILE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_DOCUMENT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_DOCUMENT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LINK_FILE_SUCCESS: 
    return {
      ...state,
      loading: false,
    };
    case LINK_FILE_FAILURE: 
    return {
      ...state,
      loading: false,
    };
    case LINK_FILE_TASK_SUCCESS: 
    return {
      ...state,
      loading: false,
    };
    case LINK_FILE_TASK_FAILURE: 
    return {
      ...state,
      loading: false,
    };
    default:
      return state;
  }
};
export default fileManagerReducer;
