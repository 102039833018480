/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteDocumentFileAction } from '../../react-shared/src/Actions/fileManager';
import { ToastMessage } from '../../atoms/Toast';
import { AddDocuments } from './Forms/AddDocuments';

const UploadFiles = props => {
  const { handleFieldChange, newSectionDetails } = props;
  const [show, setShow] = useState(false);
  const [filesFromDocument, SetFilesFromDocument] = useState([]);

  const { register } = useForm();
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [oldFilesList, setOldFilesList] = useState([]);

  console.log('filesFromDocument', filesFromDocument);

  const setAddDocumentHandler = () => {
    setShow(true);
  };

  const handleCloseDocument = () => {
    setShow(false);
  };

  // Loading uploaded files in edit mode
  useEffect(() => {
    newSectionDetails?.Oldfiles && setOldFilesList(newSectionDetails?.Oldfiles);
  }, [newSectionDetails?.Oldfiles]);

  // File upload on change function
  const handleFileChange = event => {
    const selectedFiles = Array.from(event.target.files);
    const updatedFiles = [...files, ...selectedFiles];
    setFiles(updatedFiles);
    handleFieldChange({updatedFiles, filesFromDocument});
  };
  useEffect(() => {
    handleFieldChange({
      filesFromDocument
    })
  },[filesFromDocument])
  // Removing newly uploaded file from view
  const handleRemoveFile = index => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    handleFieldChange(updatedFiles);
  };

  // After sucesscallback removing deleted file from view
  const handleOldRemoveFile = index => {
    const updatedFiles = oldFilesList.filter((_, i) => i !== index);
    setOldFilesList(updatedFiles);
  };

  // Dispatch Deleting file
  const handleDeleteFile = (id, index) => {
    dispatch(
      deleteDocumentFileAction(
        {
          id,
        },
        response => {
          ToastMessage({
            success: true,
            message: 'File deleted successfully',
          });
          handleOldRemoveFile(index);
          console.log('response', response);
        },
      ),
      error => {
        ToastMessage({
          success: false,
          message: 'something went wrong please try again',
        });
      },
    );
  };

  // File Preview Section
  const renderFilePreview = (file, index, todelete = false) => {
    const fileType = todelete ? file?.name : files?.type?.split?.('/')?.[0];
    return (
      <Row className="downloadfiles" key={index}>
        <Col md={2} className="text-center">
          <span className="material-symbols-rounded">image</span>
        </Col>
        <Col md={7}>
          <p>{file?.name}</p>
          <p className="size">
            {todelete
              ? file?.size_in_mb
              : (file?.size / (1024 * 1024)).toFixed(2)}{' '}
            MB
          </p>
        </Col>
        <Col md={3} className="text-center">
          {todelete ? (
            <span
              className="material-symbols-rounded"
              onClick={() => handleDeleteFile(file?.id, index)}>
              delete
            </span>
          ) : (
            <span
              className="material-symbols-rounded"
              onClick={() => handleRemoveFile(index)}>
              delete
            </span>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className='preview-conatiner'>
        <Row className="file-preview">
          {filesFromDocument?.map((file, index) => (
            <Col key={index} md={6}>
              {renderFilePreview(file, index)}
            </Col>
          ))}
        </Row>
        <Row className="file-preview">
          {oldFilesList?.map((file, index) => (
            <Col key={index} md={6}>
              {renderFilePreview(file, index, true)}
            </Col>
          ))}
          {files.map((file, index) => (
            <Col key={index} md={6}>
              {renderFilePreview(file, index)}
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <form>
          <div className="fileuploadButton">
            <input
              type="file"
              name="files"
              id="actual-btn"
              {...register('files', { required: false })}
              multiple
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <label htmlFor="actual-btn" className="upload-label">
              <span className="material-symbols-rounded">upload</span>
              Upload File
            </label>
          </div>
        </form>
        <div className="uploadDocuments">
          <button type="button" onClick={setAddDocumentHandler}>
            <span className="material-symbols-rounded">folder_open</span>
            Choose From Your Documents
          </button>
        </div>
      </div>
      <AddDocuments
        handleClose={handleCloseDocument}
        modalShow={show}
        SetFilesFromDocument={SetFilesFromDocument}
        filesFromDocument={filesFromDocument}
      />
    </>
  );
};

export default UploadFiles;
