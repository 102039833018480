export const APPURL = process.env.REACT_APP_API_URL;
// export const API_URL = process.env.REACT_APP_API_URL;

import axios from 'axios';
import { store } from './store';
import { signOutAction } from './Actions/login';

export const apiConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiConfig.interceptors.request.use(
  config => {
    const state = store.getState();
    const token = state.loginReducer?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

apiConfig.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Handle response errors
    if (error.response) {
      const statusCode = error.response.status;

      if (statusCode === 404) {
        console.error('Resource not found');
      } else if (statusCode === 401) {
        console.error('Unauthorized');
        store.dispatch(signOutAction());
      } else {
        console.error(`Server responded with status code: ${statusCode}`);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received');
    } else {
      // Something happened in setting up the request
      console.error('Error setting up the request', error.message);
    }

    return Promise.reject(error);
  },
);
