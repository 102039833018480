import { all, fork } from 'redux-saga/effects';

import { watchGetUser, watchjoinDealSaga, watchResetPasswordSaga} from './loginSaga';
import {
  watchGetDealList,
  watchNewDealCreate,
  watchEachGetDeal,
  watchGetSection,
  watchNewSection,
  watchNewFileUpload,
  watchTaskCompletedSaga,
  watchTaskListSaga,
  watchcreateTaskSaga,
  watchupdateDealSaga,
  watchGetPinnedSaga,
  watchaddPinedSaga,
  watchInviteCollaboratorsSaga,
  watcheditSectionSaga,
  watchupdateTaskSaga,
  watchudeleteFileSaga,
  watchRemoveCollaboratorSaga,
  watchGetCollaboratorSaga,
  watchGetSingleSectionSaga,
} from './dealsSaga';


import {
  watchpostCommentSaga,
  watchgetCommentsSaga,
  watcheditCommentSaga,
} from './commentSaga';

import {
  watchCreateFolder,
  watchDeleteDocumentFile,
  watchGetFileDetail,
  watchGetFileList,
  watchGetFolderDetail,
  watchGetFolderList,
  watchUpdateDocumentFile,
  watchUpdateFolder,
  watchUploadFile,
  watchLinkFile,
  watchLinkFileToTask
} from './fileManagerSage';

import {
  watchgetAllTaskSaga,
  watchgetTaskCommentsSaga,
  watchaddTaskCommentsSaga,
  watcheditTaskCommentsSaga,
  watchaddTaskFileSaga,
} from './taskSaga';
import {
  watchgetAllActionItemsSaga,
  watchgaddActionItemsSaga,
  watchupdateActionItemsSaga,
} from './actionItemSaga';

import { watchGetDashboardActivity } from './dashboardSaga';

const rootSaga = function* () {
  yield all([
    fork(watchGetUser),
    fork(watchGetDealList),
    fork(watchNewDealCreate),
    fork(watchEachGetDeal),
    fork(watchGetSection),
    fork(watchNewSection),
    fork(watchNewFileUpload),
    fork(watchTaskCompletedSaga),
    fork(watchTaskListSaga),
    fork(watchcreateTaskSaga),
    fork(watchpostCommentSaga),
    fork(watchgetCommentsSaga),
    fork(watchupdateDealSaga),
    fork(watchInviteCollaboratorsSaga),
    fork(watchGetPinnedSaga),
    fork(watchaddPinedSaga),
    fork(watcheditSectionSaga),
    fork(watchupdateTaskSaga),
    fork(watchudeleteFileSaga),
    fork(watcheditCommentSaga),
    fork(watchjoinDealSaga),
    fork(watchCreateFolder),
    fork(watchGetFolderList),
    fork(watchGetFileList),
    fork(watchgetAllTaskSaga),
    fork(watchgetAllActionItemsSaga),
    fork(watchgaddActionItemsSaga),
    fork(watchgetTaskCommentsSaga),
    fork(watchaddTaskCommentsSaga),
    fork(watcheditTaskCommentsSaga),
    fork(watchaddTaskFileSaga),
    fork(watchUpdateFolder),
    fork(watchUploadFile),
    fork(watchGetFolderDetail),
    fork(watchGetFileDetail),
    fork(watchupdateActionItemsSaga),
    fork(watchDeleteDocumentFile),
    fork(watchUpdateDocumentFile),
    fork(watchLinkFile),
    fork(watchRemoveCollaboratorSaga),
    fork(watchGetCollaboratorSaga),
    fork(watchGetSingleSectionSaga),
    fork(watchLinkFileToTask),
    fork(watchGetDashboardActivity),
    fork(watchResetPasswordSaga),
    // Other forks
  ]);
};

export default rootSaga;
