/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Col, Row, Form } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import {createTaskAction} from '../../../react-shared/src/Actions/DealsActions';
import { useDispatch } from 'react-redux';
import {ToastMessage} from '../../../atoms/Toast';
const defaultTasks = [
  {
    id: 1,
    name: '',
    section: '',
  },
];
export const getSectionOptions = (list) => {
  return list?.map(section => (
    <option key={section.id} value={section.id}>
      {section.name}
    </option>
  ));
};
const AddTaskForm = (props) => {
  const{actionItems, updateActionItems, handleClose, SectionList, dealRoomDetails} = props;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasks: defaultTasks,
    },
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });
  const filterTask = (task) => {
  const { id, section, taskName, ...rest } = task;
  return {
    ...rest,
    // name: rest?.taskName,
    // due_date: new Date(rest.due_date).toISOString().split('T')[0],
    // status: rest?.status ? 'C' : 'O',
  };
  }
  const onSubmit = data => {
    setLoading(true)
    const dataArray = data?.tasks?.map((item) => {
      return ( {
        deal_room: dealRoomDetails?.results?.[0]?.deal_room,
        section: item?.section,
        tasks: [filterTask(item)],
        }
      )
    })
    dataArray.map(item => {
      dispatch(createTaskAction({
        ...item,
      }, (response) => {
        updateActionItems({
          addTaskSuccesfully: response,
        })
        ToastMessage({
          success: true,
          message: 'Task upload sucessfully',
        });
        setLoading(false);
        handleClose();
        console.log('response', response);
      }, errors => {
        ToastMessage({
          error: true,
          message: 'Something went wrong, Please try again',
        });
        setLoading(false);
        console.log('error', errors);
      }))
    })
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => (
        <Row key={item.id} className="taskContainer">
          <Col md={5}>
            <label htmlFor={`tasks.${index}.name`}>Task Name</label>
            <input
              {...register(`tasks.${index}.name`, {
                required: 'Task Name is required',
              })}
              placeholder="Task Name"
              defaultValue={item.taskName}
            />
            {errors.tasks?.[index]?.name && (
              <p className="error">{errors.tasks[index].name.message}</p>
            )}
          </Col>

          <Col md={5}>
            <label htmlFor={`tasks.${index}.section`}>Section</label>
            <Form.Select
              {...register(`tasks.${index}.section`, {
                required: 'Section is required',
              })}
              defaultValue={item.section}
              className='formSelect'
            >
               <option value="">Select a section</option>
              {SectionList && getSectionOptions(SectionList)}
              {/* <option value="">Select a section</option>
              <option value="section1">Section 1</option>
              <option value="section2">Section 2</option>
              <option value="section3">Section 3</option> */}
            </Form.Select>
            {errors.tasks?.[index]?.section && (
              <p className="error">{errors.tasks[index].section.message}</p>
            )}
          </Col>

          <Col md={2} className="text-center">
          <button
              type="button"
              className="deletebutton-task"
              onClick={() => remove(index)}>
              <span className="material-symbols-rounded">delete</span>
            </button>
            {/* <button type="button" onClick={() => remove(index)}>
              Delete
            </button> */}
          </Col>
        </Row>
      ))}
      <button
        type="button"
        className="addTasks"
        onClick={() =>
          append({
            id: Date.now(),
            name: '',
            section: '',
          })
        }>
        <span className="material-symbols-rounded">playlist_add</span> Add
        Fields
      </button>
      {/* <button
        type="button"
        onClick={() =>
          append({
            id: Date.now(),
            taskName: '',
            section: '',
          })
        }
      >
        Add Task
      </button> */}

      {/* // // <button type="submit" className="submitTasks">
      //   Submit
      // </button> */}
            <Row>
        <Col md={6}>
          <Button
            varient="secondary"
            text={'Cancel'}
            type="submit"
            onClickHandler={handleClose}
          >
            Close
          </Button>
        </Col>
        <Col md={6}>
          <Button
            varient="primary"
            text={'Save'}
            type="submit"
            loading = {loading}
            // tabName={key}
            // onClickHandler={createActionItemHandler}
          >
            add {}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default AddTaskForm;
