/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'react-bootstrap';
import './style.scss';

export const SectionTabs = ({ data, onActiveTab = () => {} }) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (onActiveTab) {
      onActiveTab(active);
    }
  }, [active, onActiveTab]);

  const toggle = tab => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  return (
    <>
      <Nav tabs className="nav-container">
        {data?.map(({ label, disabled }, index) => (
          <NavItem key={index} id={`tab-item-${index}`}>
            <NavLink
              active={active === index}
              onClick={() => {
                toggle(index);
              }}
              disabled={disabled}>
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent className="py-50">
        {data?.map(({ content }, index) => (
          <TabPane key={index} id={index} active={active === index}>
            {active === index ? content : null}
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};
