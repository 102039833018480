import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import taskReducer2 from './signinReducer';
import dealsReducers from './dealsReducer';
import commentReducer from './commentReducer';
import fileManagerReducer from './fileManagerReducer';
import taskReducer from './taskReducer';
import actionItemReducer from './actionItemReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  tasks2: taskReducer2,
  dealsReducers: dealsReducers,
  commentReducer: commentReducer,
  fileManagerReducer: fileManagerReducer,
  taskReducer: taskReducer,
  actionItemReducer: actionItemReducer,
  dashboardReducer: dashboardReducer,
});

export default rootReducer;
