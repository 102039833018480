import React from 'react';

import { TailSpin, Oval } from 'react-loader-spinner';

export const Loader = () => {
  return (
    <TailSpin
      visible={true}
      height="80"
      width="80"
      color="#0984E3"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass="align-center-loader"
    />
  );
};

export const ButtonLoader = () => {
  return (
    <Oval
  visible={true}
  height="30"
  width="30"
  color="#ffffff"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  strokeWidth= '3'
  strokeWidthSecondary = '3'
  />
  );
};
