import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { loginAction } from '../../../react-shared/src/Actions/login';
import { Button } from '../../../atoms/Button';
import { Navigate, useNavigate } from 'react-router-dom';
import googleLogin from '../../../images/Button.svg';

export const Login = props => {
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
    errorField: '',
    disabled: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginReducer = useSelector(state => state.loginReducer);

  const updateUserDetails = useCallback(params => {
    setUserDetails &&
      setUserDetails(prevState => ({ ...prevState, ...params }));
  }, []);
  useEffect(() => {
    userDetails?.loginResponse?.access && navigate('/dashboard');
  }, [userDetails?.loginResponse?.access]);

  const onclickSignIn = event => {
    dispatch(
      loginAction(
        {
          headers: false, 
          params: {
            email: userDetails?.email,
            password: userDetails?.password,
          }
        },
        Response => {
          localStorage.setItem('accessToken', Response?.access);
          localStorage.setItem('userInfo', JSON.stringify(Response.user));
          updateUserDetails({
            loginResponse: Response,
          });

          // const navigate = useNavigate();
          //   navigate('/dashboard');
        },
        error => {
          updateUserDetails({
            errorField: error,
          });
        },
      ),
    );
  };

  const onclickResetPassword = () => {
    navigate('/reset-password');
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onclickSignIn();
    }
  };
  const buttonList = [
    {
      text: 'Sign In',
      varient: 'primary',
      size: 12,
      type: 'button',
      onClickHandler: onclickSignIn,
      // handleKeyPress: handleKeyPress
    },
    {
      text: 'Reset Password',
      varient: 'secondary',
      size: 12,
      type: 'button',
      onClickHandler: onclickResetPassword,
    },
  ];

  const getFieldValue = event => {
    if (event.target.name === 'email') {
      updateUserDetails({
        ...userDetails,
        errorField: '',
        email: event.target.value,
      });
    }
    if (event.target.name === 'password') {
      updateUserDetails({
        ...userDetails,
        errorField: '',
        password: event.target.value,
      });
    }
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  if (loginReducer.token) {
    return <Navigate to="/dashboard" replace />;
  }

  // handleKeyPress = (event) => {
  //   if(event.key === 'Enter'){
  //     console.log('enter press here! ')
  //   }
  // }

  return (
    <>
      <Container className='form-wrapper'>
        <Row>
          <Form autoComplete="new-password">
            <Col md={12} className="inputGroup">
              <label>Email</label>
              <input
                data-testid="check-input-box"
                value={userDetails?.email}
                onChange={getFieldValue}
                placeholder={'Enter your email'}
                type={'email'}
                name={'email'}
                // autoComplete={"off"}
                autoComplete="false"
                className={userDetails?.errorField ? 'error' : ''}
              />
            </Col>
            <Col md={12} className="inputGroup">
              <label>password</label>
              <input
                data-testid="check-input-box"
                value={userDetails?.password}
                onChange={getFieldValue}
                placeholder={'Enter your password'}
                type={showPassword ? 'text' : 'password'}
                name={'password'}
                className={userDetails?.errorField ? 'error' : ''}
                autoComplete="false"
                onKeyPress={handleKeyPress} 
              />
              <div onClick={togglePassword} className='show-password'>
                {showPassword ? (
                  <span className="material-symbols-rounded passwordActive ">
                    visibility_off
                  </span>
                ) : (
                  <span className="material-symbols-rounded password">
                    visibility
                  </span>
                )}
              </div>
            </Col>
          </Form>
        </Row>

        <Button
          {...buttonList[0]}
          disabled={
            !(userDetails?.email && userDetails?.password) ||
            userDetails?.errorField
          }
          loading={loginReducer?.loading}
        />
        {userDetails?.errorField && (
          <p className="error text-center">
            Email and or password incorrect. Please try again or reset your
            password.
          </p>
        )}
        <p className='reset-link-wrapper'>
        Need help logging in? Try <span onClick={onclickResetPassword}>Reset password</span>
        </p>
      </Container>
      <img src={googleLogin} style={{marginTop: '15px'}}/>
    </>
  );
};
