/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import notFound from '../../images/notfound.svg';
import logo from '../../images/logo.svg';
import './style.scss'; 

export const NotFound = () => {
  const screenHeight = window.innerHeight;
  return (
    <>
    <div className='notfound'>
    <Link to = '/dashboard' className='link'><img src={logo} alt='logo'/> </Link>
    </div>
    <div className='not-foundconatiner' style={{ minHeight: `${screenHeight}px` }}>
      <img src= {notFound} alt='notFound' />
      <h1>Page not found</h1>
      <p>The page you were looking for couldn't be found. <br/>
        Go back to the <Link to = '/dashboard' className='link'>Dashboard </Link>. </p>
    </div>
     </>
  );
};

// export default NotFound;
