export const sectionConfig = ({ defaultValues, OnChange, submit }) => {
  const SectionName = {
    type: 'input',
    labelRequired: true,
    label: 'Section Name',
    name: 'name',
    placeholder: 'Give this section a name',
    rules: {
      required: {
        value: true,
        message: 'section Name is required',
      },
    },
    defaultValue: defaultValues?.name,
    onChange: OnChange,
    onSubmit: submit,
  };
  const Notes = {
    type: 'textarea',
    labelRequired: true,
    label: 'Notes',
    name: 'notes',
    placeholder: 'If there is any description',
    rules: {
      required: {
        value: false,
        message: 'notes is required',
      },
    },
    defaultValue: defaultValues?.notes,
    onChange: OnChange,
    onSubmit: submit,
  };
  return [SectionName, Notes];
};