/* eslint-disable react/prop-types */
import React, { useRef, useCallback, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  newDealAction,
  updateDealAction,
  dealListAction,
} from '../../react-shared/src/Actions/DealsActions';
import { useDispatch } from 'react-redux';
import { Button } from '../../atoms/Button';
import { Form } from '../../atoms/Form';
import { creatDealFormComponets } from './createDealUtils';
import { ToastMessage } from '../../atoms/Toast';

export const CreateDeal = props => {
  const {
    handleClose,
    show,
    editMode = false,
    setDealdeatils,
    dealDetails,
  } = props;

  const [newDealDetails, setNewDealDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();

  const updateNewDealDetails = useCallback(params => {
    setNewDealDetails(prevState => ({ ...prevState, ...params }));
  }, []);

  const getDealDetails = e => {
    const { name, value } = e.target;
    updateNewDealDetails({
      ...newDealDetails,
      [name]: value,
    });
  };

  const dealComponents = creatDealFormComponets({
    OnChange: getDealDetails,
    defaultValues: dealDetails,
  });

  const createProject = async edit => {
    const isValid = await formRef.current.triggerValidation();
    if (!isValid) {
      console.log('Validation failed');
      return;
    }
    setLoading(true);
    const dealData = {
      name: newDealDetails?.dealName,
      client: newDealDetails?.Client,
      description: newDealDetails?.Dealbrief,
      // pinned: 'N',
      // color: '',
      // participants: ['4', '5'], // Assuming these are static or predefined for now
    };
    const handleSuccess = response => {
      
      ToastMessage({
        success: true,
        message: editMode
          ? `Edited ${response?.name} sucessfully`
          : `Deal: ${response?.name} created sucessfully`,
      });
      setLoading(false);
      handleClose();
      editMode && setDealdeatils(response);
      navigate(`/deal/${response?.id}`, { state: response?.id });

    };
    const handleError = error => {
      setLoading(false);
      ToastMessage({
        success: false,
        message: 'Something went wrong Please try again'
      });
      console.log('Error:', error);
    };
    if (editMode) {
      dispatch(
        updateDealAction(
          {
            id: dealDetails?.id,
            params: {
              ...dealData,
              status: dealDetails?.status?.code, // Assuming status 'A' is a predefined status for edit
            },
          },
          handleSuccess,
          handleError,
        ),
      );
    } else {
      dispatch(newDealAction(dealData, handleSuccess, handleError));
    }
  };

  return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{`${editMode ? 'Edit' : 'New'} ${dealDetails?.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Deal Details</h3>
          <Form ref={formRef} requestedFields={dealComponents} />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md={6}>
              <Button
                varient="secondary"
                text={'Cancel'}
                type="button"
                onClick={handleClose}>
                Close
              </Button>
            </Col>
            <Col md={6}>
              <Button
                varient="primary"
                text={'Create Deal Room'}
                type="button"
                loading={loading}
                onClickHandler={createProject}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
  );
};
