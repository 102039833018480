/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Button } from '../../../atoms/Button';
import { uploadFileAction } from '../../../react-shared/src/Actions/DealsActions';
import { linkFileAction } from '../../../react-shared/src/Actions/fileManager';
import { ToastMessage } from '../../../atoms/Toast';
import { AddDocuments } from './AddDocuments';
// import './style.scss';


const AddFileForm = props => {
  const { updateDealRoomDetails, dealRoomDetails, handleClose } = props;
  const { register, handleSubmit, formState: { isSubmitting, isDirty } } = useForm();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [filesFromDocument, SetFilesFromDocument] = useState([]);
  const dispatch = useDispatch();

  const setAddDocumentHandler = () => {
    setShow(true);
  };

  const handleCloseDocument = () => {
    setShow(false);
  };

  const handleFileChange = event => {
    const selectedFiles = Array.from(event.target.files);
    const updatedFiles = [...files, ...selectedFiles];
    setFiles(updatedFiles);
  };

  const handleRemoveFile = (index, version) => {
    console.log('index', index);
    if(version) {
      const updatedFiles = filesFromDocument.filter((_, i) => i !== index);
      SetFilesFromDocument(updatedFiles)
    }
    else {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('deal_room', dealRoomDetails?.deal_room);
    formData.append('section', dealRoomDetails?.id);

    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    if (filesFromDocument.length > 0) {
      dispatch(linkFileAction({
        section_id: dealRoomDetails?.id,
        file_ids: filesFromDocument.map(item => item.id),
      }, response => {
        setLoading(false);
          ToastMessage({
            success: true,
            message: 'file added successfully',
          });
          updateDealRoomDetails({
            addedFileSuccess: response,
          });
          setFiles([]);
          handleClose();
          SetFilesFromDocument([]);
      }, error => {
        console.log('error', error);
        ToastMessage({
          error: true,
          message: 'Something went wrong, Please try again',
        });
        setLoading(false);
      }));
    }
    if(files.length > 0) {
    dispatch(
      uploadFileAction(
        formData,
        response => {
          setLoading(false);
          ToastMessage({
            success: true,
            message: 'file uploaded successfully',
          });
          updateDealRoomDetails({
            addedFileSuccess: response,
          });
          setFiles([]);
          handleClose();
        },
        errorMessage => {
          ToastMessage({
            error: true,
            message: 'Something went wrong, Please try again',
          });
          setLoading(false);
        },
      ),
    );
    }
  };
  const renderFilePreview = (file, index) => {
    const fileType = file.type.split('/')[0];
    console.log('filefile', file);
    return (
      <Row className="downloadfiles" key={index}>
        <Col md={2} className="text-center">
          <span className="material-symbols-rounded">image</span>
        </Col>
        <Col md={7}>
          <p>{file?.name}</p>
          <p className="size">{(file?.size / (1024 * 1024)).toFixed(2)} MB</p>
        </Col>
        <Col md={3} className="text-center">
          <span
            className="material-symbols-rounded"
            onClick={() => handleRemoveFile(index, file?.version)}>
            delete
          </span>
        </Col>
      </Row>
    );
  };
  const isSubmitDisabled = files.length === 0 && filesFromDocument.length === 0;

  return (
    <>
      <Row className="file-preview">
        {filesFromDocument?.map((file, index) => (
          <Col key={index} md={6}>
            {renderFilePreview(file, index)}
          </Col>
        ))}
        {files?.map((file, index) => (
          <Col key={index} md={6}>
            {renderFilePreview(file, index)}
          </Col>
        ))}
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="fileuploadButton">
          <input
            type="file"
            name="files"
            id="actual-btn"
            {...register('files')}
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <label htmlFor="actual-btn" className="upload-label">
            <span className="material-symbols-rounded">upload</span>
            Upload File
          </label>
        </div>
        <div className="uploadDocuments">
          <button type="button" onClick={setAddDocumentHandler}>
            <span className="material-symbols-rounded">folder_open</span>
            Choose From Your Documents
          </button>
        </div>
        <Row className='button-wrapper'>
          <Col md={6}>
            <Button
              varient="secondary"
              text={'Cancel'}
              type="button"
              onClickHandler={handleClose}
            >
              Close
            </Button>
          </Col>
          <Col md={6}>
            <Button
              varient="primary"
              text={'Save'}
              type="submit"
              loading={loading || isSubmitting}
              disabled={isSubmitDisabled || isSubmitting}
            >
              Save
            </Button>
          </Col>
        </Row>
      </form>
      <AddDocuments
        handleClose={handleCloseDocument}
        modalShow={show}
        SetFilesFromDocument={SetFilesFromDocument}
        filesFromDocument={filesFromDocument}
      />
    </>
  );
};

export default AddFileForm;
