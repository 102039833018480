/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { AddActionItemAction } from '../../../react-shared/src/Actions/ActionItemAction';
import { useDispatch } from 'react-redux';
import { ToastMessage } from '../../../atoms/Toast';
import Select from 'react-select'; // Add this for the select dropdown

const defaultTasks = [
  {
    id: 1,
    name: '',
    assignee: '',
    due_date: new Date(),
    description: '',
  },
];

const assigneeOptions = [
  { value: 5, label: 'Pavanuser3 (ID: 5)' },
  { value: 7, label: 'Pavanuser3 (ID: 7)' },
  { value: 8, label: 'Pavanuser3 (ID: 8)' },
];

const AddActionItemForm = forwardRef((props, ref) => {
  const { updateDealRoomDetails, taskDetails, handleClose} = props;
  const [loading, setLoading] = useState(false);
  const [numberActionItems, setActionItemCount] = useState(0);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasks: defaultTasks,
    },
  });

  useImperativeHandle(ref, () => ({
    trigger,
  }));

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });
  const dispatch = useDispatch();

  const onSubmit = data => {
    setLoading(true);
    const formattedActions = data?.tasks?.map(({ id, ...rest }) => ({
      ...rest,
      due_date: new Date(rest.due_date).toISOString().split('T')[0],
      assignee: rest?.assignee?.value,
    }));
    dispatch(
      AddActionItemAction(
        {
          task: taskDetails?.id,
          action_items: formattedActions,
        },
        response => {
          setLoading(false);
          ToastMessage({
            success: true,
            message: 'Action Items added',
          });
          updateDealRoomDetails({
            addedActionItemSuccess: response,
          });
          handleClose();
        },
        errorMessage => {
          ToastMessage({
            error: true,
            message: 'Something went wrong, Please try again',
          });
          setLoading(false);
          console.log(errorMessage);
        }
      )
    );
  };

  const handleFieldChange = () => {
    const tasks = getValues('tasks');
    setActionItemCount(tasks?.length);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='actionitems-wrapper'>
        {fields.map((item, index) => (
          <Row key={item.id} className="addTaskContainer addAction-item-container">
            <div className="l-shape"></div>

            <Col md={5}>
              <label htmlFor={`tasks.${index}.name`}>Action Item</label>
              <input
                {...register(`tasks.${index}.name`, {
                  required: 'Action Item is required',
                })}
                placeholder="Action Item"
                defaultValue={item.name}
                onChange={handleFieldChange}
              />
              {errors.tasks?.[index]?.name && (
                <p className="error">{errors.tasks[index].name.message}</p>
              )}
            </Col>

            <Col md={3}>
              <label htmlFor={`tasks.${index}.assignee`}>Assignee</label>
              <Controller
                name={`tasks.${index}.assignee`}
                control={control}
                rules={{ required: 'Assignee is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={assigneeOptions}
                    placeholder="Select Assignee"
                    className='assigneeSelect'
                    // style={{
                    //   border: '1px solid #a1a1a1',
                    //   borderRadius: '8px',
                    //   fontSize: '14px',
                    //   fontWeight: '400',
                    //   color: '#646464',
                    //   height: '48px',
                    //   width: '100%', // Ensure it takes the full width of the container
                    // }}
                    onChange={value => {
                      setValue(`tasks.${index}.assignee`, value);
                      handleFieldChange();
                    }}
                  />
                )}
              />
              {errors.tasks?.[index]?.assignee && (
                <p className="error">{errors.tasks[index].assignee.message}</p>
              )}
            </Col>

            <Col md={3}>
              <label htmlFor={`tasks.${index}.due_date`}>Due Date</label>
              <Controller
                control={control}
                name={`tasks.${index}.due_date`}
                defaultValue={item.due_date}
                rules={{ required: 'Due Date is required' }}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={date => {
                      setValue(`tasks.${index}.due_date`, date);
                      handleFieldChange();
                    }}
                  />
                )}
              />
              {errors.tasks?.[index]?.due_date && (
                <p className="error">{errors.tasks[index].due_date.message}</p>
              )}
            </Col>

            <Col md={1}>
              <button
                type="button"
                className="deletebutton"
                onClick={() => remove(index)}>
                <span className="material-symbols-rounded">delete</span>
              </button>
            </Col>

            <Col md={8} className="optional-description">
              <input
                {...register(`tasks.${index}.description`)}
                placeholder="Optional notes"
                defaultValue={item.description}
                onChange={handleFieldChange}
              />
              {errors.tasks?.[index]?.description && (
                <p className="error">{errors.tasks[index].description.message}</p>
              )}
            </Col>
          </Row>
        ))}
      </div>
      <div className='addbuttonaction-task '>

      
      <button
        type="button"
        className="addTasks"
        onClick={() => {
          append({
            id: Date.now(),
            name: '',
            assignee: '',
            due_date: new Date(),
            description: '',
          });
          handleFieldChange();
        }}>
        <span className="material-symbols-rounded">playlist_add</span> Add
        Fields
      </button>
      </div>
      <Row className='sticky-button'>
        <Col md={6}>
          <Button
            varient="secondary"
            text={'Cancel'}
            type="button"
            onClickHandler={handleClose}
            >
            Close
           
          </Button>
        </Col>
        <Col md={6}>
          <Button
            varient="primary"
            text={`Add (${numberActionItems})`}
            type="submit"
            loading={loading}>
            Save
          </Button>
        </Col>
      </Row>
    </form>
  );
});

export default AddActionItemForm;
