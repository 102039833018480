export const leftPanelOptions = [
    {
            text: 'Dashboard',
            iconName: 'grid_view',
            redirectURL: '/dashboard',
            id: 1,
            name: 'dashboard'
    },
    {
            text: 'Deal Room',
            iconName: 'handshake',
            redirectURL: '/dealroom',
            id: 2,
            name: 'dealroom'
    },
    {
            text: 'Company',
            iconName: 'meeting_room',
            redirectURL: '/company',
            id: 3,
            name: 'company'
    },
    {
        text: 'Documents',
        iconName: 'summarize',
        redirectURL: '/document',
        id: 4,
        name: 'document'
    },
    {
        text: 'Profile',
        iconName: 'person',
        redirectURL: '/profile',
        id: 5,
        name: 'profile'
    }
]

export const saleWidget = [
    {
        header: 'Sales Targets',
        subheader : 'Actual vs Comparision' 
    },
    {
        header: 'Sales Pipeline',
        subheader: 'Overview of deals'
    },
    {
        header: 'Conversion Rates',
        subheader: 'Deals vs Closure'
    },
    {
        header: 'Average Deal Size',
        subheader: 'Average value of closed deals'
    }
]

