import React from 'react';
import emptyIcon from '../../images/empty-data.svg';

export const EmptyData = () => {
  return (
    <div className="empty-container">
      <div className="empty-content">
        <img src={emptyIcon} alt="empty" />
        <div className="empty-content-title">No Documents</div>
        <div className="empty-content-subtitle">
          Upload and store documents to use in your deals.
        </div>
      </div>
    </div>
  );
};
