// login
export const GET_LOGIN = 'GET_LOGIN';
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
export const GET_LOGIN_FAILURE = 'GET_LOGIN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// Deal List

export const DEAL_LIST = 'DEAL_LIST';
export const DEAL_LIST_SUCCESS = 'DEAL_LIST_SUCCESS';
export const DEAL_LIST_FAILURE = 'DEAL_LIST_FAILURE';

// New details
export const CREATE_NEW_DEAL = 'CREATE_NEW_DEAL';
export const CREATE_NEW_DEAL_SUCCESS = 'CREATE_NEW_DEAL_SUCCESS';
export const CREATE_NEW_DEAL_FAILURE = 'CREATE_NEW_DEAL_FAILURE';

export const UPDATE_DEAL = 'UPDATE_DEAL';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_FAILURE = 'UPDATE_DEAL_FAILURE';

// each deal deatails
export const EACH_DEAL = 'EACH_DEAL';
export const EACH_DEAL_SUCCESS = 'EACH_DEAL_SUCCESS';
export const EACH_DEAL_FAILURE = 'EACH_DEAL_FAILURE';

// each section details
export const EACH_SECTION = 'EACH_SECTION';
export const EEACH_SECTIONL_SUCCESS = 'EEACH_SECTIONL_SUCCESS';
export const EACH_SECTIONL_FAILURE = 'EACH_SECTIONL_FAILURE';

// add one section 
export const GET_SINGLE_SECTION = 'GET_SINGLE_SECTION';
export const GET_SINGLE_SECTION_SUCCESS = 'GET_SINGLE_SECTION_SUCCESS';
export const GET_SINGLE_SECTION_FAILURE = 'GET_SINGLE_SECTION_FAILURE';

// add section
export const ADD_SECTION = 'ADD_SECTION';
export const ADD_SECTION_SUCCESS = 'ADD_SECTION_SUCCESS';
export const ADD_SECTION_FAILURE = 'ADD_SECTION_FAILURE';

// add section
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

// add section
export const TASK_COMPLETE = 'TASK_COMPLETE';
export const TASK_COMPLETE_SUCCESS = 'TASK_COMPLETE_SUCCESS';
export const TASK_COMPLETE_FAILURE = 'TASK_COMPLETE_FAILURE';

export const TASK_LIST = 'TASK_LIST';
export const TASK_LIST_SUCCESS = 'TASK_LIST_SUCCESS';
export const TASK_LIST_FAILURE = 'TASK_LIST_FAILURE';

export const CREAT_TASK = 'CREAT_TASK';
export const CREAT_TASK_SUCCESS = 'CREAT_TASK_SUCCESS';
export const CREAT_TASK_FAILURE = 'CREAT_TASK_FAILURE';

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const CUPDATE_TASK_FAILURE = 'CUPDATE_TASK_FAILURE';

export const ADD_COMMNENT = 'ADD_COMMNENT';
export const ADD_COMMNENT_SUCCESS = 'ADD_COMMNENT_SUCCESS';
export const ADD_COMMNENT_FAILURE = 'ADD_COMMNENT_FAILURE';

export const GET_COMMNENTS = 'GET_COMMNENTS';
export const GET_COMMNENTS_SUCCESS = 'GET_COMMNENTS_SUCCESS';
export const GET_COMMNENTS_FAILURE = 'GET_COMMNENTS_FAILURE';

export const EDIT_COMMNENTS = 'EDIT_COMMNENTS';
export const EDIT_COMMNENTS_SUCCESS = 'EDIT_COMMNENTS_SUCCESS';
export const EDIT_COMMNENTS_FAILURE = 'EDIT_COMMNENTS_FAILURE';

export const DELETE_COMMNENTS = 'DELETE_COMMNENTS';
export const DELETE_COMMNENTS_SUCCESS = 'DELETE_COMMNENTS_SUCCESS';
export const DELETE_COMMNENTS_FAILURE = 'DELETE_COMMNENTS_SUCCESS';

//manage collaborators deal
export const INVITE_COLLABORATORS = 'INVITE_COLLABORATORS';
export const INVITE_COLLABORATORS_SUCCESS = 'INVITE_COLLABORATORS_SUCCESS';
export const INVITE_COLLABORATORS_FAILURE = 'INVITE_COLLABORATORS_FAILURE';

export const GET_PINNED = 'GET_PINNED';
export const GET_PINNED_SUCCESS = 'GET_PINNED_SUCCESS';
export const GET_PINNED_FAILURE = 'GET_PINNED_FAILURE';

export const ADD_PINNED = 'ADD_PINNED';
export const ADD_PINNED_SUCCESS = 'ADD_PINNED_SUCCESS';
export const ADD_PINNED_FAILURE = 'ADD_PINNED_FAILURE';

export const EDIT_SECTION = 'EDIT_SECTION';
export const EDIT_SECTION_SUCCESS = 'EDIT_SECTION_SUCCESS';
export const EDIT_SECTION_FAILURE = 'EDIT_SECTION_FAILURE';

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const JOIN_DEAL_ROOM = 'JOIN_DEAL_ROOM';
export const JOIN_DEAL_ROOM_SUCCESS = 'JOIN_DEAL_ROOM_SUCCESS';
export const JOIN_DEAL_ROOM_FAILURE = 'JOIN_DEAL_ROOM_FAILURE';

//file manager
export const CREAT_FOLDER = 'CREAT_FOLDER';
export const CREAT_FOLDER_SUCCESS = 'CREAT_FOLDER_SUCCESS';
export const CREAT_FOLDER_FAILURE = 'CREAT_FOLDER_FAILURE';

export const GET_FOLDER_LIST = 'GET_FOLDER_LIST';
export const GET_FOLDER_LIST_SUCCESS = 'GET_FOLDER_LIST_SUCCESS';
export const GET_FOLDER_LIST_FAILURE = 'GET_FOLDER_LIST_FAILURE';

export const GET_FILE_LIST = 'GET_FILE_LIST';
export const GET_FILE_LIST_SUCCESS = 'GET_FILE_LIST_SUCCESS';
export const GET_FILE_LIST_FAILURE = 'GET_FILE_LIST_FAILURE';

export const GET_TASK_LIST = 'GET_TASK_LIST';
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS';
export const GET_TASK_LIST_FAILURE = 'GET_TASK_LIST_FAILURE';

export const GET_ACTION_ITEM_LIST = 'GET_ACTION_ITEM_LIST';
export const GET_ACTION_ITEM_LIST_SUCCESS = 'GET_ACTION_ITEM_LIST_SUCCESS';
export const GET_ACTION_ITEM_LIST_FAILURE = 'GET_ACTION_ITEM_LIST_FAILURE';

export const ADD_ACTION_ITEM = 'ADD_ACTION_ITEM';
export const ADD_ACTION_ITEM_SUCCESS = 'ADD_ACTION_ITEM_SUCCESS';
export const ADD_ACTION_ITEM_FAILURE = 'ADD_ACTION_ITEM_FAILURE';

export const ADD_TASK_COMMENT = 'ADD_TASK_COMMENT';
export const ADD_TASK_COMMENT_SUCCESS = 'ADD_TASK_COMMENT_SUCCESS';
export const ADD_TASK_COMMENT_FAILURE = 'ADD_TASK_COMMENT_FAILURE';

export const GET_TASK_COMMENT = 'GET_TASK_COMMENT';
export const GET_TASK_COMMENT_SUCCESS = 'GET_TASK_COMMENT_SUCCESS';
export const GET_TASK_COMMENT_FAILURE = 'GET_TASK_COMMENT_FAILURE';

export const EDIT_TASK_COMMENT = 'EDIT_TASK_COMMENT';
export const EDIT_TASK_COMMENT_SUCCESS = 'GET_TASK_COMMENT_SUCCESS';
export const EDIT_TASK_COMMENT_FAILURE = 'EDIT_TASK_COMMENT_FAILURE';

export const ADD_TASK_FILE = 'ADD_TASK_FILE';
export const ADD_TASK_FILE_SUCCESS = 'ADD_TASK_FILE_SUCCESS';
export const ADD_TASK_FILE_FAILURE = 'ADD_TASK_FILE_FAILURE';

export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';
export const UPDATE_FOLDER_FAILURE = 'UPDATE_FOLDER_FAILURE';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const GET_FOLDER_DETAIL = 'GET_FOLDER_DETAIL';
export const GET_FOLDER_DETAIL_SUCCESS = 'GET_FOLDER_DETAIL_SUCCESS';
export const GET_FOLDER_DETAIL_FAILURE = 'GET_FOLDER_DETAIL_FAILURE';

export const GET_FILE_DETAIL = 'GET_FILE_DETAIL';
export const GET_FILE_DETAIL_SUCCESS = 'GET_FILE_DETAIL_SUCCESS';
export const GET_FILE_DETAIL_FAILURE = 'GET_FILE_DETAIL_FAILURE';

export const DELETE_DOCUMENT_FILE = 'DELETE_DOCUMENT_FILE';
export const DELETE_DOCUMENT_FILE_SUCCESS = 'DELETE_DOCUMENT_FILE_SUCCESS';
export const DELETE_DOCUMENT_FILE_FAILURE = 'DELETE_DOCUMENT_FILE_FAILURE';

export const UPDATE_DOCUMENT_FILE = 'UPDATE_DOCUMENT_FILE';
export const UPDATE_DOCUMENT_FILE_SUCCESS = 'UPDATE_DOCUMENT_FILE_SUCCESS';
export const UPDATE_DOCUMENT_FILE_FAILURE = 'UPDATE_DOCUMENT_FILE_FAILURE';

export const LINK_FILE = 'LINK_FILE';
export const LINK_FILE_SUCCESS = 'LINK_FILE_SUCCESS';
export const LINK_FILE_FAILURE = 'LINK_FILE_FAILURE';

export const LINK_FILE_TASK = 'LINK_FILE_TASK';
export const LINK_FILE_TASK_SUCCESS = 'LINK_FILE_TASK_SUCCESS';
export const LINK_FILE_TASK_FAILURE = 'LINK_FILE_TASK_FAILURE';

//end of document action

export const UPDATE_ACTION_ITEMS = 'UPDATE_ACTION_ITEMS';
export const UPDATE_ACTION_ITEMS_SUCCESS = 'UPDATE_ACTION_SUCCESS';
export const UPDATE_ACTION_ITEMS_FAILURE = 'UPDATE_ACTION_ITEMS_FAILURE';

export const REMOVE_COLLABORATORS = 'REMOVE_COLLABORATORS';
export const REMOVE_COLLABORATORS_SUCCESS = 'REMOVE_COLLABORATORS_SUCCESS';
export const REMOVE_COLLABORATORS_FAILURE = 'REMOVE_COLLABORATORS_FAILURE';

export const GET_COLLABORATORS = 'GET_COLLABORATORS';
export const GET_COLLABORATORS_SUCCESS = 'GET_COLLABORATORS_SUCCESS';
export const GET_COLLABORATORS_FAILURE = 'GET_COLLABORATORS_FAILURE';

//Dashboard

export const GET_DASHBOARD_ACTIVITY = 'GET_DASHBOARD_ACTIVITY';
export const GET_DASHBOARD_ACTIVITY_SUCCESS = 'GET_DASHBOARD_ACTIVITY_SUCCESS';
export const GET_DASHBOARD_ACTIVITY_FAILURE = 'GET_DASHBOARD_ACTIVITY_FAILURE';
