/* eslint-disable react/prop-types */
import React, { useContext, useRef } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

export const DocumentsSectionHeader = ({
  name = 'Folders',
  btnText = '',
  handleClickBtn = () => {},
  imageULR,
  eventKey,
  supportUploadFile = false,
  handleFileChange = () => {},
  noAction = false,
}) => {
  const { activeEventKey } = useContext(AccordionContext);

  const fileInputRef = useRef(null);

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  const handleClickInput = () => {
    fileInputRef.current.click();
  };

  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  return (
    <div className="document-page-section-header">
      <div className="document-page-section-title expand-action">
        <span
          className={`material-symbols-rounded ${isCurrentEventKey ? 'expand' : ''}`}
          onClick={decoratedOnClick}>
          expand_all
        </span>
        <div>{name}</div>
      </div>
      {!noAction && (
        <div
          className="document--btn"
          onClick={supportUploadFile ? handleClickInput : handleClickBtn}>
          <img src={imageULR} alt="add" className="me-2" />
          <div>{btnText}</div>
        </div>
      )}

      {supportUploadFile && (
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          multiple
        />
      )}
    </div>
  );
};
