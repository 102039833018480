/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { imageTextWrapper } from '../../../common/utils';
import {ManageParticipants} from './ManageParticipants';

export const ManageSectionCollaborators = props => {
  console.log(props, 'ManageSectionCollaborators');
  const { participants = [], dealRoomDetails, updateDealRoomDetails } = props;
  const [show, setShow] = useState(false);

  const manageParticipants = () => {
    setShow(true);
  } 
  const handClose = () => {
    setShow(false);
  }
  return (
    <div className="dealsection-managecollaborators">
      <div className="coll-warpper">
        <div className="col-content">
          <span className="material-symbols-rounded">groups</span>

          {props?.participants?.length > 0 ? (
            <ul>
              {props?.participants?.map((item, index) => {
                return <li key={index}>{imageTextWrapper(item)}</li>;
              })}
            </ul>
          ) : (
            'No Collaborators'
          )}
        </div>

        <button type="button" onClick={manageParticipants}>
          Manage Collaborators ({participants?.length})
        </button>
      </div>
      <ManageParticipants 
      dealRoomDetails = {dealRoomDetails}
      updateDealRoomDetails= {updateDealRoomDetails}
      modalShow={show}
      handleClose={handClose}
      participants={participants}
      />
    </div>
  );
};
