/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { ListElement } from "../../atoms/ListElements";
import {isEmptyArray} from '../../common/utils'
import {CreateDeal} from '../../organisms/DealRoomHeader/CreateDeal';
export const CreateListElements = (props) => {
  const [active, setActive] = useState("");
  const [newDeal, setNewDeal] = useState(false);
  const { options = [], dealOptions = [], onOptionClick = {} } = props;
  const handleClose = () => {
    setNewDeal(false);
  }
  // const addNewDeal = () => {
  //   setNewDeal(true);
  // }
  useEffect(() => {
    const url = window.location.href;
    const regex = /\/deal\/(\d+)/;
    const match = url.match(regex);
    !active && match && match[1] && setActive(match[1]);
  }, []);

  const selectDealDetailsId = (id) => {
    onOptionClick(id);
    setActive(id);
  };
  return (
    <>
      {options && (
        <ul className="left-panel">
          {options?.map((item, key) => {
            return <ListElement {...item} key={key}/>;
          })}
        </ul>
      )}
      {!isEmptyArray?.(dealOptions) && (
        <ul className="left-panel">
          <li className="pinned"><span className="material-symbols-rounded">expand_all</span> Pinned</li>
          {/* <li className="deal-active" onClick={addNewDeal}><span className="material-symbols-rounded">add_circle</span> New Deal Room</li> */}
          {dealOptions?.map((item) => {
            return (
              <li
                key={item?.id}
                onClick={() => selectDealDetailsId(item?.id)}
                className={`${active == item?.id && "deal-active"} deals`}
              >
                {/* <span class="material-symbols-rounded">
                    radio_button_unchecked
                  </span> */}
                {active == item?.id ? (
                  <span className="material-symbols-rounded color-blue">
                    radio_button_checked
                  </span>
                ) : (
                  <span className="material-symbols-rounded">
                    radio_button_unchecked
                  </span>
                )}

                {item?.name}
              </li>
            );
          })}
        </ul>
      )}
      <CreateDeal show= {newDeal} handleClose ={handleClose}/>
    </>
  );
};
