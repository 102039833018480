import {
  DEAL_LIST,
  DEAL_LIST_SUCCESS,
  DEAL_LIST_FAILURE,
  CREATE_NEW_DEAL,
  CREATE_NEW_DEAL_SUCCESS,
  CREATE_NEW_DEAL_FAILURE,
  EACH_DEAL,
  EACH_DEAL_SUCCESS,
  EACH_DEAL_FAILURE,
  EACH_SECTION,
  EEACH_SECTIONL_SUCCESS,
  EACH_SECTIONL_FAILURE,
  ADD_SECTION,
  ADD_SECTION_SUCCESS,
  ADD_SECTION_FAILURE,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  TASK_COMPLETE,
  TASK_COMPLETE_SUCCESS,
  TASK_COMPLETE_FAILURE,
  TASK_LIST,
  TASK_LIST_SUCCESS,
  TASK_LIST_FAILURE,
  CREAT_TASK,
  CREAT_TASK_SUCCESS,
  CREAT_TASK_FAILURE,
  UPDATE_DEAL,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_FAILURE,
  INVITE_COLLABORATORS_SUCCESS,
  INVITE_COLLABORATORS_FAILURE,
  INVITE_COLLABORATORS,
  GET_PINNED,
  GET_PINNED_SUCCESS,
  GET_PINNED_FAILURE,
  ADD_PINNED,
  ADD_PINNED_SUCCESS,
  ADD_PINNED_FAILURE,
  EDIT_SECTION,
  EDIT_SECTION_SUCCESS,
  EDIT_SECTION_FAILURE,
  REMOVE_COLLABORATORS,
  REMOVE_COLLABORATORS_SUCCESS,
  REMOVE_COLLABORATORS_FAILURE,
  GET_COLLABORATORS,
  GET_COLLABORATORS_SUCCESS,
  GET_COLLABORATORS_FAILURE,
  GET_SINGLE_SECTION,
  GET_SINGLE_SECTION_SUCCESS,
  GET_SINGLE_SECTION_FAILURE,
} from '../Constants/constants';
const initialState = {
  loading: false,
  dealList: {},
  newDeal: {},
  eachDealDeatils: {},
  section: {},
  addSection: {},
  uploadFile: {},
  taskCompleted: {},
  taskList: {},
  createTask: {},
  updatedDeal: {},
  pinnedDeals: {},
  addPinnedDeal: {},
  editedSection: {},
  removeCollaborators: {},
  getMembers: {},
  singleSectionDetails: {},
};

const dealsReducers = (state = initialState, action) => {
  console.log('actionReducer', action);
  switch (action.type) {
    case DEAL_LIST:
    case CREATE_NEW_DEAL:
    case EACH_DEAL:
    case EACH_SECTION:
    case ADD_SECTION:
    case FILE_UPLOAD:
    // case TASK_COMPLETE:
    case TASK_LIST:
    case CREAT_TASK:
    case UPDATE_DEAL:
    case INVITE_COLLABORATORS:
    case GET_PINNED:
    case ADD_PINNED:
    case EDIT_SECTION:
    case GET_COLLABORATORS:
    case REMOVE_COLLABORATORS:
    case GET_SINGLE_SECTION:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case DEAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dealList: action?.data,
      };
    case DEAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        dealList: action?.data,
      };
    case CREATE_NEW_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        newDeal: action?.data,
      };
    case CREATE_NEW_DEAL_FAILURE:
      return {
        ...state,
        loading: false,
        newDeal: action?.data,
      };

    case EACH_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        eachDealDeatils: action?.data,
      };
    case EACH_DEAL_FAILURE:
      return {
        ...state,
        loading: false,
        eachDealDeatils: action?.data,
      };
    case EEACH_SECTIONL_SUCCESS:
      return {
        ...state,
        loading: false,
        section: action?.data,
      };
    case EACH_SECTIONL_FAILURE:
      return {
        ...state,
        loading: false,
        section: action?.data,
      };
    case ADD_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        addSection: action?.data,
      };
    case ADD_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        addSection: action?.data,
      };
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadFile: action?.data,
      };
    case FILE_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        uploadFile: action?.data,
      };
    // case TASK_COMPLETE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     taskCompleted: action?.data,
    //   };
    // case TASK_COMPLETE_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     taskCompleted: action?.data,
    //   };
    case TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskList: action?.data,
      };
    case TASK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        taskList: action?.data,
      };
    case CREAT_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        createTask: action?.data,
      };
    case CREAT_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        createTask: action?.data,
      };
    case UPDATE_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedDeal: action?.data,
      };
    case UPDATE_DEAL_FAILURE:
      return {
        ...state,
        loading: false,
        updatedDeal: action?.data,
      };
    case INVITE_COLLABORATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        eachDealDeatils: action?.data,
      };
    case INVITE_COLLABORATORS_FAILURE:
      return {
        ...state,
        loading: false,
        eachDealDeatils: action?.data,
      };
    case GET_PINNED_SUCCESS:
      return {
        ...state,
        loading: false,
        pinnedDeals: action?.data,
      };
    case GET_PINNED_FAILURE:
      return {
        ...state,
        loading: false,
        pinnedDeals: action?.data,
      };
    case ADD_PINNED_SUCCESS:
      return {
        ...state,
        loading: false,
        addPinnedDeal: action?.data,
      };
    case ADD_PINNED_FAILURE:
      return {
        ...state,
        loading: false,
        addPinnedDeal: action?.data,
      };
    case EDIT_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        editedSection: action?.data,
      };
    case EDIT_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        removeCollaborators: action?.data,
      };
    case REMOVE_COLLABORATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        removeCollaborators: action?.data,
      };
    case REMOVE_COLLABORATORS_FAILURE:
      return {
        ...state,
        loading: false,
        removeCollaborators: action?.data,
      };
    case GET_COLLABORATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        getMembers: action?.data,
      };
    case GET_COLLABORATORS_FAILURE:
      return {
        ...state,
        loading: false,
        getMembers: action?.data,
      };
    case GET_SINGLE_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSectionDetails: action?.data,
      };
    case GET_SINGLE_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        singleSectionDetails: action?.data,
      };
    default:
      return state;
  }
};

export default dealsReducers;
