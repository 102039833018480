/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { eachSection } from '../../../react-shared/src/Actions/DealsActions';
import { useDispatch } from 'react-redux';
import AddTaskForm from '../Forms/AddTaskForm';
// import { rules } from 'eslint-plugin-prettier';

export const AddActionTask = props => {
  const { actionItems, updateActionItems, openModal, handleClose } = props;
  const [SectionList, setSectionList] = useState('');
  const [dealRoomDetails, setDealRoomDetails] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    actionItems?.actionTasks?.[0]?.deal_room &&
      dispatch(
        eachSection(
          {
            id: actionItems?.actionTasks?.[0]?.deal_room,
          },
          resposne => {
            console.log('response', resposne);
            const sectionListArray = resposne?.results?.map(item => {
              return {
                id: item.id,
                name: item.name,
              };
            });
            setSectionList(sectionListArray);
            setDealRoomDetails(resposne)
          },
          error => {
            console.log('error', error);
          },
        ),
      );
  }, [actionItems?.actionTasks]);


  return (
    <Modal
      show={openModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Task
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You can add action items to a task after it's created.</p>
        <AddTaskForm
          actionItems={actionItems}
          updateActionItems={updateActionItems}
          handleClose={handleClose}
          SectionList={SectionList}
          dealRoomDetails={dealRoomDetails}
        />
      </Modal.Body>
    </Modal>
  );
};
