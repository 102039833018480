/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

export const Form = forwardRef((props, ref) => {
  const { requestedFields = [], onSubmit } = props;
  const defaultValues = requestedFields.reduce((acc, field) => {
    acc[field.name] = field.defaultValue || '';
    return acc;
  }, {});
  
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValues, mode: 'onBlur' });
  
  
  useImperativeHandle(ref, () => ({
    handleSubmitForm: handleSubmit,
    triggerValidation: trigger
  }));

  const handleFieldChange = useCallback((name, event) => {
    setValue(name, event.target.value, { shouldValidate: true });
    const field = requestedFields.find(f => f.name === name);
    if (field?.onChange) {
      field.onChange(event);
    }
  }, [requestedFields, setValue]);

  const handleFieldBlur = async (name) => {
    await trigger(name);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {requestedFields.map((field, index) => (
        <div key={index} className='inputGroup'>
          {field.labelRequired && <label>{field.label}</label>}
          {field.type === 'input' && (
              <input
                type="text"
                className={errors[field.name] ? 'errorfield' : ''}
                placeholder={field.placeholder}
                defaultValue={field.defaultValue}
                {...register(field.name, field.rules)}
                onChange={(event) => handleFieldChange(field.name, event)}
                onBlur={() => handleFieldBlur(field.name)}
              />

          )}
          {field.type === 'textarea' && (
              <textarea
              className={errors[field.name] ? 'errorfield' : ''}
                placeholder={field.placeholder}
                defaultValue={field.defaultValue}
                {...register(field.name, field.rules)}
                onChange={(event) => handleFieldChange(field.name, event)}
                onBlur={() => handleFieldBlur(field.name)}
              />
       
          )}
          {field.type === 'password' && (
              <input
                type="password"
                className={errors[field.name] ? 'errorfield' : ''}
                placeholder={field.placeholder}
                defaultValue={field.defaultValue}
                {...register(field.name, field.rules)}
                onChange={(event) => handleFieldChange(field.name, event)}
                onBlur={() => handleFieldBlur(field.name)}
              />
          )}
          {errors[field.name] && <p className='error'>{errors[field.name].message}</p>}
        </div>
      ))}
    </form>
  );
});
