// const GET_LOGIN = 'GET_LOGIN';
import { GET_LOGIN, SIGN_OUT, JOIN_DEAL_ROOM, RESET_PASSWORD}  from '../Constants/constants';

export const loginAction = (payload, successCallback, errorCallback) => {
  console.log('payload', payload);
  return {
    type: GET_LOGIN,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const signOutAction = () => {
  return {
    type: SIGN_OUT,
  };
};

export const joinDealRoomAction = (payload, successCallback, errorCallback) => {
  console.log('payload', payload);
  return {
    type: JOIN_DEAL_ROOM,
    payload: payload,
    successCallback,
    errorCallback,
  };
};

export const resetPasswordAction = (payload, successCallback, errorCallback) => {
  console.log('payload', payload);
  return {
    type: RESET_PASSWORD,
    payload: payload,
    successCallback,
    errorCallback,
  };
}