import React from 'react';
import moment from 'moment';

import FallbackImage from '../images/Profile-Picture.png';
import pdfNew from '../images/newpdf.svg';
import video from '../images/video.svg';
import document from '../images/doc.svg';
import { isBefore } from 'date-fns';
// import { getUserSaga } from '../react-shared/src/Saga/loginSaga';

export const getFormatedDate = formattedDate => {
  // const dateTimeString = '2024-08-03T20:43:54.013581+07:00';
  const formattedDateResult = moment(formattedDate).format(
    'hh:mm A, DD MMMM YYYY',
  );
  return formattedDateResult;
  // const formattedDate = date.toISOString().split('T')[0];
};

export const getJustFormatedDate = formattedDate => {
  // const dateTimeString = '2024-08-03T20:43:54.013581+07:00';
  const formattedDateResult = moment(formattedDate).format('DD MMMM YYYY');
  return formattedDateResult;
  // const formattedDate = date.toISOString().split('T')[0];
};

export const checkEditedDate = (createdDate, modifiedDate) => {
  return moment(createdDate).isBefore(moment(modifiedDate));
};

export const imageWrapper = image => {
  return (
    <img
      src={image ? image : FallbackImage}
      alt={image ? image : FallbackImage}
      className="profileImage"
    />
  );
};

// Extract the date part in YYYY-MM-DD format

export const checkUser = (comment, userInfo) => {
  return comment?.comment_by?.email === userInfo?.UserDetails?.email
    ? 'You'
    : `${comment?.comment_by?.first_name} ${comment?.comment_by?.last_name}`;
};

export const isEmptyObject = obj => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isEmptyArray = arr => {
  return !arr || (Array.isArray(arr) && arr.length === 0);
};

export const imageTextWrapper = item => {
  const firstInitial = item?.first_name?.charAt(0)?.toUpperCase();
  const lastInitial = item?.last_name?.charAt(0)?.toUpperCase();

  const name = firstInitial + lastInitial;
  return item?.user_images?.user_image ? (
    <img
      src={`${process.env.REACT_APP_IMAGE_ROUTE_URL}${item?.user_images?.user_image}`}
      className="profileImage"
    />
  ) : (
    <div className="profilpartname">{name ? name : 'N/A'}</div>
  );
};

export const checkFutureDate = date => {
  const today = new Date();
  const inputDate = new Date(date);
  today.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);
  return inputDate > today;
};

export const areYouaOwner = (userinfo, owner) => {
  return userinfo?.UserDetails?.email === owner?.email;
};

export const checkActivityDate = date => {
  const today = moment();
  const inputDate = moment(date);

  if (inputDate.isSame(today, 'day')) {
    return 'Today';
  } else if (inputDate.isSame(today.clone().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  } else if (
    inputDate.isBetween(today.clone().subtract(3, 'days'), today, 'day', '[]')
  ) {
    return 'Last 3 days';
  } else if (inputDate.isSame(today, 'week')) {
    return 'This week';
  } else if (inputDate.isSame(today, 'month')) {
    return 'This month';
  } else {
    return inputDate.format('MMMM YYYY');
  }
};

export const formatTime = dateTime => {
  const today = moment();
  const inputTime = moment(dateTime);

  if (inputTime.isSame(today, 'day')) {
    const diffInSeconds = today.diff(inputTime, 'seconds');

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s ago`;
    } else {
      return inputTime.format('HH:mm');
    }
  } else {
    return inputTime.format('ddd D MMM YYYY h:mm a');
  }
};

export const fileTypes = props => {
  console.log('propsfilrtype', props);
  switch (props) {
    case 'pdf':
      return pdfNew;
    case 'video':
      return video;
    case 'doc':
      return document;
    default:
      return video; // Optional: Return a default image or null if the file type doesn't match
  }
};
