/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import './style.scss';
import { debounce } from 'lodash';

export const SearchInput = props => {
  const {
    value,
    onClear = () => {},
    placeholder = 'Search...',
    onChangeSearch = () => {},
  } = props;

  const [searchValue, setSearchValue] = useState(value);

  const handleChangeSearch = e => {
    const searchTerm = e?.target?.value;
    setSearchValue(searchTerm);
    handleUpdateSearchValue(e);
  };

  const handleUpdateSearchValue = useCallback(
    debounce(e => {
      try {
        onChangeSearch(e);
      } catch (err) {
        console.log(err);
      }
    }, 400),
    [onChangeSearch, value],
  );

  const handleClearSearch = () => {
    setSearchValue('');
    onClear();
  };

  return (
    <div className="search-group">
      <span className="material-symbols-rounded">search</span>
      <input
        placeholder={placeholder}
        value={searchValue}
        onChange={handleChangeSearch}
      />
      {value && (
        <span
          className="material-symbols-rounded clear"
          onClick={handleClearSearch}>
          cancel
        </span>
      )}
    </div>
  );
};
