/* eslint-disable react/prop-types */
import React from 'react';
import {ButtonLoader} from '../Loader';
export const Button = props => {
  const {
    text = '',
    imageULR = '',
    type = '',
    varient = '',
    onClickHandler,
    disabled = false,
    loading = false,
  } = props;
  const OnclickFunction = e => {
    if (onClickHandler) {
      onClickHandler(e);
    }
  };
  return (
    <button
      disabled={disabled}
      className={
        varient === 'primary'
          ? 'primary-color-button'
          : 'secondary-color-button'
      }
      type={type}
      onClick={OnclickFunction}
      onKeyPress={(event) => handleKeyPress(event)}
      >
      {imageULR && (
        <span>
          <img src={imageULR} />
        </span>
      )}
      {
        loading ? <ButtonLoader/> : text
      }
      
    </button>
  );
};
