/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'react-bootstrap';
import AddFileForm from '../Forms/AddFileForm';
import './styles.scss';

export const AddFileTemplate = props => {
  console.log('props', props);

  const { updateDealRoomDetails, dealRoomDetails, modalShow, handleClose } =
    props;

  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="fileuploadModal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddFileForm
          updateDealRoomDetails={updateDealRoomDetails}
          dealRoomDetails={dealRoomDetails}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};
