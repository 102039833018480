/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './style.scss';

export const ModalConfirmAction = ({
  show = false,
  handleClose,
  handleConfirm,
  btnText = 'Close',
  bodyText = '',
  title = '',
  icon = null,
  btnCancel = '',
  handleCancel,
  otherBodyText = '',
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal-confirm-custom"
      backdropClassName="backdrop-custom"
      centered
      backdrop="static"
      size="sm">
      <Modal.Body>
        {icon && <img src={icon} alt="icon" />}
        <div className="modal-confirm-custom--title">{title}</div>
        <div className="modal-confirm-custom--text">{bodyText}</div>
        {otherBodyText &&  <div className="modal-confirm-custom--othertext">{otherBodyText}</div>}
       
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-confirm-custom--footer-container">
          {btnCancel && (
            <Button variant="outline-secondary" onClick={handleCancel}>
              {btnCancel}
            </Button>
          )}
          <Button onClick={handleConfirm}>{btnText}</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
