import {
  GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  SIGN_OUT,
  JOIN_DEAL_ROOM,
  JOIN_DEAL_ROOM_SUCCESS,
  JOIN_DEAL_ROOM_FAILURE,
} from '../Constants/constants';

const initialState = {
  loading: false,
  UserDetails: JSON.parse(localStorage.getItem('userInfo') || '{}'),
  token: localStorage.getItem('accessToken'),
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN:
    // case JOIN_DEAL_ROOM:
      return {
        ...state,
        loading: true,
        // UserDetails : action.response,
      };
    case GET_LOGIN_SUCCESS:
    // case JOIN_DEAL_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        UserDetails: action?.data?.user,
        token: action?.data?.access,
      };
    case GET_LOGIN_FAILURE:
    // case JOIN_DEAL_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        UserDetails: action?.data?.user,
      };
    case SIGN_OUT:
      localStorage.setItem('accessToken', '');
      localStorage.setItem('userInfo', JSON.stringify({}));
      return {
        loading: false,
        UserDetails: {},
        token: '',
      };
    default:
      return state;
  }
};

export default loginReducer;
