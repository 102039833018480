/* eslint-disable react/prop-types */
import React, { useLayoutEffect } from "react";
import NoDealImage from "../../images/nodeal.svg";
import { Container, Row, Col } from "react-bootstrap";
export const NoDealHeader = (props) => {
  const { listElements = [] } = props;
  return (
    <Row>
      <Col md={12} className="text-center no-dealpanel">
        <>
          <img src={props?.[0]?.image ? props?.[0]?.image : NoDealImage} />
          <h3>{props?.[0]?.text ? props?.[0]?.text : "No Deals"}</h3>
          <p>
            {props?.[0]?.details
              ? props?.[0]?.details
              : "This is where you can create and manage your deal rooms."}
          </p>
        </>
      </Col>
    </Row>
  );
};
