/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import {
  getFormatedDate,
  imageTextWrapper,
  areYouaOwner,
} from '../../common/utils';
import { CreateDeal } from '../DealRoomHeader/CreateDeal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPinnedDeals,
  eachDealDetails,
} from '../../react-shared/src/Actions/DealsActions';
import { ManageCollaborators } from './ManageCollaborators';
import { ModalConfirmAction } from '../../atoms/ModalConfirmAction';
import nosection from '../../images/no-section.png';
import subtract from '../../images/Subtract.svg';

export const DealRoomRightPanel = props => {
  const { setText, sectionCount } = props;
  const [roomDetails, setRoomDetails] = useState({});
  const [idFromPanel, setIdFromPanel] = useState('');
  const [show, setShow] = useState(false);
  const [showManageCollab, setShowManageCollab] = useState(false);
  const [checkSectionCount, setSectionCount] = useState(false);
  const userProfile = useSelector(state => state.loginReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const url = new URL(window.location.href);
  const pathname = url.pathname;
  const Pathid = pathname.split('/')[2];

  useEffect(() => {
    setIdFromPanel(Pathid);
  }, [pathname]);

  const updateRoomDetails = useCallback(params => {
    setRoomDetails(prevState => ({ ...prevState, ...params }));
  }, []);

  useEffect(() => {
    if (idFromPanel) {
      dispatch(
        eachDealDetails(
          { id: idFromPanel },
          response => {
            console.log('response', response);
            updateRoomDetails(response);
          },
          error => {
            console.log('Error fetching deal details:', error);
          },
        ),
      );
    }
  }, [
    dispatch,
    idFromPanel,
    roomDetails?.inviteCollaboratorsSucess,
    roomDetails?.removeCollaboratorsSucess,
  ]);
  console.log('roomDetails', roomDetails);
  const handleClose = () => {
    setShow(false);
  };

  // const screenHeight = window.innerHeight;

  const editDeal = () => {
    setShow(true);
  };

  const sucessResponse = response => {
    // console.log('Response', response);
    navigate(`/deal/${response?.id}`, { state: response?.id });
  };

  const pinDeal = () => {
    const pinnedStatus = roomDetails?.pinned === 'Y' ? 'N' : 'Y';
    dispatch(
      addPinnedDeals(
        {
          id: roomDetails?.id,
          params: {
            pinned: pinnedStatus,
            status: 'A',
          },
        },
        response => {
          console.log('Pinned response:', response);
          updateRoomDetails(response);
        },
        error => {
          console.log('Error pinning deal:', error);
        },
      ),
    );
  };

  useEffect(() => {
    roomDetails?.name && setText(roomDetails?.name);
  }, [roomDetails?.name]);
  const handleCollaborators = () => {
    sectionCount ? setShowManageCollab(true) : setSectionCount(true);
  };

  const cancelModalConfirm = () => {
    setSectionCount(!checkSectionCount);
  };

  return (
    <div className="deal-right-panel">
      {/* <div className="section-warning">
        <p>
          <span className="material-symbols-rounded">visibility_lock</span>This
          section is only visible to you.
        </p>
      </div> */}
      <div className="dealright-panel-header">
        <h3>{roomDetails?.name}</h3>
        {/* <p>{roomDetails?.description}</p> */}
        <div className="right-panel-deal-details">
          <div className="right-panel-clinet">
            <p>{roomDetails?.client}</p> <p>{roomDetails?.id}</p>
          </div>
          <div className="right-panel-deal-date">
            <p>
              <span>Room opened:</span>
              {getFormatedDate(roomDetails?.created_at)}
            </p>
          </div>
          <div className="right-deal-time">
            <p>
              <span>
                <img src={subtract} alt="name" />
              </span>
              Deals like this typically close in 12–15 days.
            </p>
          </div>
          <div className="right-deal-description">
            <p>{roomDetails?.description}</p>
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <p>
            <span className="material-symbols-rounded">groups</span>{' '}
            {roomDetails?.participants?.length > 0
              ? roomDetails?.participants?.length
              : 'No Collaborators'}{' '}
            Collaborators
          </p>
        </Col>
        {/* <Col md={6} className="text-end">
          <p>{roomDetails?.participants?.length}</p>
        </Col> */}
        <Col md={12}>
          <div className="participantList">
            {roomDetails?.participants?.map((item, key) => (
              <span key={key}>{imageTextWrapper(item)}</span>
            ))}
          </div>
        </Col>
        <Col md={12}>
          <div className="invite-collaborators">
            <button type="button" onClick={handleCollaborators}>
              Manage Collaborators
            </button>
          </div>
        </Col>
      </Row>
      <Row className="button-options">
        <Col md={12}>
          {areYouaOwner(userProfile, roomDetails?.owner) && (
            <div className="other-optionbutton">
              <button type="button" onClick={editDeal}>
                Edit Deal
              </button>
            </div>
          )}
          <div className="other-optionbutton">
            <button type="button" onClick={pinDeal}>
              {roomDetails?.pinned === 'Y' ? 'Unpin deal' : 'Add to Pinned'}
            </button>
          </div>
          {areYouaOwner(userProfile, roomDetails?.owner) && (
            <div className="other-optionbutton closeDealButton">
              <button type="button">Close Deal</button>
            </div>
          )}
        </Col>
      </Row>
      <CreateDeal
        show={show}
        handleClose={handleClose}
        dealDetails={roomDetails}
        setDealdeatils={updateRoomDetails}
        editMode={true}
        sucessResponse={sucessResponse}
      />
      <ManageCollaborators
        show={showManageCollab}
        handleClose={() => setShowManageCollab(false)}
        dealDetails={roomDetails}
        updateRoomDetails={updateRoomDetails}
      />
      <ModalConfirmAction
        show={checkSectionCount}
        icon={nosection}
        btnText="Close"
        title="Add a Section Before Sharing the Deal Room"
        bodyText="It looks like you’re about to share this Deal Room, but it doesn’t contain any sections yet. Without content, your external collaborators won’t have anything to see or work on."
        otherBodyText="It looks like you’re about to share this Deal Room, but it doesn’t contain any sections yet. Without content, your external collaborators won’t have anything to see or work on.To ensure a smooth collaboration experience and avoid confusion, please add at least one section before sharing."
        handleConfirm={cancelModalConfirm}
      />
    </div>
  );
};
