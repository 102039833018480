import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalConfirm } from '../../../atoms/ModalConfirm';
import iconSent from '../../../images/email-sent.svg';
import {resetPasswordAction} from '../../../react-shared/src/Actions/login';
import {ToastMessage} from '../../../atoms/Toast'

const schema = yup
  .object({
    email: yup
      .string()
      .email('Invalid email')
      .required('Email is a required field'),
  })
  .required();

export const ResetPassword = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShow, setIsShow] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onClickResetPassword = data => {
    console.log('submit: ', data);
    dispatch(resetPasswordAction({
      data
    }, response => {
      setIsShow(!isShow);
    }, error => {
      ToastMessage({
        success: false,
        message: 'Something went wrong please try again'
      })
    }))
    // setTimeout(() => {
    //   setIsShow(!isShow);
    // }, 1000);
  };

  const onClickBack = () => {
    navigate('/');
  };

  const buttonList = [
    {
      text: 'Submit',
      varient: 'primary',
      size: 12,
      type: 'submit',
    },
    {
      text: 'Back To Login',
      varient: 'secondary',
      size: 12,
      type: 'button',
      onClickHandler: onClickBack,
    },
  ];

  return (
    <>
      <Container>
        <Row>
          <Form
            autoComplete="new-password"
            onSubmit={handleSubmit(onClickResetPassword)}>
            <Col md={12} className="inputGroup">
              <label>Email</label>
              <input
                {...register('email')}
                placeholder={'Enter your email'}
                autoComplete="false"
                className={errors.email ? 'input-error' : ''}
              />
            </Col>
            <Button {...buttonList[0]} />
          </Form>
        </Row>
        {errors.email && <p className="error">{errors.email?.message}</p>}
        <p className='redirect-back-login' onClick={onClickBack}>Back to login</p>
      </Container>
      <ModalConfirm
        show={isShow}
        icon={iconSent}
        btnText="Close"
        title="Check you email!"
        bodyText="A password reset link has been sent to your email. Please check your inbox and follow the instructions to reset your password."
        handleClose={() => setIsShow(!isShow)}
      />
    </>
  );
};
