/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';

export const DealRoomPanel = props => {
  const { Dealroomtabs, dealStatus, active} = props;
  return (
    <div className="DealRoomtabs">
      <ul className="">
        {Dealroomtabs?.map((item, index) => {
          return (
            <li
              key={index}
              className={active === item.status ? 'active' : ''}
              onClick={() => dealStatus(item?.status)}>
              {item?.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
