import React, { useEffect, useCallback, useState } from 'react';
import { LeftPanelMainOptions } from '../../organisms/MainLeftPannel';
import { NoDealHeader } from '../../organisms/NoDealPanel';
import { Container, Row, Col } from 'react-bootstrap';
export const Profile = props => {
  return (
    <Row>
      <Col md={2}>
        <LeftPanelMainOptions />
      </Col>
      <Col md={10}>
        {<NoDealHeader />}
      </Col>
    </Row>
  );
};
